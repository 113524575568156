// use type="submit" for forms
import classNames from "classnames";

const ButtonPill = ({
  children,
  onClick,
  large = false,
  type = "button",
  bgColor = "black",
  textColor = "white",
  fullWidth = false,
  small = false,
}) => {
  const classnames = classNames(
    `bg-${bgColor} text-${textColor} uppercase font-bold rounded-full select-none`,
    { "px-8 py-4 text-base": !large && !small },
    { "px-6 py-3 text-base": small },
    { "px-8 py-4 sm:py-4 uppercase text-lg sm:text-xl": large },
    { "w-full": fullWidth }
  );
  return (
    <div>
      {/* ^ without this containing div, button may expand to full width of parent */}
      <button
        style={{ WebkitTapHighlightColor: "rgba(201, 224, 253, 0.8)" }}
        type={type}
        className={classnames}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

export default ButtonPill;
