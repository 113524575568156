import { Fragment, useContext } from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";

import { setNotificationsRead } from "../api";
import { BadgeCountContext } from "../contexts/badgeCountContext";
import defaultProfile from "../images/default-profile.png";
import { queryGroups, queryNotifications } from "../queries";
import FixedImg from "./Common/FixedImg";
import NavPage from "./Common/NavPage";
import PageColumn from "./Common/PageColumn";
import PageColumnContainer from "./Common/PageColumnContainer";
import Section from "./Common/Section";
import InlinePlayLink from "./InlinePlay/InlinePlayLink";

export const HEADER_HEIGHT_PX = "";
export const HEADER_HEIGHT_TAILWIND = "24"; //h-24 mt-24 pt-24 etc

const Notification = ({ notification, user, isNew, groupName = "" }) => {
  const [showNew, setShowNew] = useState(isNew);
  const newIndicatorClass = showNew
    ? "bg-whiteBlue border-2 border-blue"
    : "bg-white border-2 border-white";

  useEffect(() => {
    let timer = setTimeout(async () => {
      setShowNew(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full pb-5">
      <InlinePlayLink promptId={notification.PromptId} showComments={true}>
        <div
          className={`w-full flex items-center p-2 cursor-pointer select-none z-20 rounded-lg drop-shadow-lg hover:bg-opacity-80 ${newIndicatorClass} duration-3000 transition transition-colors`}
        >
          <div className="w-1/6 ml-0.5">
            <FixedImg
              className="rounded-full w-10 h-10"
              src={user.ProfilePhotoUrl ? user.ProfilePhotoUrl : defaultProfile}
            />
          </div>
          <div className="flex w-5/6 flex-col space-y-1">
            <div className="text-xs truncate">
              {groupName} - {notification.PromptText}
            </div>
            <div className="text-xs text-lightGray-3 truncate">
              {notification.CommentText}
            </div>
            <ReactTimeAgo
              className="text-2xs text-lightGray-3 uppercase pt-1"
              date={new Date(notification.DateCreated * 1000)}
            />
          </div>
        </div>
      </InlinePlayLink>
    </div>
  );
};

const Notifications = () => {
  const { data: notifications } = useQuery(
    "queryNotifications",
    queryNotifications
  );
  const queryClient = useQueryClient();
  const { setNotificationsScreenShowing } = useContext(BadgeCountContext);
  const { data: groups } = useQuery("queryGroups", queryGroups);
  const [groupNamesById, setGroupNamesById] = useState({});

  useEffect(() => {
    const map = {};
    if (groups) {
      groups.forEach((group) => {
        map[group.Id] = group.Name;
      });
      setGroupNamesById(map);
    }
  }, [groups]);

  useEffect(async () => {
    await setNotificationsRead();
    queryClient.invalidateQueries("queryNotifications");
    setNotificationsScreenShowing(true);
    return () => setNotificationsScreenShowing(false);
  }, []);

  return (
    <NavPage backgroundColor="lightGray-6" title="Activity">
      <PageColumnContainer>
        <PageColumn>
          <Section>
            {notifications?.Notifications.length === 0 ? (
              <div className="p-2 text-sm">
                No notifications yet. When there are comments or other activity
                on your prompts, you'll see notifications here.
              </div>
            ) : (
              notifications?.Notifications.map((notification) => {
                const isNew = notification.DateCreated > notifications.LastRead;
                const userId = notification.CommentAuthorId;
                const user = notifications.Users.find(
                  (user) => user.Id === userId
                );
                //  array1.find(element => element > 10);
                return (
                  <Notification
                    key={notification.DateCreated}
                    groupName={groupNamesById[notification.GroupId]}
                    user={user}
                    notification={notification}
                    isNew={isNew}
                  />
                );
              })
            )}
          </Section>
        </PageColumn>
      </PageColumnContainer>
    </NavPage>
  );
};

export default Notifications;
