// Taken from example here: https://material-ui.com/components/switches/
// Added an insistence on white even when disabled on line 34 (fixed their bug).

import { experimentalStyled as styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React from "react";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: theme.spacing(1),
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
    color: theme.palette.common.white,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border-color"]),
  },
}));

export default IOSSwitch;
