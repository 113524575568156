// Styles a standard title for top of pages
// Children, e.g. buttons will be next to title

const PageTitle = ({ label, children }) => (
  <div className="mb-5 sm:mb-8 mx-auto flex flex-row items-center justify-center">
    <div className="font-bold text-2xl sm:text-3xl text-center text-lightGray-1 select-none">
      {label}
    </div>
    {children ? <div className="pl-3">{children}</div> : ""}
  </div>
);

export default PageTitle;
