// For use with react-query.  Returns response data, not the raw response.

import {
  getCurrentPromptForGroup,
  getCurrentPromptsForAllGroups,
  getGroup,
  getGroupManage,
  getGroups,
  getMe,
  getNotifications,
  getPrompt,
  getPromptHistoryForGroup,
  getRecentPrompts,
  getUsers,
} from "./api";

const sortPrompts = (prompts) => {
  // Temp fix to remove null prompts...
  prompts = prompts.filter((p) => p !== null);
  // Prompts seem to come back in unpredictable order, which causes re-renders
  return prompts.sort((a, b) => {
    const aSortValue = a.ScheduledDate + a.GroupId; // if same date, then also GroupId
    const bSortValue = b.ScheduledDate + b.GroupId;
    let value = 0;
    if (aSortValue > bSortValue) {
      value = -1;
    } else if (aSortValue < bSortValue) {
      value = 1;
    }
    return value;
  });
};

const sortUsers = (users) => {
  // User seem to come back in unpredictable order, which causes re-renders
  return users.sort((a, b) => {
    const aSortValue = a.FirstName + a.LastName + a.Id; // if same name, then also id, lol.
    const bSortValue = b.FirstName + b.LastName + b.Id;
    let value = 0;
    if (aSortValue < bSortValue) {
      value = -1;
    } else if (aSortValue > bSortValue) {
      value = 1;
    }
    return value;
  });
};

const sortGroups = (groups) => {
  // Don't know how server orders these, but we'll do alphabetical.
  return groups.sort((a, b) => {
    const aSortValue = a.Name + a.Id; // if same name, then also id, lol.
    const bSortValue = b.Name + b.Id;
    let value = 0;
    if (aSortValue < bSortValue) {
      value = -1;
    } else if (aSortValue > bSortValue) {
      value = 1;
    }
    return value;
  });
};

export async function queryMe() {
  const response = await getMe();
  return response.data.Data.User;
}

// notifications
export async function queryNotifications() {
  const response = await getNotifications();
  return response.data.Data;
}

export async function queryUsers({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { userIds }] = queryKey;
  const response = await getUsers(userIds);
  return sortUsers(response.data.Data.Users);
}

export async function queryPrompt({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { promptId }] = queryKey;
  const response = await getPrompt(promptId);
  return response.data.Data.Prompt;
}

export async function queryGroup({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { groupId }] = queryKey;
  const response = await getGroup(groupId);
  return response.data.Data;
}

export async function queryPromptHistoryForGroup({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { groupId }] = queryKey;
  const response = await getPromptHistoryForGroup(groupId);
  return sortPrompts(response.data.Data.Prompts);
}

export async function queryCurrentPromptForGroup({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { groupId }] = queryKey;
  const response = await getCurrentPromptForGroup(groupId);
  return response.data.Data;
}

export async function queryGroups() {
  const response = await getGroups();
  return sortGroups(response.data.Data.Groups);
}

// Consider using queryHomePageDedupedData instead!
export async function queryRecentPrompts() {
  const response = await getRecentPrompts();
  return sortPrompts(response.data.Data.Prompts);
}

// Consider using queryHomePageDedupedData instead!
export async function queryCurrentPromptsForAllGroups() {
  const response = await getCurrentPromptsForAllGroups();
  const prompts = response.data.Data.Prompts;
  //prompts.push(testProcessingPrompt);
  return sortPrompts(prompts);
}

export async function queryHomePageDedupedData() {
  //   This query returns getRecentPrompts() and
  // getCurrentPromptsForAllGroups() and de-duplicates the former.
  //   Reason: On the Home page, we don't want to include current finished
  // prompts in the recent prompts list. Because of per-query-caching,
  // using two separate queries raises the possibility of flashing
  // deduplicates when/if a separate currentPrompts query would return
  // cache and the recentPrompts returned live data.
  //   Not sure yet what happens if one errors-- I think just
  // the first error will get thrown up to the query handler.
  const responses = await Promise.all([
    getCurrentPromptsForAllGroups(),
    getRecentPrompts(),
  ]);
  const currentPrompts = sortPrompts(responses[0].data.Data.Prompts);
  let recentPrompts = sortPrompts(responses[1].data.Data.Prompts);
  const currentIds = currentPrompts.map((prompt) => prompt.Id);
  // Don't include recent prompts that are also current prompts.
  recentPrompts = recentPrompts.filter((prompt) => {
    return !currentIds.includes(prompt.Id);
  });
  return { currentPrompts, recentPrompts };
}

export async function queryGroupManage({ queryKey }) {
  // https://react-query.tanstack.com/guides/query-functions#query-function-variables
  const [, { groupId }] = queryKey;
  const response = await getGroupManage(groupId);
  return response.data.Data;
}

// SOME PROMPTS IN VARIOUS STATES THAT CAN RETURNED FOR MANUALLY TESTING UI:

// const testProcessingPrompt = {
//   CanSubmit: false,
//   Comments: [],
//   CurrentPhase: "Closed",
//   GroupId: "UG0AA0AA0AA0",
//   Id: "SP2ZU3YQ5PE6",
//   MyResponse: null,
//   PromptText: "Tell us one thing you learned to do during the pandemic",
//   ReadyAt: 1615535700,
//   ReadyIn: 96,
//   ResponseUsers: ["UU3RE1DX4FT5", "UU7EX4VQ4ZK6", "UU4BE3TE5PR3"],
//   ScheduledDate: 1615420800
// }
