import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { leaveGroup } from "../../api";
import { AppContext } from "../../contexts/appContext";
import ButtonPill from "../Common/ButtonPill";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import Section from "../Common/Section";

const GroupSettings = ({ group }) => {
  const [, setLocation] = useLocation();
  const queryClient = useQueryClient();
  const { displayError, displayModal } = useContext(AppContext);

  const askLeaveGroup = useCallback(() => {
    displayModal(
      `Leave ${group.Name}?`,
      <div className="w-72"></div>,
      () => doLeaveGroup(group),
      "Yes, Leave"
    );
  }, [group]);

  const doLeaveGroup = useCallback(async () => {
    try {
      //await leaveGroup(group.Id);
      displayModal(
        "You've left the team.",
        `You're no longer a member of the ${group.Name} team.`,
        () => setLocation("/teams")
      );
    } catch (err) {
      // TODO catch other exceptions
      const message = err.response.data.Error.Message;
      displayError(message);
    }
    queryClient.invalidateQueries("queryGroups");
  }, [group]);

  return (
    <Fragment>
      {group && (
        <PageColumnContainer>
          <PageColumn>
            <Section label="Actions">
              <ButtonPill onClick={askLeaveGroup} bgColor="red">
                Leave Team
              </ButtonPill>
            </Section>
          </PageColumn>
        </PageColumnContainer>
      )}
    </Fragment>
  );
};

export default GroupSettings;
