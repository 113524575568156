import { MicrophoneIcon, VideoCameraIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useContext } from "react";

import { MediaContext } from "../contexts/mediaContext";
import MediaChooser from "./MediaChooser";

const MicCamSelect = () => {
  const { hasMediaPermission } = useContext(MediaContext);
  const [showingMediaChooser, setShowingMediaChooser] = useState(false);

  return (
    <div className="flex flex-row items-start select-none">
      {hasMediaPermission && (
        <div>
          <div
            className={`absolute bottom-8 z-30 ${
              showingMediaChooser ? "" : "hidden"
            }`}
          >
            <MediaChooser onClose={(e) => setShowingMediaChooser(false)} />
          </div>
          <div
            onClick={(e) => setShowingMediaChooser(true)}
            className="cursor-pointer flex text-xs w-32"
          >
            <div className="flex justify-center items-center h-10 w-10 rounded-full border-4 border-opacity-50 border-yellow text-yellow select-none mr-2">
              <VideoCameraIcon className="h-5 w-5 text-white" />
            </div>
            <div className="flex justify-center items-center h-10 w-10 rounded-full border-4 border-opacity-50 border-yellow text-yellow select-none">
              <MicrophoneIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MicCamSelect;
