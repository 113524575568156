import { Redirect, Route } from "wouter";

const ProtectedRoute = ({ component, path }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/" />
  );
};

export default ProtectedRoute;
