// Will look for url search params "p=PROMPTID" and if found, shows a full screen overlay player.
//  Closing the player removes *all* search params and unmounts the player.

import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";

import { ParentAppContext } from "../../contexts/parentAppContext";
import useSearchParam from "../../hooks/useSearchParam";
import InlinePlayer from "./InlinePlayer";

const InlinePlayDisplay = () => {
  const playInlineId = useSearchParam("p");
  const openComments = useSearchParam("c") === "true";
  const [location, setLocation] = useLocation();
  const [overlayContent, setOverlayContent] = useState();
  const { parentAppSetShowStatusBar } = useContext(ParentAppContext);

  const closeOverlay = useCallback(() => {
    setOverlayContent(undefined);
    setLocation(location); // This will clear *all* search params, as wouter 'location' does not include them.
  }, [setOverlayContent, location, setLocation]);

  useEffect(() => {
    if (playInlineId) {
      parentAppSetShowStatusBar(false);
      setOverlayContent(
        <InlinePlayer
          promptId={playInlineId}
          onClose={closeOverlay}
          openComments={openComments}
        />
      );
    } else {
      parentAppSetShowStatusBar(true);
      setOverlayContent(undefined);
    }
  }, [playInlineId, closeOverlay]);

  // The background of the overlay is always present to allow css fade-in, but when hidden has "pointer-events-none".
  const overlayClasses = !overlayContent
    ? "pointer-events-none opacity-0"
    : "opacity-100";
  return (
    <div
      className={`flex flex-col absolute top-0 z-30 w-full h-full bg-black transition duration-300 ${overlayClasses}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {overlayContent}
    </div>
  );
};

export default InlinePlayDisplay;
