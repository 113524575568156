import { isMobile } from "react-device-detect";

import downBolt from "../images/down-bolt.svg";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";
import SafeAreaInsetBottom from "./Common/SafeAreaInsetBottom";

const WalkthroughPrompt = ({ handleStepFinished }) => {
  return (
    <div className="w-screen h-screen bg-opacity-50 bg-black flex items-center justify-center">
      <div className="flex flex-col justify-center items-center">
        <div
          className={`${
            isMobile
              ? "left-1/2 -ml-36 mb-1 bottom-52"
              : "left-24 mb-1 bottom-52"
          } absolute z-10 w-72 mb-6`}
        >
          <div className="bg-yellow text-black p-6 rounded flex flex-col items-center justify-center ">
            <div className="font-bold select-none">You look awesome!</div>
            <div className="text-sm py-4 select-none text-left mb-2">
              This is the daily prompt your team will be answering. Each team
              member will record twelve seconds of video. Answer any way you
              like! Participation is what matters most.
            </div>
            <div>
              <ButtonPill onClick={handleStepFinished}>Next</ButtonPill>
            </div>
          </div>
        </div>
        <FixedImg
          src={downBolt}
          alt="bolt arrow"
          className={`${
            isMobile ? "left-20 mb-2 bottom-40" : "left-52 -ml-5 mb-2 bottom-36"
          } absolute`}
        />
        <SafeAreaInsetBottom />
        {/* ^ Account for extra padding on controls when on iOS */}
      </div>
    </div>
  );
};

export default WalkthroughPrompt;
