import { Redirect, Route } from "wouter";

const LoggedOutRoute = ({ component, path }) => {
  const isAuthenticated = localStorage.getItem("token");
  return !isAuthenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/home" />
  );
};

export default LoggedOutRoute;
