import { Fragment, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { AppContext } from "../../contexts/appContext";
import { queryGroup } from "../../queries";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import Section from "../Common/Section";
import SectionInstructions from "../Common/SectionInstructions";
import ErrorPage from "../ErrorPage/ErrorPage";
import FuturePrompts from "./FuturePrompts";
import PromptTimes from "./PromptTimes";

const GroupSchedule = ({ group }) => {
  return (
    <Fragment>
      <PageColumnContainer>
        <PageColumn>
          <Section label="Video Schedule">
            <SectionInstructions>
              Administrators can chose the days and time that team videos will
              be compiled. An end-of-business-day time usually works best.
              Members will be instructed to answer the prompts in the morning,
              although it is possible to answer early the night before.
            </SectionInstructions>
            <PromptTimes group={group} />
          </Section>
          <Section label="Upcoming Prompts">
            <SectionInstructions>
              Administrators can edit upcoming prompts or refresh them to get a
              new random prompt.
            </SectionInstructions>
            <FuturePrompts groupId={group.Id} />
          </Section>
        </PageColumn>
      </PageColumnContainer>
    </Fragment>
  );
};

export default GroupSchedule;
