import { useCallback } from "react";

import defaultProfile from "../../images/default-profile.png";
import FixedImg from "../Common/FixedImg";

const RosterControls = ({
  members,
  handleMemberClick,
  activeUserId,
  memberRefsByUserId,
  prompt,
}) => {
  const userForId = (userId) => {
    if (members && members.length > 0) {
      return members.find((member) => member.Id === userId);
    }
  };

  const isActive = useCallback(
    (userId) => {
      return userId === activeUserId;
    },
    [activeUserId]
  );

  return (
    <div className="h-16 sm:h-20 flex flex-row p-3 sm:p-6 items-center justify-start flex-1 bg-black overflow-x-auto">
      {prompt.Timeline?.UserEntries.map((userEntry) => {
        const userId = userEntry.UserId;
        const user = userForId(userId);
        const active = isActive(userId);
        return (
          <div
            ref={memberRefsByUserId[userEntry.UserId]}
            key={userId}
            className="w-12 h-8 -ml-2 flex-shrink-0 flex justify-center"
          >
            {/* ^ Doing some width/margin hacks above to make sure some right margin also gets scrolled into view */}
            <FixedImg
              src={user ? user.ProfilePhotoUrl : defaultProfile}
              alt="Profile"
              className={`w-8 h-8 rounded-full cursor-pointer border-2 border-white ${
                active ? "border-opacity-90" : "duration-700 border-opacity-0"
              }`}
              onClick={() => handleMemberClick(userId)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RosterControls;
