import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { MediaContext } from "../contexts/mediaContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import downBolt from "../images/down-bolt.svg";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";
import SafeAreaInsetTop from "./Common/SafeAreaInsetTop";

const WalkthroughCamera = ({ handleStepFinished }) => {
  const { hasParentApp } = useContext(ParentAppContext);

  const { requestMediaPermission, hasMediaPermission } = useContext(
    MediaContext
  );

  const [
    isRequestingMediaPermission,
    setIsRequestingMediaPermission,
  ] = useState(true);

  useEffect(() => {
    if (hasMediaPermission) {
      // We move on from this step only after we have media permissions
      handleStepFinished();
    }
  }, [hasMediaPermission]);

  useEffect(() => {
    if (hasMediaPermission) {
      // TODO: Small "Bug". If user already granted permanent permission in profilephotowidget,
      // but has not seen the video walkthrough, they are still going to see this screen,
      // whose wording implies they are going to get a permission dialog. They won't
      // get one, they'll go straight to (hasMediaPermission === true) when requesting
      // camera access, and move on to the next step.  Don't know how we'll fix this.
      handleStepFinished();
    }
  }, [hasMediaPermission, handleStepFinished]);

  let platform = "web browser";
  if (isMobile && hasParentApp) platform = "12seconds app";
  else if (isMobile && !hasParentApp) platform = "mobile browser app";
  let requestText = `We’re going to need camera and microphone access to record a video. Please allow your ${platform} to access the camera and microphone.`;
  return (
    <div className="w-screen h-screen bg-opacity-50 bg-black select-none">
      {!hasMediaPermission /* && !isRequestingMediaPermission &&*/ && (
        //Don't flash is we already have permission. useEffect will go to next step.
        <div className="h-screen flex flex-col justify-start">
          <SafeAreaInsetTop />
          <div className="relative">
            <div className="w-72 absolute mx-auto left-0 right-0 top-12 mt-5 z-10">
              <div className="bg-yellow text-black p-6 rounded flex flex-col items-center justify-center">
                <div className="font-bold">Camera and Microphone Access</div>
                <div className="text-sm py-4 text-left">
                  {requestText}
                  <div className="text-xs mt-4 text-left mb-2 font-medium">
                    Privacy note: 12seconds only records you when you press the
                    record button and only uploads when you press Submit.
                  </div>
                </div>
                <div>
                  <ButtonPill onClick={requestMediaPermission}>Next</ButtonPill>
                </div>
              </div>
            </div>
            {!isMobile && (
              <FixedImg
                src={downBolt}
                alt="bolt arrow"
                className="absolute mx-auto pl-32 top-0 left-0 right-0 z-0 horizontal-flip"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalkthroughCamera;
