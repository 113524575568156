// An img that doesn't allow dragging or long-press context menu, making the site more "app like"
import clone from "clone";

const FixedImg = (props) => {
  const updatedProps = clone(props); // Copy because can't modify props
  if (!updatedProps.style) updatedProps.style = {};
  updatedProps.style["WebkitTouchCallout"] = "none";
  if (!updatedProps.className) {
    updatedProps.className = "";
  }
  updatedProps.className = updatedProps.className + " select-none";
  return (
    <img
      {...updatedProps}
      onDragStart={(e) => {
        e.preventDefault();
      }}
    />
  );
};

export default FixedImg;
