import ReactPlayer from "react-player";

import FixedLink from "./Common/FixedLink";

const IntroCard = ({ videoUrl, introPromptId }) => {
  if (videoUrl) {
    return (
      <div>
        <ReactPlayer
          url={videoUrl}
          playing={false}
          controls={true}
          loop={false}
          width="100%"
          height="100%"
        />
        <div className="text-xs flex justify-between py-2">
          <div className="text-blue font-bold">
            <FixedLink href={`/prompts/${introPromptId}`}>
              Retake Your Intro 12
            </FixedLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="text-blue font-bold">
      <FixedLink href={`/prompts/${introPromptId}`}>
        Record Your Intro 12
      </FixedLink>
    </div>
  );
};

export default IntroCard;
