import { useContext } from "react";
import { Fragment } from "react";
import { isAndroid } from "react-device-detect";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";

import { ParentAppContext } from "../contexts/parentAppContext";
import { WindowSizeContext } from "../contexts/windowSizeContext";
import useVideoAreaHeight from "../hooks/useVideoAreaHeight";
import transparent1x1 from "../images/transparent1x1.png";
import PromptTitle from "./PromptTitle";

const Preview = ({
  playerRef,
  videoUrl,
  playing,
  onPause,
  onPlay,
  onEnded,
  prompt,
}) => {
  const videoAreaHeight = useVideoAreaHeight(false);
  const { windowSize } = useContext(WindowSizeContext);
  const { hasParentApp } = useContext(ParentAppContext);
  if (!videoAreaHeight) return <Fragment />;

  // this is duplicated from record
  // todo: refactor
  //sizing the webcam to fill window size
  let videoWidth = windowSize.width;
  // determine the proper video height for the window size width
  let videoHeight = (videoWidth / 9) * 16;
  let videoTopOffset = (videoHeight - videoAreaHeight) * -1;
  let videoLeftOffset = 0;

  if (videoTopOffset > 0) {
    videoHeight = videoAreaHeight;
    videoWidth = (videoHeight / 16) * 9;
    videoLeftOffset = (videoWidth - windowSize.width) * -1;
    videoTopOffset = 0;
  }

  const videoLeftOffsetInverse = videoLeftOffset * -1;
  /*
  console.log("videoHeight", videoHeight);
  console.log("videoTopOffset", videoTopOffset);
  console.log("videoAreaHeight", videoAreaHeight);
  console.log("videoWidth", videoWidth);
  console.log("videoLeftOffset", videoLeftOffset);
  */

  const mobileWebcamStyle = {
    height: videoHeight,
    width: videoWidth,
    overflow: "hidden",
    marginTop: videoTopOffset,
  };

  const desktopWebcamStyle = {
    height: videoAreaHeight,
  };

  // Android app doesn't like to render the first frame for preview and instead
  // shows the WebView's getDefaultVideoPoster() image, which for us is blank.
  // Rather than showing a black screen, we use this hack of adding a timestamp.
  const androidPosterFrameHack = hasParentApp && isAndroid ? "#t=0.0001" : "";
  // Overrides Android's ugly default poster img.
  // const config = isAndroid
  //   ? { file: { attributes: { poster: transparent1x1 } } }
  //   : {};
  const config = {};

  return (
    <div
      className="relative w-full"
      style={
        isMobile
          ? { width: videoWidth, marginLeft: videoLeftOffset }
          : { height: videoAreaHeight }
      }
    >
      <div style={isMobile ? mobileWebcamStyle : desktopWebcamStyle}>
        <ReactPlayer
          ref={playerRef}
          url={videoUrl + androidPosterFrameHack}
          playing={playing}
          loop={false}
          width={"100%"}
          height={isMobile ? "" : videoAreaHeight}
          className="horizontal-flip w-full"
          playsinline={true /* So on mobile does not go fullscreen */}
          onPause={onPause}
          onPlay={onPlay}
          onEnded={onEnded}
          config={config}
        />
        <div
          className="relative"
          style={{ marginLeft: videoLeftOffsetInverse }}
          // ^ I'm hackin' here to get the text positioned relative to screen and not the video overflow
        >
          <PromptTitle text={prompt ? prompt.PromptText : "..."} />
        </div>
      </div>
    </div>
  );
};
export default Preview;
