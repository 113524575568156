import { ChatIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { debounce } from "lodash";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isAndroid, isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { useQuery } from "react-query";
import { useSwipeable } from "react-swipeable";
import useKeypress from "react-use-keypress";
import { elementScrollIntoView } from "seamless-scroll-polyfill"; // For mobile safari smooth scroll. https://github.com/magic-akari/seamless-scroll-polyfill

import { ParentAppContext } from "../../contexts/parentAppContext";
import { WindowSizeContext } from "../../contexts/windowSizeContext";
import transparent1x1 from "../../images/transparent1x1.png";
import { queryGroup, queryUsers } from "../../queries";
import { remToPx, tailwindBreakpointForWidth } from "../../utils";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";
import Comments from "./Comments";
import RosterControls from "./RosterControls";

const InlinePlayerLandscape = ({ prompt, onClose, openComments }) => {
  const { parentAppRotation, hasParentApp } = useContext(ParentAppContext);
  const { windowSize } = useContext(WindowSizeContext);
  const [activeUserId, setActiveUserId] = useState();
  const [playing, _setPlaying] = useState(!openComments); // Don't autoplay if launching with comments.
  const [flashPlay, setFlashPlay] = useState(false);
  const [flashPause, setFlashPause] = useState(false);
  const [showComments, _setShowComments] = useState(openComments);
  const [commentInputHasFocus, setCommentInputHasFocus] = useState(false);
  const [memberRefsByUserId, setMemberRefsByUserId] = useState({});
  const [indexLastScrolled, setIndexLastScrolled] = useState(-1); // Only try auto-scroll once per member change
  const playerRef = useRef(null);
  const backgroundRef = useRef();

  const setShowComments = (show) => {
    if (!show) {
      // If comments were dismissed while had focus, this fixes that
      setCommentInputHasFocus(false);
    }
    _setShowComments(show);
  };

  const setPlaying = useCallback(
    (isPlaying) => {
      // Causes the splash animations and sets state.
      setFlashPlay(!!isPlaying);
      setFlashPause(!isPlaying);
      _setPlaying(!!isPlaying);
    },
    [_setPlaying, setFlashPlay, setFlashPause]
  );

  const togglePlaying = useMemo(() => {
    // Need to debounce this or rapid space bar or clicking seems to get you in f'd up play/pause states.
    // Seems maybe React-player doesn't have a foolproof way to sync up the "playing" state.
    return debounce(() => setPlaying(!playing), 200);
  }, [playing, setPlaying]);

  const onVideoClick = useCallback(() => {
    if (!isMobile) {
      // Disabling video click on Mobile for now. iOS webkit seems to be passing the clicks
      // on video controls through to the start/stop control div that we have listening
      // underneath the player. :/
      togglePlaying();
    }
  }, [togglePlaying]);

  // eslint-disable-next-line no-unused-vars
  const onError = useCallback(
    (e) => {
      // Expected when we try to autostart and can't. We must update our playing state.
      // Could check for e.name === "NotAllowedError"
      // but it's DOM error, and don't know if same on all browsers.
      _setPlaying(false); // Calling direct, no splash animation.
    },
    [_setPlaying]
  );

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  const onEnded = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  useKeypress(
    "Escape",
    useCallback(() => {
      if (showComments) {
        setShowComments(false);
      } else {
        onClose();
      }
    }, [showComments])
  );

  useKeypress(
    " ",
    useCallback(
      (event) => {
        //' ' is space bar
        // when we are potentially typing a comment, we don't want to
        // hijack the space bar
        if (!showComments) {
          togglePlaying();
          event.preventDefault();
        }
        // preventing default to stop background component scrolling! Who is doing that?
      },
      [togglePlaying, showComments]
    )
  );

  const groupId = prompt?.GroupId;
  const { data: group } = useQuery(["queryGroup", { groupId }], queryGroup, {
    enabled: !!groupId,
  });

  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });

  useEffect(() => {
    if (prompt && prompt.Timeline) {
      const refs = {};
      prompt.Timeline.UserEntries.forEach((userEntry) => {
        refs[userEntry.UserId] = createRef();
      });
      setMemberRefsByUserId(refs);
    }
  }, [prompt]);

  const onProgress = useCallback(
    (progress) => {
      // Called every second (by default) from ReactPlayer
      const { playedSeconds } = progress;
      let activeId;
      const userEntries = prompt?.Timeline?.UserEntries || [];
      for (let i = 0; i < userEntries.length; i += 1) {
        const userEntry = userEntries[i];
        // Nudging the playback times here seems to help
        if (
          playedSeconds >= userEntry.StartTime - 0.5 &&
          playedSeconds < userEntry.StartTime + userEntry.VideoLength - 0.7
        ) {
          // We found the activeId...
          activeId = userEntry.UserId;
          // Next try auto-scroll, only try once per member shift...
          if (indexLastScrolled !== i) {
            // Scroll the next two members into view. Or next one. Or just this one...
            const userEntryScrollTarget =
              userEntries[i + 2] || userEntries[i + 1] || userEntry;
            const scrollRef = memberRefsByUserId[userEntryScrollTarget.UserId];
            if (scrollRef?.current) {
              const scrollConfig = { behavior: "smooth", block: "start" };
              if (!showComments) {
                // This polyfill helps iOS, but causes draw issues when comments showing
                elementScrollIntoView(scrollRef?.current, scrollConfig);
              } else {
                scrollRef.current.scrollIntoView(scrollConfig);
              }
              setIndexLastScrolled(i);
            }
          }
          break;
        }
      }
      setActiveUserId(activeId);
    },
    [
      setActiveUserId,
      prompt?.Timeline?.UserEntries,
      memberRefsByUserId,
      indexLastScrolled,
      showComments,
    ]
  );

  const handleMemberClick = useCallback(
    (userId) => {
      setActiveUserId(userId); // Set immediately, instead of waiting for onProgress()
      // nudging the scrub time by .04 seconds
      // so the still image shows the correct person
      // (Note: playerRef.current can be null if video not found)
      if (prompt?.Timeline?.UserEntries) {
        const startTime = prompt.Timeline.UserEntries.find(
          (entry) => entry.UserId === userId
        ).StartTime;
        const adjustedTime = parseFloat(startTime) + 0.08;
        playerRef.current?.seekTo(adjustedTime, "seconds");
      }
      setPlaying(true);
    },
    [setPlaying, setActiveUserId, prompt?.Timeline?.UserEntries]
  );

  // This needs to useCallback so it does't get recreated. Recreate will break the css animation.
  const PlayIndicator = useCallback(() => {
    return (
      <div className="text-white flex w-full h-full items-center justify-center pointer-events-none">
        <div className="w-1/6 h-1/6 animate-splash">
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="black" />
            <path
              d="M38.5 29.6667V71.6667L70 50.6667L38.5 29.6667Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    );
  }, []);

  // This needs to useCallback so it does't get recreated. Recreate will break the css animation.
  const PauseIndicator = useCallback(() => {
    return (
      <div className="text-white flex w-full h-full items-center justify-center pointer-events-none">
        <div className="w-1/6 h-1/6 animate-splash">
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="black" />
            <rect x="57" y="29" width="13" height="42" fill="white" />
            <rect x="57" y="29" width="13" height="42" fill="white" />
            <rect x="31" y="29" width="13" height="42" fill="white" />
            <rect x="31" y="29" width="13" height="42" fill="white" />
          </svg>
        </div>
      </div>
    );
  }, []);

  // BEGIN SIZE CALCULATIONS including rotations...
  const FORCE_ROTATE = false; // true allows debugging on desktop
  const rotated =
    FORCE_ROTATE ||
    parentAppRotation === "LEFT" ||
    parentAppRotation === "RIGHT";
  const transitionClasses = classNames({
    "transition-all": hasParentApp,
  }); // Animates the rotation/resize in app. Unncessarily slow on desktop.
  const rotationClasses = classNames({
    "transform rotate-0": !rotated && !FORCE_ROTATE,
    "transform rotate-90": parentAppRotation === "LEFT" || FORCE_ROTATE,
    "transform -rotate-90": parentAppRotation === "RIGHT" && !FORCE_ROTATE,
  });

  // show additional controls: members, comments
  // in practice this means "we are not rotated"
  const showAdditionalControls = !rotated;
  let membersHeight = 0;
  if (showAdditionalControls) {
    // As long we keep members controls height "h-16 sm:h-20", this code is works...
    const breakpoint = tailwindBreakpointForWidth(windowSize.width);
    if (breakpoint === "") {
      membersHeight = remToPx(4); //h-16
    } else {
      membersHeight = remToPx(5); //h-20
    }
  }
  const hPad = rotated ? 0 : 20;

  const availableWidth = rotated
    ? windowSize.height - membersHeight
    : windowSize.width - hPad;
  const availableHeight = rotated
    ? windowSize.width - hPad
    : windowSize.height - membersHeight;
  const ASPECT_RATIO = 1280 / 720;
  const availableAreaIsWider = availableWidth / availableHeight > ASPECT_RATIO;
  const rotatingStyle = { width: "0px", height: "0px" };
  rotatingStyle.height =
    Math.round(
      availableAreaIsWider ? availableHeight : availableWidth / ASPECT_RATIO
    ) + "px";
  rotatingStyle.width =
    Math.round(
      availableAreaIsWider ? availableHeight * ASPECT_RATIO : availableWidth
    ) + "px";

  // BEGIN SWIPE HANDLING...
  const onSwipeClose = (e) => {
    if (e.target === backgroundRefPassthrough.current) {
      // TODO animate off screen instead of fade away
      onClose();
      e.event.stopPropagation();
    }
  };
  const onSwiped = {};
  if (parentAppRotation === "NONE") {
    onSwiped.onSwipedDown = onSwipeClose;
  } else if (parentAppRotation === "LEFT") {
    onSwiped.onSwipedLeft = onSwipeClose;
  } else if (parentAppRotation === "RIGHT") {
    onSwiped.onSwipedRight = onSwipeClose;
  }
  const swipeHandlers = useSwipeable(onSwiped);
  const backgroundRefPassthrough = (el) => {
    // combine useSwipeable's ref with our backgroundRef
    swipeHandlers.ref(el); // call useSwipeables ref prop with el
    backgroundRef.current = el; // set the el to a ref you can access yourself
  };

  return (
    <div
      {...swipeHandlers} // Must be inserted BEFORE the ref declaration below. WTF
      ref={backgroundRefPassthrough}
      className={`flex-1 flex flex-col bg-black w-full h-full justify-center outline-none focus:outline-none items-center`}
      onClick={(e) => {
        e.preventDefault();
        if (e.target === backgroundRef.current) {
          // Click on background will close player...
          if (!isMobile) {
            onClose();
          }
        }
      }}
    >
      <SafeAreaInsetTop />
      <div className="flex-1 flex flex-col w-full px-2 items-center justify-center">
        {/* {errorMessage && (
          <ErrorDialog message={errorMessage} onClose={onClose} />
        )} */}

        {showAdditionalControls && (
          <div className="absolute top-0 flex-col w-full text-white justify-start items-center">
            <SafeAreaInsetTop />
            <div
              className="text-black flex cursor-pointer pt-2"
              onClick={onClose}
            >
              <XIcon className="ml-2 w-6 h-6 text-white z-10" />
            </div>
          </div>
        )}

        {!!prompt &&
          isMobile &&
          showAdditionalControls && ( // Mobile has different comments button location
            <div
              className="absolute right-0 bottom-10 z-10 text-white mt-6 py-2 pr-4 pl-4 flex-col items-center cursor-pointer select-none ml-auto"
              onClick={(e) => setShowComments(!showComments)}
            >
              <ChatIcon className="w-11 h-11 text-white " />
              <div className={`text-center text-lg`}>
                {prompt.Comments.length}
              </div>
              <SafeAreaInsetBottom />
            </div>
          )}

        {!!prompt && !!group && !!members && (
          <div
            className={`flex flex-col ${rotationClasses} ${transitionClasses}`}
            style={{ width: rotatingStyle.width }}
          >
            {/* whether we position the
            comment box relative to the player
            or the screen depends on whether we're
            in mobile or not */}
            {showComments && !isMobile && (
              <Comments
                promptId={prompt.Id}
                setShowComments={setShowComments}
                commentInputHasFocus={commentInputHasFocus}
                setCommentInputHasFocus={setCommentInputHasFocus}
              />
            )}

            <div
              className={`aspect-w-16 aspect-h-9 flex-1`}
              style={rotatingStyle}
              onClick={onVideoClick}
            >
              {/* Click on body of player, not controls, passes thru to the onVideoClick above */}
              <ReactPlayer
                ref={playerRef}
                url={prompt.FinalVideoUrl}
                playing={playing}
                playsinline={true}
                controls={true}
                loop={false}
                width=""
                height="100%"
                onError={onError}
                onPlay={onPlay}
                onPause={onPause}
                onEnded={onEnded}
                onProgress={onProgress}
                config={{ file: { attributes: { poster: transparent1x1 } } }} // Overrides Android's ugly default poster img.
              />
              {/* Android chrome seems to draw its own play/pause */}
              {flashPause && !isAndroid && <PauseIndicator />}
              {flashPlay && !isAndroid && <PlayIndicator />}
            </div>
            {showAdditionalControls && (
              <div className="flex h-16 sm:h-20">
                {/* h-16 sm:h-20 is important for "showAdditionalControls" */}
                <RosterControls
                  members={members}
                  handleMemberClick={handleMemberClick}
                  activeUserId={activeUserId}
                  memberRefsByUserId={memberRefsByUserId}
                  prompt={prompt}
                />

                {!isMobile && ( // Mobile has different comments button location
                  <div
                    className="text-white bg-black p-6 flex cursor-pointer"
                    onClick={(e) => setShowComments(!showComments)}
                  >
                    {prompt.Comments.length} comments
                    <ChatIcon className="ml-2 w-6 h-6 text-white" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* whether we position the
            comment box relative to the player
            or the screen depends on whether we're
            in mobile or not */}
        {showComments && isMobile && !!group && !!prompt && (
          <Comments
            promptId={prompt.Id}
            setShowComments={setShowComments}
            commentInputHasFocus={commentInputHasFocus}
            setCommentInputHasFocus={setCommentInputHasFocus}
            hidden={!showAdditionalControls}
            rotated={rotated}
          />
        )}
      </div>

      <SafeAreaInsetBottom />
    </div>
  );
};

export default InlinePlayerLandscape;
