import dayjs from "dayjs";
import { Fragment } from "react";

import play from "../images/play-yellow.png";
import FixedImg from "./Common/FixedImg";
import FixedLink from "./Common/FixedLink";

const Twelve = ({ group, response }) => {
  return (
    <div className="flex mb-4 w-full">
      <a
        href={response.VideoUrl}
        className="flex-shrink-0 cursor-pointer"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="relative">
          <FixedImg
            src={response.ThumbnailUrl}
            alt="Preview"
            className="mr-2 w-32 h-18"
          />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
            <FixedImg src={play} alt="Play" />
          </div>
        </div>
      </a>

      <div className="flex flex-col">
        <div className="cursor-pointer text-xs font-bold text-blue">
          {group.Name}
        </div>

        <div className="my-2 text-sm text-gray">
          <a
            href={response.VideoUrl}
            className="flex-shrink-0 cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            {response.Prompt.PromptText}
          </a>
        </div>
        <div className="text-xs">
          {`${dayjs(new Date(response.Prompt.ScheduledDate * 1000)).format(
            "MM/DD/YYYY"
          )}`}{" "}
          |{" "}
          <a
            href={response.VideoUrl}
            className="flex-shrink-0 cursor-pointer text-blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            Play Video
          </a>{" "}
          |{" "}
          <FixedLink
            className="text-blue"
            href={`/responses/${response.Prompt.Id}`}
          >
            See Team 12
          </FixedLink>
        </div>
      </div>
    </div>
  );
};

const Twelves = ({ group, responses }) => {
  if (!responses) return <Fragment />;
  return (
    <div>
      {responses.map((response) => (
        <Twelve group={group} response={response} key={response.Prompt.Id} />
      ))}
    </div>
  );
};

export default Twelves;
