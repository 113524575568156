/*
ClosablePromptTimes and CloseablePromptScheduleDescription are
for supporting the production environment in the interim
period, before always-open prompts are rolled out to prod
*/

// So, this whole thing is complicated by the fact that TimePicker likes Dayjs objects
// (because we tell it to use that date library), but our APIs want time strings,
// e.g. "16:20:00".  We use Dayjs objs in all the  state and convert to and from time
// strings only when needed.
import util from "util";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import TimePicker from "@material-ui/lab/TimePicker";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { updateGroup } from "../../api";
import { AppContext } from "../../contexts/appContext";
import SaveControls from "../Common/SaveControls";
import ActiveDaysPicker from "./ActiveDaysPicker";
import PromptScheduleDescription from "./CloseablePromptScheduleDescription";

const dateForTime = (timeStr) => {
  const timeParts = timeStr.split(":").map((x) => parseInt(x));
  return dateForHM(timeParts[0], timeParts[1]);
};

const dateForHM = (hours, minutes) => {
  // Arbitrary day, it just needs to be the same one all the time for comparisons.
  const d = new Date(2020, 12, 12);
  return dayjs(d).add(hours, "hours").add(minutes, "minutes");
};

const timeFromDate = (date) => {
  const time = dayjs(date).format("HH:mm:ss");
  return time;
};

const isSameTime = (date, timeString) => {
  let isSame = false;
  if (date?.isValid()) {
    // Invalid Dates, which the Picker can create while editing,
    // do not have the getTime() function
    isSame = dayjs(date).isSame(dateForTime(timeString));
  }
  return isSame;
};

const RELEASE_AFTER_CLOSE_MINUTES = 5;
const MIN_OPEN_MINUTES = 29;
const MAX_CLOSE_DATE_TIME = dateForHM(23, 50);
const TIME_ERROR_MESSAGE =
  "Open Time and Close Time must both be valid times and must have at least 30 minutes difference. At least one weekday must be selected.";
const TIMEZONE_LABEL = "Time zone";

const PromptTimes = ({ group }) => {
  if (!group) return <Fragment />;

  const { displayError, displayModal } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [activeDays, setActiveDays] = useState(group.ActiveDays);
  const [timeZone, setTimeZone] = useState(group.TimeZone);
  const [timeReset, setTimeReset] = useState(dateForTime(group.TimeReset));
  const [timeClose, _setTimeClose] = useState(dateForTime(group.TimeClose));
  const [timeRelease, setTimeRelease] = useState(
    dateForTime(group.TimeRelease)
  );
  const [showSave, setShowSave] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [saving, setSaving] = useState(false);

  // Here we are also auto setting the release time 5 min after close...
  const setTimeClose = (date) => {
    _setTimeClose(date);
    if (date?.isValid()) {
      setTimeRelease(dayjs(date).add(RELEASE_AFTER_CLOSE_MINUTES, "minute"));
    } else {
      setTimeRelease(date);
    }
  };

  const onCancel = () => {
    setActiveDays(group.ActiveDays);
    setTimeZone(group.TimeZone);
    setTimeClose(dateForTime(group.TimeClose));
    setTimeRelease(dateForTime(group.TimeRelease));
    setTimeReset(dateForTime(group.TimeReset));
  };

  useEffect(() => {
    setShowSave(
      !isEqual(activeDays, group.ActiveDays) ||
        timeZone !== group.TimeZone ||
        !isSameTime(timeReset, group.TimeReset) ||
        !isSameTime(timeClose, group.TimeClose) ||
        !isSameTime(timeRelease, group.TimeRelease)
    );
  }, [activeDays, timeZone, timeClose, timeRelease, timeReset, group]);

  useEffect(() => {
    setSaveEnabled(
      activeDays.length > 0 &&
        timeClose?.isValid() &&
        timeReset?.isValid() &&
        dayjs(timeClose).diff(dayjs(timeReset), "minute") >= MIN_OPEN_MINUTES
    );
  }, [activeDays, timeClose, timeReset]);

  const onSave = useCallback(async () => {
    setSaving(true);
    const data = {
      ActiveDays: activeDays,
      TimeClose: timeFromDate(timeClose),
      TimeRelease: timeFromDate(timeRelease),
      TimeReset: timeFromDate(timeReset),
      TimeZone: timeZone,
    };
    try {
      await updateGroup(group.Id, data);
      displayModal(
        "Prompt Schedule Updated",
        <span className="pt-6">
          <PromptScheduleDescription
            activeDays={activeDays}
            timeReset={timeFromDate(timeReset)}
            timeClose={timeFromDate(timeClose)}
            centerText={false}
          />
        </span>
      );
    } catch (e) {
      //console.log(util.inspect(e, { depth: 6 }));
      displayError(e.response.data.Error.Message);
    }
    queryClient.invalidateQueries("queryGroup");
    const groupId = group.Id;
    queryClient.invalidateQueries(["queryGroupManage", { groupId }]); // Also refresh future prompts
    setSaving(false);
  }, [activeDays, timeZone, timeClose, timeRelease, timeReset]);

  const onTimePickerChange = (value, setter) => {
    // value is dayjs datetime relative to NOW. May be invalid.
    let date = value;
    if (date?.isValid()) {
      // But we don't want NOW's day on the time, we need our constant arbitrary day...
      date = dateForHM(date.hour(), date.minute());
    }
    setter(date);
  };

  const onTimezoneSelect = (event) => {
    setTimeZone(event.target.value);
  };

  return (
    <div className="flex flex-col text-gray-500 items-start w-full">
      {/* <PromptScheduleDescription group={group} allowScheduledLink={false} /> */}

      <div className="pb-8 w-full">
        <ActiveDaysPicker
          activeDays={activeDays}
          setActiveDays={setActiveDays}
        />
      </div>

      <div className="pb-8 w-full">
        <div className="flex flex-row space-x-2">
          <div className="w-1/2">
            <TimePicker
              minutesStep={5}
              inputFormat="h:mm A"
              label={"Opening at"}
              value={timeReset}
              onChange={(value) => {
                onTimePickerChange(value, setTimeReset);
              }}
              renderInput={(params) => {
                return <TextField {...params}></TextField>;
              }}
              maxTime={dayjs(timeClose).subtract(MIN_OPEN_MINUTES, "minute")}
            />
          </div>
          <div className="w-1/2">
            <TimePicker
              minutesStep={5}
              inputFormat="h:mm A"
              label={"Closing at"}
              value={timeClose}
              onChange={(value) => {
                onTimePickerChange(value, setTimeClose);
              }}
              renderInput={(params) => {
                return <TextField {...params}></TextField>;
              }}
              minTime={dayjs(timeReset).add(MIN_OPEN_MINUTES, "minute")}
              maxTime={MAX_CLOSE_DATE_TIME}
            />
          </div>
        </div>
      </div>
      <div className="pb-2 w-full">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-label">
            {TIMEZONE_LABEL}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeZone}
            onChange={onTimezoneSelect}
            label={TIMEZONE_LABEL}
          >
            <MenuItem value={"America/Anchorage"}>America/Anchorage</MenuItem>
            <MenuItem value={"America/Los_Angeles"}>
              America/Los Angeles
            </MenuItem>
            <MenuItem value={"America/Phoenix"}>America/Phoenix</MenuItem>
            <MenuItem value={"America/Denver"}>America/Denver</MenuItem>
            <MenuItem value={"America/Chicago"}>America/Chicago</MenuItem>
            <MenuItem value={"America/New_York"}>America/New York</MenuItem>
          </Select>
        </FormControl>
      </div>
      <SaveControls
        show={showSave}
        onSave={onSave}
        onCancel={onCancel}
        saving={saving}
        saveEnabled={saveEnabled}
        disabledMessage={TIME_ERROR_MESSAGE}
      />
    </div>
  );
};

export default PromptTimes;
