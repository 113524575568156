import { Fragment, useContext } from "react";
import { isMobile } from "react-device-detect";

import { WindowSizeContext } from "../contexts/windowSizeContext";
import useVideoAreaHeight from "../hooks/useVideoAreaHeight";

const aspectRatio = 1.77777777778;

const PromptTitle = ({ text }) => {
  const { windowSize, windowSizeReady } = useContext(WindowSizeContext);
  const videoAreaHeight = useVideoAreaHeight();

  if (!windowSizeReady || !videoAreaHeight) return <Fragment />;

  let videoWidth = 0;
  let videoHeight = 0;
  let gutterWidth = 0;
  let titleBottom = 0;
  let titleRight = 0;
  let titleWidth = 0;

  if (videoAreaHeight * aspectRatio <= windowSize.width) {
    // fits top to bottom, gutters on left and right
    videoHeight = videoAreaHeight;
    videoWidth = videoHeight * aspectRatio;
    gutterWidth = (windowSize.width - videoWidth) / 2;
    titleBottom = Math.round(videoAreaHeight / 20);
    titleRight = gutterWidth;
  } else {
    // fits left to right, gutters on top and bottom
    videoWidth = windowSize.width;
    videoHeight = windowSize.width / aspectRatio;
    gutterWidth = (videoAreaHeight - videoHeight) / 2;
    titleBottom = gutterWidth + Math.round(videoAreaHeight / 20);
    titleRight = 0;
  }

  if (isMobile) {
    titleWidth = Math.round(videoWidth / 1.5);
    titleBottom = Math.round(videoAreaHeight / 20);
  } else {
    titleWidth = Math.round(videoWidth / 3);
  }

  return (
    <div
      className="absolute text-white text-shadow p-4 text-lg sm:text-base select-none"
      style={{ width: titleWidth, left: 0, bottom: 0 }}
    >
      {text}
    </div>
  );
};

export default PromptTitle;
