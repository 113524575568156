import { useCallback, useContext, useEffect, useState } from "react";

import { MediaContext } from "../contexts/mediaContext";
import { ParentAppContext } from "../contexts/parentAppContext";

const MediaContextProvider = ({ children }) => {
  const [audioDevice, _setAudioDevice] = useState();
  const [videoDevice, _setVideoDevice] = useState();
  const [mediaDevices, setMediaDevices] = useState([]);
  const [hasMediaPermission, setHasMediaPermission] = useState(false); // may not have been asked yet
  const [mediaPermissionDenied, setMediaPermissionDenied] = useState(false); // has been asked, but denied

  const {
    hasParentApp,
    parentAppNeedsMediaPermissions,
    parentAppDeclinedMediaPermissions,
    requestParentAppMediaPermissions,
    hasParentAppMediaPermissions,
  } = useContext(ParentAppContext);

  const setAudioDevice = (device) => {
    if (device) {
      localStorage.setItem("preferred-audio-device", JSON.stringify(device));
    }
    _setAudioDevice(device);
  };

  const setVideoDevice = (device) => {
    if (device) {
      localStorage.setItem("preferred-video-device", JSON.stringify(device));
    }
    _setVideoDevice(device);
  };

  const requestMediaPermissionWebRTC = useCallback(async () => {
    console.log("MCP. requestMediaPermissionWebRTC()");

    // Return if we already know we have permission. getUserMedia
    // a second time causes black screen issues on iOS, and it
    // shouldn't be necessary to run this function again anyways.
    if (hasMediaPermission) return;

    const getStoredDevice = (type, allDevices) => {
      let device;
      const key = `preferred-${type}-device`;
      const value = localStorage.getItem(key);
      if (value) {
        try {
          device = JSON.parse(value);
          device = findMatchingDevice(device, allDevices);
        } catch (e) {
          localStorage.removeItem(key);
        }
      }
      return device;
    };

    const findDeviceForId = (deviceId, allDevices) => {
      let match = allDevices.find((device) => {
        return deviceId === device.deviceId;
      });
      return match;
    };

    const findMatchingDevice = (storedDevice, allDevices) => {
      // If IDs did not change, it's easy...
      let match = allDevices.find((device) => {
        return storedDevice.deviceId === device.deviceId;
      });
      // Else try matching on the beginning of the label, i.e. everything before "("
      if (!match) {
        match = allDevices.find((device) => {
          const storedLabelSubstring = storedDevice.label.split("(")[0];
          const deviceLabelSubstring = device.label.split("(")[0];
          const isCloseMatch = storedLabelSubstring === deviceLabelSubstring;
          //if (isCloseMatch) console.log("Found matching device: " + deviceLabelSubstring);
          return isCloseMatch;
        });
      }
      return match; // Will be undefined if no match found
    };

    let stream;
    try {
      const constraints = {
        audio: { deviceId: undefined },
        video: {
          deviceId: undefined,
          facingMode: "user",
          width: 1280,
          height: 720,
        },
      };
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (e) {
      console.log("MCP. Error in requestMediaPermission", e);
    }
    if (stream) {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      // Try to use devices that match stored devices
      let storedAudioDevice = getStoredDevice("audio", allDevices);
      let storedVideoDevice = getStoredDevice("video", allDevices);
      if (storedAudioDevice) {
        setAudioDevice(storedAudioDevice);
      }
      if (storedVideoDevice) {
        setVideoDevice(storedVideoDevice);
      }
      // If we didn't find anything matching stored device, we'll
      // set our device to the default one returned by getUserMedia().
      // We can get that device object by looking at the tracks.
      const tracks = stream.getTracks();
      // ^ See: https://w3c.github.io/mediacapture-main/#mediastreamtrack
      tracks.forEach((track) => {
        const device = findDeviceForId(
          track.getSettings().deviceId,
          allDevices
        );
        // ^ See: https://w3c.github.io/mediacapture-main/#media-track-settings
        if (track.kind === "audio" && !storedAudioDevice) {
          setAudioDevice(device);
        } else if (track.kind === "video" && !storedVideoDevice) {
          setVideoDevice(device);
        }
        // IMPORTANT! Now we must stop all the tracks. All we we do
        // request permissions and get device IDs.
        // If we don't stop the tracks, the stream stays on forver,
        // and the camera light will stay on, at least in Safari.
        track.stop();
      });
      setMediaDevices(allDevices);
      setHasMediaPermission(true);
      setMediaPermissionDenied(false);
    } else {
      console.log("MCP. No Stream. Media permission denied");
      setMediaPermissionDenied(true);
    }
  }, [hasMediaPermission]);

  const requestMediaPermission = useCallback(async () => {
    // TODO We could think about first resetting mediaPermissionDenied(false) here in case of a retry, however,
    // mobile browsers seem to only request permission once--and after that you are denied until page reload.
    if (parentAppNeedsMediaPermissions) {
      requestParentAppMediaPermissions();
    } else {
      requestMediaPermissionWebRTC();
    }
  }, [
    requestParentAppMediaPermissions,
    requestMediaPermissionWebRTC,
    parentAppNeedsMediaPermissions,
  ]);

  useEffect(() => {
    if (hasParentApp) {
      if (parentAppDeclinedMediaPermissions) {
        setMediaPermissionDenied(true);
      } else if (hasParentAppMediaPermissions) {
        requestMediaPermissionWebRTC();
      }
    }
  }, [
    hasParentApp,
    parentAppDeclinedMediaPermissions,
    hasParentAppMediaPermissions,
  ]);

  return (
    <MediaContext.Provider
      value={{
        mediaDevices, // All devices
        audioDevice, // Null until we have a permissioned device
        setAudioDevice,
        videoDevice, // Null until we have a permissioned device
        setVideoDevice,
        hasMediaPermission, // If we have permissioned devices
        requestMediaPermission,
        mediaPermissionDenied, // If we requested but were denied
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export default MediaContextProvider;
