// PageMargin is a PageFull (under header) with a scrolling margined container inside.

import classNames from "classnames";
import { useContext, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";
import Measure from "react-measure";

import { WindowSizeContext } from "../../contexts/windowSizeContext";
import PageFull from "./PageFull";

const safeAreaInsetTop =
  getComputedStyle(document.documentElement)
    .getPropertyValue("--safe-area-inset-top")
    ?.split("px")[0] || "0";
const HEADER_HEIGHT = 80 + parseInt(safeAreaInsetTop);

const PageMargin = ({ children, backgroundColor = "light-gray-6" }) => {
  const { windowSize } = useContext(WindowSizeContext);
  const height = (windowSize.height || window.innerHeight) - HEADER_HEIGHT;
  const width = windowSize.width || window.innerWidth;
  const ScrollbarsRef = useRef(null);
  const marginClasses = classNames(
    "flex flex-col items-center",
    "pt-9 px-3",
    { "mb-32": isMobile }, // Add bottom pad to scroll page on mobile because Mobile safari toolbar is annoying.
    { "mb-16": !isMobile }
  );
  return (
    <PageFull backgroundColor={backgroundColor}>
      <Scrollbars ref={ScrollbarsRef} style={{ width: width, height: height }}>
        <Measure
          bounds
          onResize={() => {
            ScrollbarsRef.current && ScrollbarsRef.current.forceUpdate();
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className={marginClasses}>
              <div className="container sm:px-3">{children}</div>
            </div>
          )}
        </Measure>
      </Scrollbars>
    </PageFull>
  );
};

export default PageMargin;
