import { VolumeOffIcon, VolumeUpIcon } from "@heroicons/react/solid";
import { useCallback, useState } from "react";
import ReactPlayer from "react-player";

import MarketingVideoPosterFrame from "../../images/marketing-video-poster-frame.png";

const MarketingPlayer = () => {
  const [isMuted, setIsMuted] = useState(true);

  const MuteToggle = useCallback(
    () => (
      <div
        className={`bg-black border-white border-4 h-12 w-12 rounded-full p-2 text-white cursor-pointer select-none `}
      >
        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </div>
    ),
    [isMuted]
  );

  return (
    <div className="relative" onClick={() => setIsMuted(!isMuted)}>
      <div className="relative rounded-3xl shadow-2xl overflow-hidden">
        {/* ^ don't know why, but relative above is needed for rounded corners */}
        <ReactPlayer
          config={{ attributes: { poster: MarketingVideoPosterFrame } }}
          url={process.env.PUBLIC_URL + "/video/12sample.mp4"}
          playing={true}
          loop={true}
          width={"100%"}
          height={"100%"}
          playsinline={true}
          muted={isMuted}
        />
      </div>
      <div
        className={`absolute top-4 right-4 flex flex-col items-center ${
          isMuted ? "animate-pulse" : ""
        }`}
      >
        <MuteToggle />
        {isMuted && (
          <div className="text-white text-2xs pt-1 font-medium">MUTED</div>
        )}
      </div>
    </div>
  );
};

export default MarketingPlayer;

/* HERE'S A MOBILE PHONE FRAME IN CSS in case we want it...

<div className="hidden relative sm:flex sm:w-96 sm:items-center sm:justify-center ml-8">
                <div className="absolute top-0 w-32 h-9 bg-black rounded-b-2xl">
                  <div className="w-4 h-4 mt-3 ml-24 bg-gray-800 rounded-full"></div>
                </div>
                <video
                  controls
                  height={640}
                  width={360}
                  className="shadow-2xl border-black bg-black"
                  style={{ borderRadius: "2rem", borderWidth: "10px" }}
                  autoPlay
                  muted
                  loop
                >

*/
