import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { queryGroups, queryHomePageDedupedData } from "../queries";
import NavPage from "./Common/NavPage";
import GroupCreateLink from "./GroupCreateLink";
import PromptsGrid from "./PromptsGrid";

const Videos = () => {
  const [showGroupCreateLink, setShowGroupCreateLink] = useState(false);
  const [recentPrompts, setRecentPrompts] = useState();
  const { data: groups } = useQuery("queryGroups", queryGroups);
  const { data: homePageDedupedData } = useQuery(
    "queryHomePageDedupedData",
    queryHomePageDedupedData,
    {
      // Shorten cache so you don't always see yesterday's prompts
      cacheTime: 1000 * 60 * 60 * 1, // 1 hours
    }
  );

  useEffect(() => {
    if (homePageDedupedData) {
      const { recentPrompts: recent } = homePageDedupedData;
      setRecentPrompts(recent);
    }
  }, [homePageDedupedData, setRecentPrompts]);

  // People with no groups go to group create page
  useEffect(() => {
    if (groups) {
      setShowGroupCreateLink(groups.length === 0);
    }
  }, [groups]);

  return (
    <NavPage backgroundColor="lightGray-6" title="Videos">
      <div className="container mx-auto">
        <div className="flex flex-col items-center pb-3">
          {recentPrompts && (
            <PromptsGrid prompts={recentPrompts} showTeam={true} />
          )}
          {showGroupCreateLink && (
            <GroupCreateLink text="Create a team to make videos!" />
          )}
        </div>
      </div>
    </NavPage>
  );
};

export default Videos;
