import { remToPx } from "../../utils";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";

const TimerBar = ({ remainingMilliseconds, maxMilliseconds }) => {
  const remPx = remToPx(1.25); //h-5
  const remainingSeconds = Math.ceil(remainingMilliseconds / 1000);
  const percentRemaining = (remainingMilliseconds / maxMilliseconds) * 100;
  const width = percentRemaining + "%";
  const textCenterFixClass = remainingSeconds < 10 ? "pl-px" : "pl-0";

  return (
    <div className="relative">
      <SafeAreaInsetTop />

      <div className="relative w-full h-5 flex flex-col justify-center">
        {/* Background white circle with spinner... */}
        <div className="absolute flex justify-center w-full">
          <div className="bg-white rounded-full w-5 h-5 " />

          <div className="absolute flex justify-center w-full">
            <div className="absolute rounded mx-auto w-5 h-5 flex justify-items-center items-center animate-spin overflow-visible opacity-70">
              <svg
                className="overflow-visible"
                width={remPx}
                height={remPx}
                xmlns="http://www.w3.org/2000/svg"
              >
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop stopColor="#0FEFFD80" />
                  <stop offset="0.515718" stopColor="#FF00F580" />
                  <stop offset="0.776042" stopColor="#0FEFFD80" />
                  <stop offset="0.984375" stopColor="#FF00F580" />
                </linearGradient>
                <circle
                  shapeRendering="optimizeQuality"
                  r={Math.round(remPx / 2)}
                  cx={Math.round(remPx / 2)}
                  cy={Math.round(remPx / 2)}
                  strokeWidth={Math.round(remPx / 5)}
                  fill="none"
                  stroke="url(#linearColors)"
                ></circle>
              </svg>
            </div>
          </div>
        </div>

        {/* Shrinking timer bar... */}
        <div className="absolute flex h-5 justify-center items-center w-full opacity-90">
          <div
            className="bg-white h-0.5 rounded-full"
            style={{ width: width }}
          />
        </div>

        {/* Center positioned counter... */}
        <div className="absolute flex justify-center w-full">
          <div
            className={`{w-5 h-5 flex justify-center items-center text-xs font-bold text-black ${textCenterFixClass}`}
          >
            {remainingSeconds}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimerBar;
