// So you don't have to always search the array to check a day. One pass to get all the bools.
export const getGroupActiveDayBooleans = (groupActiveDays) => {
  let sun,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat = false;
  groupActiveDays.forEach((day) => {
    switch (day) {
      case "Sunday":
        sun = true;
        break;
      case "Monday":
        mon = true;
        break;
      case "Tuesday":
        tue = true;
        break;
      case "Wednesday":
        wed = true;
        break;
      case "Thursday":
        thu = true;
        break;
      case "Friday":
        fri = true;
        break;
      case "Saturday":
        sat = true;
        break;
    }
  });
  return {
    sun,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
  };
};
