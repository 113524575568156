import { useContext } from "react";
// Full page of Yellow with container and minimal side margins
import { isIOS, isMobile } from "react-device-detect";

import { ParentAppContext } from "../../contexts/parentAppContext";
import SafeAreaInsetBottom from "./SafeAreaInsetBottom";
import SafeAreaInsetTop from "./SafeAreaInsetTop";

const YellowScreen = ({ children }) => {
  const { parentAppIsReady } = useContext(ParentAppContext);

  // Here we make contents of a yellow screen fade in when on mobile app and first load.
  // Looks cool for launch screens, and also works around the issue of SafeAreaInsets
  // not being immediately defined, causing snapping.
  const fadeInOpacity = parentAppIsReady ? "opacity-100" : "opacity-0";

  // Fix for Safari mobile web, where min-h-screen gives screen height that includes the safari toolbars,
  // That messes up the v centering.  However, other browsers react badly to this, so only do mobile safari.
  // Does not seem to adversely affect the mobile app.
  const style =
    isMobile && isIOS ? { minHeight: "-webkit-fill-available" } : {};

  return (
    <div
      className={`bg-yellow h-screen min-h-screen flex flex-col items-center justify-center`}
      style={style}
    >
      <div
        className={`flex flex-col items-center justify-center ${fadeInOpacity} transition-opacity duration-1000`}
      >
        <SafeAreaInsetTop />
        <div
          className={
            "container flex flex-col flex-1 items-center justify-center mx-4"
          }
        >
          {children}
        </div>
        <SafeAreaInsetBottom />
      </div>
    </div>
  );
};

export default YellowScreen;
