import rightBolts from "../images/right-bolts.svg";
import FixedImg from "./Common/FixedImg";
import ProfilePhotoWidget from "./ProfilePhotoWidget";

const WalkthroughProfilePic = ({ user, handleStepFinished }) => {
  return (
    <div className="container mx-auto flex justify-center items-center flex-col">
      <FixedImg src={rightBolts} alt="Bolts!!!" className="pb-8" />
      <div className="text-black text-center font-bold text-xl uppercase mb-6">
        Add a profile pic
      </div>
      <ProfilePhotoWidget
        borderColorClassName="border-black"
        user={user}
        nextStep={handleStepFinished}
      />
      <button
        className="pt-10 text-xs text-black opacity-70 underline"
        onClick={handleStepFinished}
      >
        Skip this step
      </button>
    </div>
  );
};

export default WalkthroughProfilePic;
