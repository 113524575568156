import { Fragment } from "react";

const SaveControls = ({
  show,
  onSave,
  onCancel,
  saving,
  saveEnabled,
  disabledMessage,
}) => {
  return (
    <Fragment>
      {show && (
        <div className="py-4 text-center flex flex-col">
          <div className="flex justify-content space-x-6">
            <button
              className={`px-8 py-2 border bg-white rounded-full ${
                saving ? "text-gray-200" : "text-gray-500"
              } font-medium uppercase rounded-full text-xs`}
              onClick={onCancel}
              disabled={saving}
            >
              Cancel
            </button>
            <button
              className={`px-8 py-2 ${
                saveEnabled ? "bg-black" : "bg-gray-200"
              } text-white font-bold uppercase rounded-full text-xs`}
              onClick={onSave}
              disabled={!saveEnabled}
            >
              {saving ? "Saving..." : "Save Changes"}
            </button>
          </div>
          {show && !saveEnabled && disabledMessage && (
            <div className="pt-2 text-xs text-red">{disabledMessage}</div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SaveControls;
