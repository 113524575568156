import util from "util";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import { TextField } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { joinGroup, loginStart, signupStart } from "../api";
import { AppContext } from "../contexts/appContext";
import useSearchParam from "../hooks/useSearchParam";
import bolts from "../images/black-bolts.svg";
import puff from "../images/puff-black.svg";
import logo from "../images/twelve-logo.svg";
import AuthFooter from "./Common/AuthFooter";
import FatTitle from "./Common/FatTitle";
import FixedImg from "./Common/FixedImg";
import FixedLink from "./Common/FixedLink";
import GoButton from "./Common/GoButton";
import SafeAreaInsetTop from "./Common/SafeAreaInsetTop";
import YellowScreen from "./Common/YellowScreen";
import Spinner from "./Spinner";

// for reference, using react-hook-form with material-ui
// https://levelup.gitconnected.com/using-react-hook-form-with-material-ui-components-ba42ace9507a

const Auth = ({ isSignup, code }) => {
  const queryClient = useQueryClient();
  const emailSearchParamValue = useSearchParam("email");
  const { me, displayModal } = useContext(AppContext);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { handleSubmit, control } = useForm();
  const [, setLocation] = useLocation();

  // if a user is logged in, and gets sent to this invite
  // page with a code, we just try to join the group
  // and then redirect there
  useEffect(() => {
    if (me && code) {
      joinGroup(code)
        .then(() => {
          queryClient.invalidateQueries("queryGroups"); // Refresh Groups
          queryClient.invalidateQueries("queryHomePageDedupedData"); // Refresh Prompts
          displayModal("Success!", "You've joined a new team!");
          setLocation("/videos");
        })
        .catch((err) => {
          //console.log(util.inspect(me, { depth: 5 }));
          // Since user is already logged in and has clicked a link, we use displayError
          // and reroute to /videos
          displayModal("Whoops!", err.response.data.Error.Message);
          setLocation("/videos");
        });
    }
  }, [me, code, setLocation, queryClient]);

  const onSubmit = async (values) => {
    setSubmitting(true);
    setErrorMessage(); //clear error

    if (isSignup) {
      const result = await signupStart(values.email, values.code).catch(
        (err) => {
          console.log(err.response);
          setErrorMessage(
            err.response.data.Error.FieldValidationResults?.Code ||
              err.response.data.Error.Message
          );
          setSubmitting(false);
        }
      );
      if (result) {
        const token = result.data?.Data?.Token;
        if (token) {
          // Signups via email invites return token and don't require email verification step
          setLocation(`/signup/${token}`);
        } else {
          // Else go to "check your email!"
          setSubmitted(true);
          setSubmitting(false);
        }
      }
    } else {
      const result = await loginStart(values.email).catch((err) => {
        setErrorMessage(err.response.data.Error.Message);
        setSubmitting(false);
      });
      if (result) {
        setSubmitted(true);
        setSubmitting(false);
      }
    }
  };

  const BackButton = () => (
    <div className="absolute top-5 left-5">
      <SafeAreaInsetTop />
      <FixedLink href="/">
        <div className="flex select-none cursor-pointer">
          <ChevronLeftIcon className="h-6 w-6 text-black" />
          <div className="font-med">Back</div>
        </div>
      </FixedLink>
    </div>
  );

  if (submitting)
    return (
      <div className="flex min-h-screen min-w-screen bg-yellow items-center justify-center">
        <FixedImg src={puff} alt="Loading" />
      </div>
    );

  if (submitted)
    return (
      <YellowScreen>
        <BackButton />
        <FixedImg
          src={logo}
          className="sm:w-1/3 w-1/2 mb-16"
          alt="Twelve Seconds"
        />
        <FatTitle>Nice! Check your email, friend!</FatTitle>
        {isMobile && (
          <div className="mt-16 font-med text-center">
            Open the emailed link on your phone.
          </div>
        )}
      </YellowScreen>
    );

  if (me) {
    // We are going to get redirected soon, don't flash the regular page
    return <Spinner />;
  }

  return (
    <YellowScreen>
      <BackButton />
      <div>
        <div className="flex flex-col items-center select-none flex-1">
          <div className="mb-8">
            {isSignup ? (
              <FixedImg
                src={bolts}
                className="w-1/3 sm:w-1/4 mx-auto"
                alt="12seconds"
              />
            ) : (
              <FixedImg
                src={logo}
                className="w-1/2 sm:w-1/3 mx-auto"
                alt="12seconds"
              />
            )}
            {isSignup && (
              <div className="mt-8">
                <FatTitle>Create an account with your invite code</FatTitle>
              </div>
            )}
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4 sm:w-1/2 w-3/4"
          >
            <Controller
              name="email"
              control={control}
              defaultValue={emailSearchParamValue || ""}
              rules={{ required: "Email required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Email address"
                  variant="filled"
                  type="email"
                  autoComplete="username"
                  value={value}
                  onChange={(x) => {
                    setErrorMessage(); //Clear error message
                    onChange(x);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            {isSignup && (
              <Controller
                name="code"
                control={control}
                defaultValue={code}
                rules={{ required: "Invitation code required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Invitation code"
                    variant="filled"
                    value={value}
                    onChange={(x) => {
                      setErrorMessage(); //Clear error message
                      onChange(x);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
            {/*
            <input
              type="email"
              autoComplete="username"
              name="email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              placeholder="Enter your email address"
              className="py-4 w-80 text-center text-black focus:outline-none bg-transparent border-b border-black placeholder-gray-700"
            />
            <div className="py-2 text-red text-sm select-none">
              {errors.email && errors.email.message}
            </div>
            */
            /*
            {isSignup ? (
              <div>
                <input
                  name="code"
                  ref={register({
                    required: "Required",
                  })}
                  defaultValue={code}
                  placeholder="Enter your invitation code"
                  className="py-4 w-80 text-center text-black focus:outline-none bg-transparent border-b border-black placeholder-gray-700"
                />
                <div className="py-2 text-red text-sm select-none">
                  {errors.code && errors.code.message}
                </div>
              </div>
            ) : (
              <Fragment />
            )}
            */}
            <div className="flex justify-end items-center space-between">
              {errorMessage && (
                <div className="text-red font-bold w-full pr-2">
                  {errorMessage}
                </div>
              )}
              <GoButton type="submit" />
            </div>
          </form>
        </div>
        <AuthFooter />
      </div>
    </YellowScreen>
  );
};

export default Auth;
