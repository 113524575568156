import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import TimePicker from "@material-ui/lab/TimePicker";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { updateMe } from "../api";
import { AppContext } from "../contexts/appContext";
import SaveControls from "./Common/SaveControls";
import SelectTimeZone from "./Common/SelectTimeZone";

const dateForTime = (timeStr) => {
  const timeParts = timeStr.split(":").map((x) => parseInt(x));
  return dateForHM(timeParts[0], timeParts[1]);
};

const dateForHM = (hours, minutes) => {
  // Arbitrary day, it just needs to be the same one all the time for comparisons.
  const d = new Date(2020, 12, 12);
  return dayjs(d).add(hours, "hours").add(minutes, "minutes");
};

const timeFromDate = (date) => {
  const time = dayjs(date).format("HH:mm:ss");
  return time;
};

const isSameTime = (date, timeString) => {
  let isSame = false;
  if (date?.isValid()) {
    // Invalid Dates, which the Picker can create while editing,
    // do not have the getTime() function
    isSame = dayjs(date).isSame(dateForTime(timeString));
  }
  return isSame;
};

const SettingsUserTime = ({ me }) => {
  const [timeZone, setTimeZone] = useState(me.TimeZone);
  const [notificationTime, setNotificationTime] = useState(
    dateForTime(me.Settings.PromptNotificationTime)
  );
  const [showSave, setShowSave] = useState(false);
  const [saving, setSaving] = useState(false);
  const { displayError } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    setShowSave(
      !isEqual(
        notificationTime,
        dateForTime(me.Settings.PromptNotificationTime)
      ) || timeZone !== me.TimeZone
    );
  }, [me, timeZone, notificationTime]);

  useEffect(() => {
    if (me && !showSave) {
      setNotificationTime(dateForTime(me.Settings.PromptNotificationTime));
      setTimeZone(me.TimeZone);
    }
  }, [me, showSave]);

  const onCancel = useCallback(() => {
    setNotificationTime(dateForTime(me.Settings.PromptNotificationTime));
    setTimeZone(me.TimeZone);
    setShowSave(false);
  }, [me]);

  const onSave = useCallback(async () => {
    setSaving(true);
    const data = {
      TimeZone: timeZone,
      Settings: {
        ...me.Settings,
        PromptNotificationTime: timeFromDate(notificationTime),
      },
    };
    try {
      await updateMe(data);
    } catch (e) {
      //console.log(util.inspect(e, { depth: 6 }));
      displayError(e.response.data.Error.Message);
    }
    queryClient.invalidateQueries("queryMe");
    setSaving(false);
  }, [timeZone, notificationTime]);

  const onTimePickerChange = (value, setter) => {
    // value is dayjs datetime relative to NOW. May be invalid.
    let date = value;
    if (date?.isValid()) {
      // But we don't want NOW's day on the time, we need our constant arbitrary day...
      date = dateForHM(date.hour(), date.minute());
    }
    setter(date);
  };

  const onTimeZoneSelect = (event) => {
    setTimeZone(event.target.value);
  };

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-row space-x-2">
          <div className="w-1/2">
            <TimePicker
              minutesStep={5}
              inputFormat="h:mm A"
              label={"Time"}
              value={notificationTime}
              onChange={(value) => {
                onTimePickerChange(value, setNotificationTime);
              }}
              renderInput={(params) => {
                return <TextField {...params}></TextField>;
              }}
            />
          </div>
          <div className="pb-2 w-1/2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-label">Time zone</InputLabel>
              <SelectTimeZone
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeZone}
                onChange={onTimeZoneSelect}
                label={"Time Zone"}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <SaveControls
        show={showSave}
        onSave={onSave}
        onCancel={onCancel}
        saving={saving}
        saveEnabled={true}
      />
    </div>
  );
};

export default SettingsUserTime;
