import { PencilIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from "react-query";

import { refreshPrompt, setPromptForDate } from "../../api";
import { queryGroupManage } from "../../queries";
import { utcDateStringsForTimestamp } from "../../utils/promptUtils";

const MAX_PROMPT_CHARS = 100;

const FuturePrompts = ({ groupId }) => {
  const queryClient = useQueryClient();
  const [
    refreshingPromptByTimestamp,
    setRefreshingPromptByTimestamp,
  ] = useState({}); // So we don't disable every refresh button
  const [editingPromptTimestamp, setEditingPromptTimestamp] = useState();
  const [savingPromptTimestamp, setSavingPromptTimestamp] = useState();
  const [savingPromptText, setSavingPromptText] = useState("");
  const [futurePromptWrappers, setFuturePromptWrappers] = useState([]);
  const editingTextAreaRef = useRef();

  const { error: queryError, data: groupManage } = useQuery(
    ["queryGroupManage", { groupId }],
    queryGroupManage
  );

  useEffect(() => {
    if (groupManage?.Schedule) {
      const sortedScheduleKeys = Object.keys(groupManage.Schedule).sort();
      const futurePromptWrappers = [];
      sortedScheduleKeys.forEach((scheduleKey) => {
        const scheduleItem = groupManage.Schedule[scheduleKey];
        if (
          // We ignore "DisabledForThisDayOfWeek"
          scheduleItem.Status == "Scheduled" ||
          scheduleItem.status == "AwaitingAssignment"
          // TODO also support "DisabledForThisDay" eventually, allowing peolple to turn on/off a day
        ) {
          scheduleItem.Timestamp = scheduleKey;
          futurePromptWrappers.push(scheduleItem);
        }
      });
      setFuturePromptWrappers(futurePromptWrappers);
      // So now "futurePrompWrappers" is ordered array [ { Key, Status, Prompt }, {...}, ... ]
      // Which excludes all "DisabledForThisDayOfWeek" for easier using
    }
  }, [groupManage]);

  const handleRefreshPrompt = useCallback(
    async (timestamp) => {
      refreshingPromptByTimestamp[timestamp] = true;
      setRefreshingPromptByTimestamp({ ...refreshingPromptByTimestamp });
      try {
        await refreshPrompt(groupId, timestamp);
      } catch (e) {
        // TODO handle error
        console.log(e);
      }
      queryClient.invalidateQueries(["queryGroupManage", { groupId }]);
      setRefreshingPromptByTimestamp({});
    },
    [refreshingPromptByTimestamp]
  );

  const editPrompt = useCallback(
    (timestamp) => {
      setSavingPromptTimestamp(); // TODO shouldn't need this one saving actually completes
      setEditingPromptTimestamp(timestamp);
    },
    [editingPromptTimestamp]
  );

  const savePromptEdit = useCallback(
    async (timestamp) => {
      const text = editingTextAreaRef.current.value;
      setEditingPromptTimestamp();
      setSavingPromptText(text);
      setSavingPromptTimestamp(timestamp);
      // TODO CALL API. Faking it...
      try {
        await setPromptForDate(groupId, timestamp, text);
      } catch (e) {
        // TODO handle error
        console.log(e);
      }
      setSavingPromptText("");
      setSavingPromptTimestamp();
      queryClient.invalidateQueries(["queryGroupManage", { groupId }]);
    },
    [queryClient]
  );

  const cancelPromptEdit = (timestamp) => {
    setEditingPromptTimestamp();
  };

  const onTextAreaChange = (event) => {
    let text = event.target.value;
    // Remove spaces at start | newlines | emojis
    text = text.replace(
      /(^\s+|\r?\n|\r|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2020-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    //text = text.trimStart(); // Remove starting spaces. I.E. Not supported BTW
    if (text.length > MAX_PROMPT_CHARS) {
      text = text.substring(0, MAX_PROMPT_CHARS);
    }
    event.target.value = text;
  };

  return (
    <div>
      {futurePromptWrappers.map((wrapper, i) => {
        const isFirst = i === 0;
        const isLast = i === futurePromptWrappers.length - 1;
        const classes = classNames({
          "flex flex-col w-full border border-0 bg-white border px-3 py-3 h-28 select-none": true,
          "border-t-0": !isFirst,
          "rounded-t": isFirst,
          "rounded-b": isLast,
        });
        const {
          Timestamp: timestamp,
          Status: status,
          Prompt: prompt,
        } = wrapper;
        const unassigned = status === "AwaitingAssignment"; //Server hasn't generated a prompt yet
        const { weekday, longDate } = utcDateStringsForTimestamp(timestamp);
        const refreshing = refreshingPromptByTimestamp[`${timestamp}`] || false;
        const editing = timestamp === editingPromptTimestamp;
        const saving = timestamp === savingPromptTimestamp;
        const promptTextContainerClasses = classNames({
          "flex-1 flex flex-col border-top rounded justify-center relative group": true,
          "bg-lightGray-6 overflow-hidden": !editing, //overflow-hidden helps with too-long prompt string
          "cursor-pointer": !editing && !saving,
        });
        const pencilIconClasses = classNames("m-1 w-5 h-5", {
          "text-transparent group-hover:text-blue": !isMobile,
          "text-blue": isMobile,
        });
        return (
          <div key={`${timestamp}-${editing}`} className={classes}>
            <div className="grid grid-cols-3 w-full">
              {editing && !saving ? (
                <div
                  onClick={() => cancelPromptEdit(prompt)}
                  className={`text-blue text-xs cursor-pointer`}
                >
                  Cancel
                </div>
              ) : (
                <div className="text-xs text-muiGray-2 pb-2 font-bold">
                  {weekday}
                </div>
              )}
              <div className="text-xs text-muiGray-3 pb-2 text-center">
                {longDate}
              </div>
              <div className="flex justify-end">
                {editing || saving ? (
                  <div
                    onClick={() => savePromptEdit(timestamp)}
                    className={`text-blue text-xs cursor-pointer ${
                      saving ? "opacity-50" : ""
                    }`}
                  >
                    {saving ? "Saving..." : "Save"}
                  </div>
                ) : (
                  <div
                    onClick={() => handleRefreshPrompt(timestamp)}
                    className={`text-blue text-xs cursor-pointer ${
                      refreshing ? "opacity-50" : ""
                    }`}
                  >
                    Refresh
                  </div>
                )}
              </div>
            </div>
            <div
              onClick={() => !saving && editPrompt(timestamp)}
              className={promptTextContainerClasses}
            >
              {editing && !saving ? (
                <div className="flex-1 text-muiGray-1 text-sm font-bold text-center border box-border border-4 border-blue">
                  <textarea
                    autoFocus={true}
                    ref={editingTextAreaRef}
                    className="w-full resize-none text-center h-14 px-2 py-2 font-bold bg-transparent"
                    type="text"
                    onChange={onTextAreaChange}
                  />
                </div>
              ) : (
                <div
                  className={`text-muiGray-1 text-sm font-bold text-center px-2 py-2 ${
                    saving ? "opacity-20" : ""
                  }`}
                >
                  {saving
                    ? savingPromptText
                    : unassigned
                    ? "(coming soon)"
                    : prompt.PromptText}
                </div>
              )}
              {!editing && !saving && (
                <div className="absolute bottom-0 right-0">
                  <PencilIcon className={pencilIconClasses} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default FuturePrompts;
