import { MicrophoneIcon, VideoCameraIcon } from "@heroicons/react/outline";
import { useCallback, useContext, useEffect, useState } from "react";

import { MediaContext } from "../contexts/mediaContext";

const MediaChooser = ({ onClose }) => {
  const {
    mediaDevices,
    audioDevice,
    setAudioDevice,
    videoDevice,
    setVideoDevice,
  } = useContext(MediaContext);

  const [audioInputs, setAudioInputs] = useState([]);
  const [videoInputs, setVideoInputs] = useState([]);

  const handleAudioInputChange = useCallback(
    (e) => {
      setAudioDevice(
        mediaDevices.find((device) => {
          return e.target.value === device.deviceId;
        })
      );
    },
    [mediaDevices, setAudioDevice]
  );

  const handleVideoInputChange = useCallback(
    (e) => {
      setVideoDevice(
        mediaDevices.find((device) => {
          return e.target.value === device.deviceId;
        })
      );
    },
    [mediaDevices, setVideoDevice]
  );

  useEffect(() => {
    let audioInputDevices = [];
    let videoInputDevices = [];
    for (const device of mediaDevices) {
      const { kind } = device;
      if (kind === "audioinput") {
        audioInputDevices.push(device);
      } else if (kind === "videoinput") {
        videoInputDevices.push(device);
      }
    }
    setAudioInputs(audioInputDevices);
    setVideoInputs(videoInputDevices);
  }, [mediaDevices]);

  useEffect(() => {}, [audioInputs]);
  useEffect(() => {}, [videoInputs]);

  return (
    <div className="z-50 py-8 w-72 h-64 bg-white rounded-2xl flex flex-col items-center justify-center shadow-2xl bg-opacity-70 backdrop-filter bg-blur-xl select-none">
      <div className="px-6 flex flex-col w-full">
        <div className="flex items-center pb-0.5">
          <VideoCameraIcon className="h-5 w-5 mr-1.5" />
          <div className="text-md font-medium">Camera</div>
        </div>
        <div className="pt-0.5">
          <select
            className="py-2 px-3 text-xs bg-whiteOpacity-80 border border-blackOpacity-70 rounded-lg w-full"
            onChange={handleVideoInputChange}
            value={videoDevice?.deviceId || ""}
          >
            {videoInputs.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center pt-4 pb-0.5">
          <MicrophoneIcon className="h-5 w-5 mr-1.5" />
          <div className="text-md font-medium">Microphone</div>
        </div>
        <div className="pt-0.5">
          <select
            className="py-2 px-3 text-xs bg-whiteOpacity-80 border border-blackOpacity-70 rounded-lg w-full"
            onChange={handleAudioInputChange}
            value={audioDevice?.deviceId || ""}
          >
            {audioInputs.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="text-center mt-7 select-none">
        <button
          className="bg-black py-2 rounded-full w-36 text-white font-bold text-sm"
          onClick={onClose}
        >
          DONE
        </button>
      </div>
    </div>
  );
};

export default MediaChooser;
