import {
  BellIcon,
  CogIcon,
  FilmIcon,
  LightningBoltIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import {
  BellIcon as SolidBellIcon,
  CogIcon as SolidCogIcon,
  FilmIcon as SolidFilmIcon,
  LightningBoltIcon as SolidLightningBoltIcon,
  UserGroupIcon as SolidUserGroupIcon,
  VideoCameraIcon as SolidVideoCameraIcon,
} from "@heroicons/react/solid";
import { useCallback, useContext } from "react";

import { BadgeCountContext } from "../../contexts/badgeCountContext";
import { BottomNavEnum, NavContext } from "../../contexts/navContext";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";

const BottomNavBar = () => {
  const {
    videosBadgeCount,
    promptsBadgeCount,
    notificationsBadgeCount,
  } = useContext(BadgeCountContext);
  const { bottomNavSelection, navigateTo } = useContext(NavContext);

  const heightClass = "h-16";

  const BottomNavButton = useCallback(
    ({ enumValue }) => {
      const isSelected = bottomNavSelection === enumValue;
      const iconClasses = "w-6 h-6";
      const colorClass = isSelected ? "text-black" : "text-gray-400";
      let icon, title;
      let badgeCount = 0;
      let badgeColor;
      switch (enumValue) {
        case BottomNavEnum.TEAMS:
          title = "Teams";
          icon = isSelected ? (
            <UserGroupIcon className={iconClasses} />
          ) : (
            <UserGroupIcon className={iconClasses} />
          );
          break;
        case BottomNavEnum.VIDEOS:
          title = "Videos";
          badgeColor = "blue";
          badgeCount = videosBadgeCount;
          icon = isSelected ? (
            <FilmIcon className={iconClasses} />
          ) : (
            <FilmIcon className={iconClasses} />
          );
          break;
        case BottomNavEnum.PROMPTS:
          title = "Prompts";
          badgeColor = "red";
          badgeCount = promptsBadgeCount;
          icon = isSelected ? (
            <VideoCameraIcon className={iconClasses} />
          ) : (
            <VideoCameraIcon className={iconClasses} />
          );
          break;
        case BottomNavEnum.NOTIFICATIONS:
          title = "Activity";
          badgeColor = "blue";
          badgeCount = notificationsBadgeCount;
          icon = isSelected ? (
            <BellIcon className={iconClasses} />
          ) : (
            <BellIcon className={iconClasses} />
          );
          break;
        case BottomNavEnum.SETTINGS:
          title = "Settings";
          icon = isSelected ? (
            <CogIcon className={iconClasses} />
          ) : (
            <CogIcon className={iconClasses} />
          );
          break;
      }

      return (
        <div
          className={`flex flex-col items-center sm:w-24 justify-center cursor-pointer ${colorClass}`}
          onClick={() => navigateTo(enumValue)}
        >
          <div className="relative">
            {icon}
            {!!badgeCount && (
              <div
                className={`absolute bg-${badgeColor} rounded-full h-4 w-4 shadow-md -right-2.5 -top-1 flex items-center justify-center text-white text-2xs font-bold`}
              >
                {badgeCount}
              </div>
            )}
          </div>
          <div className="text-2xs font-medium">{title}</div>
        </div>
      );
    },
    [
      bottomNavSelection,
      videosBadgeCount,
      promptsBadgeCount,
      notificationsBadgeCount,
    ]
  );

  return (
    <div
      className={`w-full flex flex-col items-center justify-center bg-white border-t border-gray-200`}
    >
      <div className={"w-full sm:w-4/5 md:w-2/3 lg:w-1/2"}>
        <div
          className={`grid grid-cols-5 items-center px-3 select-none ${heightClass}`}
        >
          <BottomNavButton
            enumValue={BottomNavEnum.TEAMS}
            notificationCount={0}
          />
          <BottomNavButton
            enumValue={BottomNavEnum.VIDEOS}
            notificationCount={0}
          />
          <BottomNavButton
            enumValue={BottomNavEnum.PROMPTS}
            notificationCount={0}
          />
          <BottomNavButton
            enumValue={BottomNavEnum.NOTIFICATIONS}
            notificationCount={0}
          />
          <BottomNavButton
            enumValue={BottomNavEnum.SETTINGS}
            notificationCount={0}
          />
        </div>
      </div>
      <SafeAreaInsetBottom bgColor="red" />
    </div>
  );
};

export default BottomNavBar;
