import classNames from "classnames";
import { xor } from "lodash";

import { getGroupActiveDayBooleans } from "../../utils/groupUtils";

const ActiveDaysPicker = ({ activeDays, setActiveDays }) => {
  let { sun, mon, tue, wed, thu, fri, sat } = getGroupActiveDayBooleans(
    activeDays
  );

  const daySortValue = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const DayToggle = ({
    name,
    selected,
    onClick,
    isFirst = false,
    isLast = false,
  }) => {
    const classes = classNames({
      "grid col-span-7 justify-center w-full py-2 border cursor-pointer select-none": true,
      "border-l-0": !isFirst,
      "rounded-l": isFirst,
      "rounded-r": isLast,
      "bg-blue text-white": selected,
      "text-muiGray-2": !selected,
    });
    return (
      <div className={classes} onClick={onClick}>
        <div className="text-center w-8">{name}</div>
      </div>
    );
  };

  const handleClick = (enumKey) => {
    const newDays = xor(activeDays, [enumKey]);
    // Sort days. Not sure if server likes out of order, but
    // at any rate, the sorting is helpful when comparing changes
    newDays.sort((a, b) => {
      const aSortValue = daySortValue[a];
      const bSortValue = daySortValue[b];
      if (aSortValue < bSortValue) return -1;
      if (aSortValue > bSortValue) return 1;
      return 0;
    });
    setActiveDays(newDays);
  };

  return (
    <div className="flex flex-col">
      <div className="pl-4 -ml-0.5 pb-2 text-xs text-muiGray-2">
        Videos will be compiled on the selected days
      </div>
      <div className="flex">
        <DayToggle
          name="Sun"
          selected={sun}
          onClick={() => handleClick("Sunday")}
          isFirst={true}
        />
        <DayToggle
          name="Mon"
          selected={mon}
          onClick={() => handleClick("Monday")}
        />
        <DayToggle
          name="Tue"
          selected={tue}
          onClick={() => handleClick("Tuesday")}
        />
        <DayToggle
          name="Wed"
          selected={wed}
          onClick={() => handleClick("Wednesday")}
        />
        <DayToggle
          name="Thu"
          selected={thu}
          onClick={() => handleClick("Thursday")}
        />
        <DayToggle
          name="Fri"
          selected={fri}
          onClick={() => handleClick("Friday")}
        />
        <DayToggle
          name="Sat"
          selected={sat}
          onClick={() => handleClick("Saturday")}
          isLast={true}
        />
      </div>
    </div>
  );
};

export default ActiveDaysPicker;
