import {
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import EmailValidator from "email-validator";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { updateMe } from "../api";
import { MAX_CHARS_FIRST_NAME, MAX_CHARS_LAST_NAME } from "../constants";
import { AppContext } from "../contexts/appContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import { VersionCheckContext } from "../contexts/versionCheckContext";
import { queryMe } from "../queries";
import ButtonPill from "./Common/ButtonPill";
import IOSSwitch from "./Common/IOSSwitch";
import NavPage from "./Common/NavPage";
import PageColumn from "./Common/PageColumn";
import PageColumnContainer from "./Common/PageColumnContainer";
import SaveControls from "./Common/SaveControls";
import Section from "./Common/Section";
import SectionInstructions from "./Common/SectionInstructions";
import ProfilePhotoWidget from "./ProfilePhotoWidget";
import SettingsUserTime from "./SettingsUserTime";

// Keys for user object json...
const SETTINGS_KEY = "Settings";
// const MORNING_PROMPT_KEY = "MorningPrompt";
// const AFTERNOON_REMINDER_KEY = "AfternoonReminder";
// const VIDEO_READY_KEY = "VideoReady";
const EMAIL_NOTIFICATIONS_KEY = "EmailNotifications";

const Settings = () => {
  const { currentVersion } = useContext(VersionCheckContext);
  const { data: me } = useQuery("queryMe", queryMe);
  const queryClient = useQueryClient(); // for causing "queryMe" to refresh
  const {
    hasParentApp,
    parentAppHasNotificationsOn,
    openParentAppNotificationsScreen,
  } = useContext(ParentAppContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [, setLocation] = useLocation();
  const { displayModal } = useContext(AppContext);

  // const [morningPromptNotification, setMorningPromptNotification] = useState(
  //   false
  // );
  // const [morningPromptBusy, setMorningPromptBusy] = useState(true);
  // const [
  //   afternoonReminderNotification,
  //   setAfternoonReminderNotification,
  // ] = useState(false);
  // const [afternoonReminderBusy, setAfternoonReminderBusy] = useState(true);
  // const [videoReadyNotification, setVideoReadyNotification] = useState(false);
  // const [videoReadyBusy, setVideoReadyBusy] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [emailNotificationsBusy, setEmailNotificationsBusy] = useState(true);

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveValid, setSaveValid] = useState(false);

  useEffect(() => {
    if (me && me !== null) {
      setFirstName(me.FirstName);
      setLastName(me.LastName);
      setEmail(me.EMail);
      // Notification Prefs...
      const settings = me[SETTINGS_KEY];
      // setMorningPromptNotification(
      //   settings[MORNING_PROMPT_KEY] !== undefined
      //     ? settings[MORNING_PROMPT_KEY]
      //     : true
      // );
      // setMorningPromptBusy(false);
      // setAfternoonReminderNotification(
      //   settings[AFTERNOON_REMINDER_KEY] !== undefined
      //     ? settings[AFTERNOON_REMINDER_KEY]
      //     : true
      // );
      // setAfternoonReminderBusy(false);
      // setVideoReadyNotification(
      //   settings[VIDEO_READY_KEY] !== undefined
      //     ? settings[VIDEO_READY_KEY]
      //     : true
      // );
      // setVideoReadyBusy(false);
      setEmailNotifications(
        settings[EMAIL_NOTIFICATIONS_KEY] !== undefined
          ? settings[EMAIL_NOTIFICATIONS_KEY]
          : true
      );
      setEmailNotificationsBusy(false);
      // The following is to clear save button after a refreshMe...
      setShowSaveButton(false);
      setSaving(false);
    }
  }, [me]);

  useEffect(() => {
    if (me && me !== null) {
      setShowSaveButton(
        firstName.trim() !== me.FirstName ||
          lastName.trim() !== me.LastName ||
          email.trim() !== me.EMail
      );
      setSaveValid(
        firstName.trim() !== "" &&
          lastName.trim() !== "" &&
          EmailValidator.validate(email.trim())
      );
    } else {
      // not expecting 'me' to change from existing to null, but just in case...
      setShowSaveButton(false);
    }
  }, [firstName, lastName, email, me]);

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailNotifications = async (event) => {
    setEmailNotificationsBusy(true);
    const obj = {};
    obj[SETTINGS_KEY] = {};
    obj[SETTINGS_KEY][EMAIL_NOTIFICATIONS_KEY] = event.target.checked;
    await updateMe(obj);
    queryClient.invalidateQueries("queryMe");
  };

  const handleMobileNotifications = async (event) => {
    openParentAppNotificationsScreen();
  };

  // const handleMorningPrompt = async (event) => {
  //   setMorningPromptBusy(true);
  //   const obj = {};
  //   obj[SETTINGS_KEY] = {};
  //   obj[SETTINGS_KEY][MORNING_PROMPT_KEY] = event.target.checked;
  //   await updateMe(obj);
  //   queryClient.invalidateQueries("queryMe");
  // };

  // const handleAfternoonReminder = async (event) => {
  //   setAfternoonReminderBusy(true);
  //   const obj = {};
  //   obj[SETTINGS_KEY] = {};
  //   obj[SETTINGS_KEY][AFTERNOON_REMINDER_KEY] = event.target.checked;
  //   await updateMe(obj);
  //   queryClient.invalidateQueries("queryMe");
  // };

  // const handleVideoReadyNotification = async (event) => {
  //   setVideoReadyBusy(true);
  //   const obj = {};
  //   obj[SETTINGS_KEY] = {};
  //   obj[SETTINGS_KEY][VIDEO_READY_KEY] = event.target.checked;
  //   await updateMe(obj);
  //   queryClient.invalidateQueries("queryMe");
  // };

  const handleSave = async (event) => {
    setSaving(true);
    await updateMe({
      FirstName: firstName.trim(),
      LastName: lastName.trim(),
      EMail: email.trim(),
    });
    queryClient.invalidateQueries("queryMe");
    // useEffect for [me] will reset the save button
  };

  const handleCancelSave = async (event) => {
    setFirstName(me.FirstName);
    setLastName(me.LastName);
    setEmail(me.EMail);
  };

  const tryLogout = () => {
    displayModal(
      "Logout?",
      <div className="w-72"></div>,
      () => setLocation("/logout"),
      "Yes, Logout"
    );
  };

  return (
    <NavPage backgroundColor="lightGray-6" title="Settings">
      {me && (
        <div>
          <PageColumnContainer>
            <PageColumn>
              <Section label="Personal Info">
                <div>
                  <div className="flex items-center mb-8 space-x-4">
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="First name"
                        value={firstName}
                        onChange={handleChangeFirstName}
                        inputProps={{ maxLength: MAX_CHARS_FIRST_NAME }}
                      />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Last name"
                        value={lastName}
                        onChange={handleChangeLastName}
                        inputProps={{ maxLength: MAX_CHARS_LAST_NAME }}
                      />
                    </FormControl>
                  </div>
                  <div className="flex items-center mb-2">
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Email"
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="mb-4">
                  <SaveControls
                    show={showSaveButton}
                    onSave={handleSave}
                    onCancel={handleCancelSave}
                    saving={saving}
                    saveEnabled={saveValid}
                  />
                </div>
              </Section>

              <Section label="Profile Picture">
                <ProfilePhotoWidget />
              </Section>
            </PageColumn>

            <PageColumn>
              <Section label="Notifications">
                <div className="flex flex-col select-none w-full">
                  <div className="text-sm pb-2">
                    Notifications are sent when:
                    <ul className="pl-2 pt-1">
                      <li>• New prompts open</li>
                      <li>• An unanswered prompt is closing soon</li>
                      <li>• A finished video arrives</li>
                    </ul>
                  </div>
                  <FormGroup>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            disabled={emailNotificationsBusy}
                            checked={emailNotifications}
                            onChange={handleEmailNotifications}
                          />
                        }
                        label="Email Notifications"
                      />
                    </FormControl>
                    {hasParentApp && (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={parentAppHasNotificationsOn}
                              onChange={handleMobileNotifications}
                            />
                          }
                          label="Mobile Notifications"
                        />
                      </FormControl>
                    )}
                  </FormGroup>
                </div>
              </Section>
              <Section label="Prompt Notifications Time">
                <SectionInstructions>
                  Notifications for prompts are sent in the morning. You can
                  customize your morning time.
                </SectionInstructions>
                <SettingsUserTime me={me} />
              </Section>
              <Section>
                <div className="flex flex-row justify-end mt-4 sm:mt-6">
                  <ButtonPill
                    fullWidth={false}
                    small={true}
                    onClick={tryLogout}
                  >
                    Log out
                  </ButtonPill>
                </div>
              </Section>
            </PageColumn>
          </PageColumnContainer>
          <div className="mt-4 mb-5 flex justify-end text-black font-med text-xs opacity-40">
            12Seconds VERSION {currentVersion}
          </div>
        </div>
      )}
    </NavPage>
  );
};

export default Settings;
