import tv from "../images/12seconds-tv.svg";
import AuthFooter from "./Common/AuthFooter";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";
import FixedLink from "./Common/FixedLink";
import YellowScreen from "./Common/YellowScreen";

const LoggedOutHome = () => {
  return (
    <YellowScreen>
      <div className="flex-1 flex flex-col items-center justify-between">
        <div className="flex-1 flex flex-col space-y-4 px-8 justify-center">
          <FixedImg className="mb-4" src={tv} alt="12seconds" />
          <FixedLink href="/login">
            <ButtonPill fullWidth={true} large={true}>
              Log In
            </ButtonPill>
          </FixedLink>
          <FixedLink href="/signup">
            <ButtonPill fullWidth={true} large={true}>
              Sign Up With Code
            </ButtonPill>
          </FixedLink>
          {/*
          <FixedLink href="/request">
            <ButtonPill fullWidth={true} large={true}>
              Request Invite Code
            </ButtonPill>
          </FixedLink>
          */}
        </div>
        <AuthFooter />
      </div>
    </YellowScreen>
  );
};

export default LoggedOutHome;
