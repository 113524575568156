import { ChatIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import Slider from "@material-ui/core/Slider";
import { debounce } from "lodash";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isAndroid, isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { useQuery } from "react-query";
import { useSwipeable } from "react-swipeable";
import useKeypress from "react-use-keypress";
import { elementScrollIntoView } from "seamless-scroll-polyfill"; // For mobile safari smooth scroll. https://github.com/magic-akari/seamless-scroll-polyfill

import { WindowSizeContext } from "../../contexts/windowSizeContext";
import transparent1x1 from "../../images/transparent1x1.png";
import { queryGroup, queryUsers } from "../../queries";
import { remToPx, tailwindBreakpointForWidth } from "../../utils";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";
import Comments from "./Comments";
import RosterControls from "./RosterControls";

const InlinePlayerPortrait = ({ prompt, onClose, openComments }) => {
  const { windowSize } = useContext(WindowSizeContext);
  const [activeUserId, setActiveUserId] = useState();
  const [playing, _setPlaying] = useState(!openComments); // Don't autoplay if launching with comments.
  const [sliding, setSliding] = useState(false);
  const [flashPlay, setFlashPlay] = useState(false);
  const [flashPause, setFlashPause] = useState(false);
  const [showComments, _setShowComments] = useState(openComments);
  const [commentInputHasFocus, setCommentInputHasFocus] = useState(false);
  const [memberRefsByUserId, setMemberRefsByUserId] = useState({});
  const [indexLastScrolled, setIndexLastScrolled] = useState(-1); // Only try auto-scroll once per member change
  const [progressPercent, setProgressPercent] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeMemberName, setActiveMemberName] = useState();
  const playerRef = useRef(null);
  const backgroundRef = useRef();
  const seekBarRef = useRef();

  const setShowComments = (show) => {
    if (!show) {
      // If comments were dismissed while had focus, this fixes that
      setCommentInputHasFocus(false);
    }
    _setShowComments(show);
  };

  const setPlaying = useCallback(
    (isPlaying) => {
      // Causes the splash animations and sets state.
      setFlashPlay(!!isPlaying);
      setFlashPause(!isPlaying);
      _setPlaying(!!isPlaying);
    },
    [_setPlaying, setFlashPlay, setFlashPause]
  );

  const togglePlaying = useMemo(() => {
    // Need to debounce this or rapid space bar or clicking seems to get you in f'd up play/pause states.
    // Seems maybe React-player doesn't have a foolproof way to sync up the "playing" state.
    return debounce(() => setPlaying(!playing), 200);
  }, [playing, setPlaying]);

  const onVideoClick = useCallback(() => {
    //if (!isMobile) {
    // Disabling video click on Mobile for now. iOS webkit seems to be passing the clicks
    // on video controls through to the start/stop control div that we have listening
    // underneath the player. :/
    togglePlaying();
    //}
  }, [togglePlaying]);

  // eslint-disable-next-line no-unused-vars
  const onError = useCallback(
    (e) => {
      // Expected when we try to autostart and can't. We must update our playing state.
      // Could check for e.name === "NotAllowedError"
      // but it's DOM error, and don't know if same on all browsers.
      _setPlaying(false); // Calling direct, no splash animation.
    },
    [_setPlaying]
  );

  const onPlay = useCallback(() => {
    //_setPlaying(true);
  }, [setPlaying]);

  const onPause = useCallback(() => {
    //_setPlaying(false);
  }, [setPlaying]);

  const onEnded = useCallback(() => {
    //_setPlaying(false);
  }, [setPlaying]);

  useKeypress(
    "Escape",
    useCallback(() => {
      if (showComments) {
        setShowComments(false);
      } else {
        onClose();
      }
    }, [showComments])
  );

  useKeypress(
    " ",
    useCallback(
      (event) => {
        //' ' is space bar
        // when we are potentially typing a comment, we don't want to
        // hijack the space bar
        if (!showComments) {
          togglePlaying();
          event.preventDefault();
        }
        // preventing default to stop background component scrolling! Who is doing that?
      },
      [togglePlaying, showComments]
    )
  );

  const groupId = prompt?.GroupId;
  const { data: group } = useQuery(["queryGroup", { groupId }], queryGroup, {
    enabled: !!groupId,
  });

  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });

  useEffect(() => {
    let name;
    if (activeUserId && members?.length > 0) {
      const member = members.find((member) => member.Id === activeUserId);
      name = `${member.FirstName} ${member.LastName}`;
    }
    setActiveMemberName(name);
  }, [activeUserId, members]);

  useEffect(() => {
    if (prompt && prompt.Timeline) {
      const refs = {};
      prompt.Timeline.UserEntries.forEach((userEntry) => {
        refs[userEntry.UserId] = createRef();
      });
      setMemberRefsByUserId(refs);
    }
  }, [prompt]);

  const onProgress = useCallback(
    (progress) => {
      // Called every second (by default) from ReactPlayer
      const { playedSeconds, played } = progress;
      const totalTime = prompt?.Timeline?.TotalVideoLength;
      setProgressPercent((playedSeconds * 100) / totalTime);
      let activeId;
      const userEntries = prompt?.Timeline?.UserEntries || [];
      for (let i = 0; i < userEntries.length; i += 1) {
        const userEntry = userEntries[i];
        // Nudging the playback times here seems to help
        const nudge = 0; // 0.5 0.7
        if (
          playedSeconds >= userEntry.StartTime - nudge &&
          playedSeconds < userEntry.StartTime + userEntry.VideoLength - nudge
        ) {
          // We found the activeId...
          activeId = userEntry.UserId;
          // Next try auto-scroll, only try once per member shift...
          if (indexLastScrolled !== i) {
            // Scroll the next two members into view. Or next one. Or just this one...
            const userEntryScrollTarget =
              userEntries[i + 2] || userEntries[i + 1] || userEntry;
            const scrollRef = memberRefsByUserId[userEntryScrollTarget.UserId];
            if (scrollRef?.current) {
              const scrollConfig = { behavior: "smooth", block: "start" };
              if (!showComments) {
                // This polyfill helps iOS, but causes draw issues when comments showing
                elementScrollIntoView(scrollRef?.current, scrollConfig);
              } else {
                scrollRef.current.scrollIntoView(scrollConfig);
              }
              setIndexLastScrolled(i);
            }
          }
          break;
        }
      }
      setActiveUserId(activeId);
    },
    [
      setActiveUserId,
      prompt?.Timeline?.UserEntries,
      memberRefsByUserId,
      indexLastScrolled,
      showComments,
    ]
  );

  const handleMemberClick = useCallback(
    (userId) => {
      setActiveUserId(userId); // Set immediately, instead of waiting for onProgress()
      // nudging the scrub time by .04 seconds
      // so the still image shows the correct person
      // (Note: playerRef.current can be null if video not found)
      if (prompt?.Timeline?.UserEntries) {
        const startTime = prompt.Timeline.UserEntries.find(
          (entry) => entry.UserId === userId
        ).StartTime;
        const adjustedTime = parseFloat(startTime) + 0.08;
        playerRef.current?.seekTo(adjustedTime, "seconds");
      }
      setPlaying(true);
    },
    [setPlaying, setActiveUserId, prompt?.Timeline?.UserEntries]
  );

  // This needs to useCallback so it does't get recreated. Recreate will break the css animation.
  const PlayIndicator = useCallback(() => {
    return (
      <div className="text-white flex w-full h-full items-center justify-center pointer-events-none animate-splash">
        <div className="w-28 h-28">
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="black" />
            <path
              d="M38.5 29.6667V71.6667L70 50.6667L38.5 29.6667Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    );
  }, []);

  // This needs to useCallback so it does't get recreated. Recreate will break the css animation.
  const PauseIndicator = useCallback(() => {
    return (
      <div className="text-white flex w-full h-full items-center justify-center pointer-events-none">
        <div className="w-28 h-28 animate-splash">
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="black" />
            <rect x="57" y="29" width="13" height="42" fill="white" />
            <rect x="57" y="29" width="13" height="42" fill="white" />
            <rect x="31" y="29" width="13" height="42" fill="white" />
            <rect x="31" y="29" width="13" height="42" fill="white" />
          </svg>
        </div>
      </div>
    );
  }, []);

  const showAdditionalControls = true;
  let membersHeight = 0;
  if (showAdditionalControls) {
    // As long we keep members controls height "h-16 sm:h-20", this code is works...
    const breakpoint = tailwindBreakpointForWidth(windowSize.width);
    if (breakpoint === "") {
      membersHeight = remToPx(4); //h-16
    } else {
      membersHeight = remToPx(5); //h-20
    }
  }
  const hPad = 0;

  const availableWidth = windowSize.width - hPad;
  const availableHeight = windowSize.height - membersHeight;
  const ASPECT_RATIO = 720 / 1280;
  const availableAreaIsWider = availableWidth / availableHeight > ASPECT_RATIO;
  const rotatingStyle = { width: "0px", height: "0px" };
  rotatingStyle.height =
    Math.round(
      availableAreaIsWider ? availableHeight : availableWidth / ASPECT_RATIO
    ) + "px";
  rotatingStyle.width =
    Math.round(
      availableAreaIsWider ? availableHeight * ASPECT_RATIO : availableWidth
    ) + "px";

  // BEGIN SWIPE HANDLING...
  const onSwipeClose = (e) => {
    if (e.target === backgroundRefPassthrough.current) {
      // TODO animate off screen instead of fade away
      onClose();
      e.event.stopPropagation();
    }
  };
  const onSwiped = { onSwipedDown: onSwipeClose };
  const swipeHandlers = useSwipeable(onSwiped);
  const backgroundRefPassthrough = (el) => {
    // combine useSwipeable's ref with our backgroundRef
    swipeHandlers.ref(el); // call useSwipeables ref prop with el
    backgroundRef.current = el; // set the el to a ref you can access yourself
  };

  const overlayOpacityClass = `${
    activeUserId ? "opacity-100" : "opacity-0"
  } transition transition-opacity`;

  const justifyClass = isMobile ? "justify-start" : "justify-center";

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      {...swipeHandlers} // Must be inserted BEFORE the ref declaration below. WTF
      ref={backgroundRefPassthrough}
      className={`flex-1 flex flex-col bg-black w-full h-full items-center outline-none focus:outline-none ${justifyClass}`}
      onClick={(e) => {
        e.preventDefault();
        if (e.target === backgroundRef.current) {
          // Click on background will close player...
          if (!isMobile) {
            onClose();
          }
        }
      }}
    >
      <div
        className={`flex-1 flex flex-col h-full px-2 items-center ${justifyClass}`}
      >
        {/* {errorMessage && (
          <ErrorDialog message={errorMessage} onClose={onClose} />
        )} */}

        {/* {showAdditionalControls && (
          <div className="absolute top-0 flex-col w-full text-white justify-start items-center">
            <SafeAreaInsetTop />
            <div
              className="text-black flex cursor-pointer pt-2"
              onClick={onClose}
            >
              <XIcon className="ml-2 w-6 h-6 text-white z-10" />
            </div>
          </div>
        )} */}

        {!!prompt && !!group && !!members && (
          <div
            className={`flex flex-col`}
            style={{ width: rotatingStyle.width }}
          >
            {/* whether we position the
            comment box relative to the player
            or the screen depends on whether we're
            in mobile or not */}
            {showComments && !isMobile && (
              <Comments
                promptId={prompt.Id}
                setShowComments={setShowComments}
                commentInputHasFocus={commentInputHasFocus}
                setCommentInputHasFocus={setCommentInputHasFocus}
              />
            )}

            {/* {!!prompt && ( // Mobile has different comments button location
              <div
                className="absolute right-0 bottom-44 z-10 text-white mt-6 py-2 pr-4 pl-4 flex-col items-center cursor-pointer select-none ml-auto"
                onClick={(e) => setShowComments(!showComments)}
              >
                <ChatAlt2Icon className="w-10 h-10 text-white " />
                <div className={`text-center text-lg`}>
                  {prompt.Comments.length}
                </div>
                <SafeAreaInsetBottom />
              </div>
            )} */}

            <div
              className={`relative aspect-h-16 aspect-w-9 flex-1`}
              style={rotatingStyle}
              onClick={onVideoClick}
            >
              {/* Click on body of player, not controls, passes thru to the onVideoClick above */}
              <ReactPlayer
                ref={playerRef}
                url={prompt.FinalVideoUrl}
                playing={playing && !sliding}
                playsinline={true}
                controls={false}
                loop={false}
                width="100%"
                height=""
                onError={onError}
                onPlay={onPlay}
                onPause={onPause}
                onEnded={onEnded}
                onProgress={onProgress}
                progressInterval={50}
                config={{ file: { attributes: { poster: transparent1x1 } } }} // Overrides Android's ugly default poster img.
              />
              {/* Android chrome seems to draw its own play/pause */}
              <div className="absolute top-0 w-full h-full z-50 pointer-events-none">
                {flashPause && !isAndroid && <PauseIndicator />}
                {flashPlay && !isAndroid && <PlayIndicator />}
              </div>

              <div className="absolute top-0 text-white bg-opacity-50 h-full w-full flex flex-col space-between select-none">
                {showAdditionalControls && (
                  <div className="absolute top-0 flex-col w-full">
                    <SafeAreaInsetTop />
                    <div
                      className="text-black flex justify-end cursor-pointer pt-2"
                      onClick={onClose}
                    >
                      <XIcon className="mx-2 w-8 h-8 text-white z-10 opacity-80 filter drop-shadow-sm" />
                    </div>
                  </div>
                )}
                <div
                  className="absolute -right-1.5 bottom-32 z-10 text-white mt-6 py-2 pr-4 pl-4 flex-col items-center cursor-pointer select-none ml-auto opacity-80"
                  onClick={(e) => setShowComments(!showComments)}
                >
                  <ChatIcon className="w-9 h-9 text-white filter drop-shadow-sm" />
                  <div className={`text-center text-lg filter drop-shadow-sm`}>
                    {prompt.Comments.length}
                  </div>
                  <SafeAreaInsetBottom />
                </div>
              </div>

              {/* OVERLAY The bottom gradient always draws to ensure smooth transition to/from titles.*/}
              {/*
              <div className="absolute top-0 text-white bg-opacity-50 h-full w-full flex flex-col space-between select-none">
                <SafeAreaInsetTop />
                <div
                  className={`flex-1 flex flex-col ${overlayOpacityClass}`}
                  style={{
                    textShadow: "0 0 7px #000",
                    // "0px 15px 5px rgba(0,0,0,0.1), 10px 20px 5px rgba(0,0,0,0.05), -10px 20px 5px rgba(0,0,0,0.05)",
                    //"0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135",
                  }}
                >
                  <div className="w-full text-center pt-3 text-sm font-bold">
                    {`${group.Emojicon} ${group.Name}`}
                  </div>
                  <div className="w-full text-center pt-2 font-bold">
                    {activeMemberName}
                  </div>
                </div>
                <div className="h-1/6 bg-gradient-to-t from-black ">
                  <div
                    className={`flex flex-col justify-center h-full p-3 ${overlayOpacityClass}`}
                  >
                    {prompt.PromptText}
                  </div>
                </div>
              </div>
              */}
            </div>

            <div className="relative flex h-16 sm:h-20">
              {/* h-16 sm:h-20 is important for "showAdditionalControls" */}
              <div
                className="absolute top-0 w-full -mt-4"
                ref={seekBarRef}
                // onClick={handleSeekBarClick}
              >
                <Slider
                  sx={{
                    color: "#FFFFFF",
                    // padding: "0px 0px !important",
                    // margin: "0px 0px !important",
                    "& .MuiSlider-root": {
                      // padding: "0px 0px !important",
                      // margin: "0px 0px !important",
                    },
                    "& .MuiSlider-thumb": {
                      opacity: "0%",
                    },
                  }}
                  min={0}
                  max={1000}
                  value={Math.round(progressPercent * 10)}
                  onChange={(e, value) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSliding(true);
                    const totalTime = prompt?.Timeline?.TotalVideoLength;
                    const adjustedTime = totalTime * (value / 1000);
                    playerRef.current?.seekTo(adjustedTime, "seconds");
                  }}
                  onChangeCommitted={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSliding(false);
                  }}
                />
                {/* <Line
                  strokeLinecap="square"
                  percent={`${progressPercent}`}
                  strokeWidth="1"
                  strokeColor="#FFF200"
                  trailColor="#00000000"
                /> */}
              </div>
              <RosterControls
                members={members}
                handleMemberClick={handleMemberClick}
                activeUserId={activeUserId}
                memberRefsByUserId={memberRefsByUserId}
                prompt={prompt}
              />
            </div>
          </div>
        )}

        {/* whether we position the
            comment box relative to the player
            or the screen depends on whether we're
            in mobile or not */}
        {showComments && isMobile && !!group && !!prompt && (
          <Comments
            promptId={prompt.Id}
            setShowComments={setShowComments}
            commentInputHasFocus={commentInputHasFocus}
            setCommentInputHasFocus={setCommentInputHasFocus}
            hidden={!showAdditionalControls}
          />
        )}
      </div>

      <SafeAreaInsetBottom />
    </div>
  );
};

export default InlinePlayerPortrait;
