// Mostly ripped from https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/modals/small

import React, { Fragment, useRef } from "react";
import ReactDOM from "react-dom";

const Modal = ({
  isShowing,
  hide,
  title,
  body,
  onAction,
  actionTitle = "Okay",
  allowCancel = true,
}) => {
  const backgroundRef = useRef();
  if (!isShowing) {
    return null;
  }

  // We're a bit messy in this code, supporting:
  // 1.) Standard OKAY dialog with no callback action
  // 2.) Action button with cancel
  // 3.) Action button with no cancel
  const hasAction = !!onAction;
  const isActionWithNoCancel = hasAction && !allowCancel;
  const hasTwoButtons = hasAction && !isActionWithNoCancel;

  return ReactDOM.createPortal(
    <Fragment>
      <div
        ref={backgroundRef}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-3"
        onClick={(e) => {
          if (e.target === backgroundRef.current) hide();
        }}
      >
        <div className="relative mx-auto">
          {/*content*/}
          <div className="px-6 py-6 sm:px-8 sm:py-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-lg">
            {/*header*/}
            <div className="flex items-start justify-between pb-4 pt-2">
              <h3 className="text-2xl sm:text-2xl font-semibold">{title}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={hide}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>

            {/*body*/}
            <div className="relative flex-auto text-gray-700">{body}</div>

            {/*footer*/}

            <div
              className={`flex ${
                hasTwoButtons ? "justify-between" : "justify-center"
              } items-center pt-6 sm:pt-8`}
            >
              {isActionWithNoCancel ? (
                <button
                  className={`text-red font-bold uppercase py-2 mb-1`}
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={() => {
                    hide();
                    onAction();
                  }}
                >
                  {actionTitle}
                </button>
              ) : (
                <Fragment>
                  <button
                    className={`text-${
                      hasAction ? "black" : "red"
                    } background-transparent font-bold uppercase py-2 mr-1 mb-1`}
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={hide}
                  >
                    {hasAction ? "Cancel" : "Okay"}
                  </button>

                  {hasAction && (
                    <button
                      className={`text-red font-bold uppercase py-2 mr-1 mb-1`}
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={() => {
                        hide();
                        onAction();
                      }}
                    >
                      {actionTitle}
                    </button>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-80 fixed inset-0 z-40 bg-black bg-blur-lg"></div>
    </Fragment>,
    document.body
  );
};

export default Modal;
