// This component allows taking or choosing a photo and uploading it.

import { CloudUploadIcon, PhotographIcon } from "@heroicons/react/outline";
import { CameraIcon, XIcon } from "@heroicons/react/solid";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AvatarEdit from "react-avatar-edit";
import Dropzone from "react-dropzone";
import { useQuery, useQueryClient } from "react-query";
import Webcam from "react-webcam";

import { getProfilePicUploadUrl, updateMe, uploadProfilePic } from "../api";
import { AppContext } from "../contexts/appContext";
import { MediaContext } from "../contexts/mediaContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import { WindowSizeContext } from "../contexts/windowSizeContext";
import defaultProfile from "../images/default-profile.png";
import { queryMe } from "../queries";
import FixedImg from "./Common/FixedImg";
import ButtonIconText from "./FancyButton";

const DROPZONE_HIGHLIGHT_CLASSES = "border-2";
const DROPZONE_NO_HIGHLIGHT_CLASSES = "border-2 border-dashed";

const CAMERA_WIDTH = 1280;
const CAMERA_HEIGHT = 720;
const AVATAR_SCALE_IN_WIDGET = 0.75; // How big is the current avatar in the drop zone.
const MIN_SCREEN_H_PAD = 10;

const ProfilePhotoWidget = ({ nextStep, borderColorClassName = "" }) => {
  const queryClient = useQueryClient();
  const { displayError, displayModal } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [croppedBase64Avatar, setCroppedBase64Avatar] = useState(null);
  const [highlightDrop, setHighlightDrop] = useState(false);
  const [dropzoneClasses, setDropzoneClasses] = useState(
    DROPZONE_NO_HIGHLIGHT_CLASSES
  );
  const [takingPhoto, setTakingPhoto] = useState(false);

  const [widgetSize, setWidgetSize] = useState({ width: 0, height: 0 });
  const [avatarSize, setAvatarSize] = useState(0);

  const { windowSize } = useContext(WindowSizeContext);

  const {
    requestMediaPermission,
    mediaPermissionDenied,
    hasMediaPermission,
  } = useContext(MediaContext);

  const { data: me } = useQuery("queryMe", queryMe);

  useEffect(() => {
    // Scale the widget px sizes according to screen size.
    let widgetScale, widgetWidth, widgetHeight;
    if (windowSize.width < 384) {
      // Widget needs to be smaller than default size
      widgetScale = windowSize.width / CAMERA_WIDTH;
      widgetWidth =
        Math.round(CAMERA_WIDTH * widgetScale) - MIN_SCREEN_H_PAD * 2;
      widgetHeight =
        Math.round(CAMERA_HEIGHT * widgetScale) - MIN_SCREEN_H_PAD * 2;
    } else {
      // Screen is big enough for default size
      widgetScale = 0.25;
      widgetWidth = Math.round(CAMERA_WIDTH * widgetScale);
      widgetHeight = Math.round(CAMERA_HEIGHT * widgetScale);
    }
    setWidgetSize({ width: widgetWidth, height: widgetHeight });
    const avatarSize = Math.round(
      Math.min(widgetWidth, widgetHeight) * AVATAR_SCALE_IN_WIDGET
    );
    setAvatarSize(avatarSize);
  }, [windowSize, setAvatarSize, setWidgetSize]);

  const onTakePhoto = () => {
    setTakingPhoto(true);
  };

  const highlightChoosing = (isHighlighted) => {
    setHighlightDrop(isHighlighted);
    setDropzoneClasses(
      isHighlighted ? DROPZONE_HIGHLIGHT_CLASSES : DROPZONE_NO_HIGHLIGHT_CLASSES
    );
  };

  const onDragEnter = () => {
    highlightChoosing(true);
  };

  const onDragLeave = () => {
    highlightChoosing(false);
  };

  const handleDrop = (files) => {
    const url = URL.createObjectURL(files[0]);
    updateAvatarEdit(url);
    highlightChoosing(false);
  };

  const onAvatarClose = () => {
    updateAvatarEdit(false);
  };

  const onAvatarCrop = (coppedBase64ImageData) => {
    setCroppedBase64Avatar(coppedBase64ImageData);
  };

  const updateAvatarEdit = (srcUrl) => {
    setAvatarSrc(srcUrl);
    setCroppedBase64Avatar(null);
  };

  const handleImageSubmit = async () => {
    setLoading(true);
    const uploadUrlResponse = await getProfilePicUploadUrl();
    const { url, photoKey } = uploadUrlResponse.data.Data;
    if (croppedBase64Avatar) {
      const buffer = Buffer.from(croppedBase64Avatar.split(",")[1], "base64");
      try {
        await uploadProfilePic(url, buffer);
        await updateMe({
          ProfilePhotoKey: photoKey,
        });
      } catch (e) {
        displayError(
          "There was an unexpected problem processing the photo. Please try again."
        );
      }
      queryClient.invalidateQueries("queryMe");
      updateAvatarEdit(false);
    }
    setLoading(false);
    if (nextStep) nextStep();
  };

  const renderInitialState = () => {
    return (
      <div className="flex flex-col items-center">
        <Dropzone
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={handleDrop}
          noKeyboard
          style={{
            width: widgetSize.width + "px",
            height: widgetSize.height + "px",
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: "flex flex-col items-center cursor-pointer",
              })}
            >
              <div
                style={{
                  width: widgetSize.width + "px",
                  height: widgetSize.height + "px",
                }}
                className={`flex items-center justify-center ${dropzoneClasses} ${borderColorClassName}`}
              >
                <FixedImg
                  style={{
                    width: avatarSize + "px",
                    height: avatarSize + "px",
                  }}
                  className="rounded-full"
                  src={
                    me && me.ProfilePhotoUrl
                      ? me.ProfilePhotoUrl
                      : defaultProfile
                  }
                  alt="Preview"
                />
              </div>
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        <div className="flex flex-row mt-8">
          <div className="mx-4">
            <ButtonIconText label="Take Photo" onClick={onTakePhoto}>
              <CameraIcon className="w-6 h-6 text-black" />
            </ButtonIconText>
          </div>
          <div className="mx-4">
            <Dropzone
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={handleDrop}
              noKeyboard
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <ButtonIconText label="Choose Pic" highlight={highlightDrop}>
                    <PhotographIcon className="w-6 h-6 text-black" />
                  </ButtonIconText>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    );
  };

  const renderAvatarEditState = () => {
    return (
      <div className="flex flex-col items-center">
        <AvatarEdit
          exportSize={1200}
          exportAsSquare={true}
          exportMimeType="image/jpeg"
          exportQuality={0.95}
          width={widgetSize.width}
          height={widgetSize.height}
          onCrop={onAvatarCrop}
          onClose={onAvatarClose}
          src={avatarSrc}
        />
        <div className="flex flex-row justify-center items-center mt-8">
          <FixedImg
            className="border rounded-full"
            width={50}
            height={50}
            src={croppedBase64Avatar}
            alt="Preview"
          />
          <div className="ml-4">
            <ButtonIconText
              thinking={loading}
              thinkingLabel="Uploading..."
              label="Upload Profile Picture"
              onClick={handleImageSubmit}
            >
              <CloudUploadIcon className="w-6 h-6 text-black" />
            </ButtonIconText>
          </div>
        </div>
      </div>
    );
  };

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setAvatarSrc(imageSrc);
    setTakingPhoto(false);
  }, [webcamRef]);

  useEffect(() => {
    if (mediaPermissionDenied) {
      setTakingPhoto(false);
      displayModal(
        "Permission Required",
        "Camera permission is required to take a photo.",
        () => {
          // HACK! By reloading the page, we allow the permission prompt to appear again. Else denied forever.
          window.location.reload();
        },
        "Okay",
        false
      );
    }
  }, [mediaPermissionDenied]);

  useEffect(() => {
    if (takingPhoto && !hasMediaPermission) {
      requestMediaPermission();
    }
  }, [takingPhoto, hasMediaPermission]);

  const renderTakePhotoState = () => {
    return (
      <div className="flex flex-col items-center">
        <div
          className="bg-black overflow-hidden flex flex-col items-center justify-center"
          style={{
            width: widgetSize.width + "px",
            height: widgetSize.height + "px",
          }}
        >
          <Webcam
            forceScreenshotSourceSize={false}
            audio={false}
            ref={webcamRef}
            width={widgetSize.width}
            height={widgetSize.height}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: CAMERA_WIDTH,
              height: CAMERA_HEIGHT,
              aspectRatio: CAMERA_WIDTH / CAMERA_HEIGHT,
              facingMode: "user",
            }}
          />
        </div>
        <div className="flex flex-row mt-8 justify-evenly">
          <div className="mx-2">
            <ButtonIconText onClick={() => setTakingPhoto(false)}>
              <XIcon className="w-6 h-6 text-black" />
            </ButtonIconText>
          </div>
          <div className="mx-2">
            <ButtonIconText onClick={capture}>
              <CameraIcon className="w-6 h-6 text-black" />
            </ButtonIconText>
          </div>
        </div>
      </div>
    );
  };

  const renderCurrentState = () => {
    let render;
    if (avatarSrc) {
      render = renderAvatarEditState();
    } else if (
      takingPhoto &&
      hasMediaPermission // &&
      // !parentAppDeclinedMediaPermissions &&
      //!mediaPermissionDenied
    ) {
      render = renderTakePhotoState();
    } else {
      render = renderInitialState();
    }
    return render;
  };

  return <div className="flex flex-row">{renderCurrentState()}</div>;
};

export default ProfilePhotoWidget;
