import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "wouter";

import { AppContext } from "../../contexts/appContext";
import { queryGroup, queryMe, queryUsers } from "../../queries";
import NavPage from "../Common/NavPage";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import Section from "../Common/Section";
import ErrorPage from "../ErrorPage/ErrorPage";
import FuturePrompts from "./FuturePrompts";
import GroupMembers from "./GroupMembers";
import GroupSchedule from "./GroupSchedule";
import GroupSettings from "./GroupSettings";
import GroupSettingsAdmin from "./GroupSettingsAdmin";
import GroupVideos from "./GroupVideos";

const GroupHome = ({ params }) => {
  const allowAdmin = process.env.REACT_APP_ALLOW_TEAM_ADMIN === "true";
  const { groupId } = params;
  const { displayError, displayModal } = useContext(AppContext);
  const [notFound, setNotFound] = useState(false);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [showAdminView, setShowAdminView] = useState(false);
  const [location, setLocation] = useLocation();
  const [selectedTabValue, setSelectedTabValue] = useState(undefined);

  const { error: queryGroupError, data: group } = useQuery(
    ["queryGroup", { groupId }],
    queryGroup
  );

  const { data: me } = useQuery("queryMe", queryMe);

  useEffect(() => {
    if (me && group) {
      const isAdmin = group?.AdminIds.includes(me.Id);
      setIsAdmin(isAdmin);
      setShowAdminView(isAdmin);
    }
  }, [me, group]);

  // const { data: currentPrompt } = useQuery(
  //   ['queryCurrentPromptForGroup', {groupId}],
  //   queryCurrentPromptForGroup,
  //   {enabled: !!group}
  // );

  // TODO Figure out general error handling for all queries..
  useEffect(() => {
    if (queryGroupError) {
      if (queryGroupError.response.status === 404) {
        setNotFound(true);
      } else {
        displayError(queryGroupError.response.data.Error.Message);
      }
    }
  }, [queryGroupError, setNotFound, displayError]);

  if (notFound) {
    return <ErrorPage code="404" message="Page not found" />;
  }

  const handleTabChange = (event, value) => {
    setLocation(`/teams/${groupId}/${value}`);
    setSelectedTabValue(value);
  };

  useEffect(() => {
    if (isAdmin !== undefined) {
      const value = location.split("/")[3]; //"/team/ABCDEFG/videos"
      if (value === undefined || (value === "prompts" && !isAdmin)) {
        setLocation(`/teams/${groupId}/videos`);
      } else {
        setSelectedTabValue(value);
      }
    }
  }, [location, isAdmin]);

  const pageIsReady =
    !!group && !!me && selectedTabValue !== undefined && isAdmin !== undefined;

  const getTabs = useCallback(() => {
    // The TabsList mui container is finicky about its contents such that you
    // can't use a normal jsx boolean expression hack to turn off the Prompts tab,
    // as that actually inserts a null into the children. So we need this array
    // of tabs.
    const tabs = [];
    // Note the "key" prop below is just to get rid of react warning for this array.
    tabs.push(<Tab label="Videos" value="videos" key="videos" />);
    tabs.push(<Tab label="Members" value="members" key="members" />);
    if (showAdminView) {
      tabs.push(<Tab label="Schedule" value="schedule" key="schedule" />);
    }
    tabs.push(<Tab label="Settings" value="settings" key="settings" />);
    return tabs;
  }, [showAdminView]);

  const showMemberPreview = useCallback(() => {
    if (selectedTabValue === "prompts") setSelectedTabValue("videos");
    setShowAdminView(!showAdminView);
  }, [selectedTabValue, showAdminView]);

  return (
    <div className="flex-1 flex flex-col w-full h-0">
      {/* WTF: I don't know why, but the "h-0" (or any fixed height (not h-full)) above allows our nested NavPage below to 
      do its flex-1 shink inside this flex-1.  It doesn't actually make the height 0.  If you remove it, NavPage won't
      shrink to fit, and bottom nav tabs will be off screen, with no scrolling availabe. -mgl */}

      {pageIsReady && (
        <Fragment>
          {isAdmin && (
            <div className="bg-black w-full h-10 flex items-center justify-center select-none">
              <div className="container px-3 ">
                <div className="flex justify-between items-center text-sm text-white font-medium">
                  {showAdminView && (
                    <div
                      className={`cursor-pointer font-bold`}
                      onClick={() => setShowAdminView(true)}
                    >
                      Team Admin
                    </div>
                  )}
                  <div
                    className={`cursor-pointer flex items-center ${
                      !showAdminView ? "font-bold" : "opacity-40"
                    }`}
                    onClick={showMemberPreview}
                  >
                    <span className="pr-1">
                      {showAdminView ? (
                        <InformationCircleIcon className="w-5 h-5" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 animate-pulse" />
                      )}
                    </span>

                    {showAdminView ? (
                      <span>What do members see?</span>
                    ) : (
                      <span className="animate-pulse">
                        Members see the limited controls shown below.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <TabContext value={selectedTabValue}>
            <Box
              className="bg-white flex justify-center"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                {getTabs()}
              </TabList>
            </Box>
            <NavPage
              backgroundColor="lightGray-6"
              title={group ? `${group.Emojicon} ${group.Name}` : ""}
              showBackButton={true}
              backLocation="/teams"
              backButtonText="Teams"
            >
              <TabPanel value="videos" style={{ padding: 0 }}>
                <GroupVideos group={group} showAdminView={showAdminView} />
              </TabPanel>
              <TabPanel value="members" style={{ padding: 0 }}>
                <GroupMembers
                  group={group}
                  me={me}
                  showAdminView={showAdminView}
                />
              </TabPanel>
              {showAdminView && (
                <TabPanel value="schedule" style={{ padding: 0 }}>
                  <GroupSchedule group={group} />
                </TabPanel>
              )}
              <TabPanel value="settings" style={{ padding: 0 }}>
                {showAdminView ? (
                  <GroupSettingsAdmin groupId={group.Id} />
                ) : (
                  <GroupSettings group={group} />
                )}
              </TabPanel>
            </NavPage>
          </TabContext>
        </Fragment>
      )}
    </div>
  );
};
export default GroupHome;
