import { useLocation } from "wouter";

import FixedLink from "../Common/FixedLink";

const InlinePlayLink = ({ promptId, showComments = false, children }) => {
  const [location] = useLocation();
  const commentsParam = showComments ? "&c=true" : "";
  return (
    <FixedLink href={`${location}?p=${promptId}${commentsParam}`}>
      <div className="cursor-pointer">{children}</div>
    </FixedLink>
  );
};

export default InlinePlayLink;
