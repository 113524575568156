// The "Answer Now" or "Watch New Video" button

const PromptStatusButton = ({ text, bgColor = "lightGray-4", big = false }) => {
  const textSize = big ? "text-lg" : "text-normal";
  return (
    <div className="absolute flex justify-center items-end">
      <div className="h-2/5 p-3 flex justify-center">
        <div
          style={{ maxHeight: "3rem" }} // Tailwind max-h not working for me
          className={`rounded-full bg-${bgColor} flex items-center justify-center shadow-md`}
        >
          <div
            className={`py-4 px-7 font-bold select-none text-white ${textSize} `}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptStatusButton;
