import { useCallback, useEffect, useState } from "react";

import { getAvailableVersion } from "../api";
import { VersionCheckContext } from "../contexts/versionCheckContext";

// Currently, version strings are just simple ints, but we handle
// string or number types. If that changes, update these function.
const isVersionNumber = (value) => {
  return typeof value === "string" || typeof value === "number";
};

const asVersionComparable = (value) => {
  let comparable = value;
  if (typeof value === "string") {
    comparable = parseInt(value.replace(/[^0-9]./g, ""));
  }
  return comparable;
};

const isGreaterVersion = (a, b) => {
  if (!isVersionNumber(a) || !isVersionNumber(b)) return false;
  const isGreater = asVersionComparable(a) > asVersionComparable(b);
  return isGreater;
};

const VersionCheckProvider = ({ children }) => {
  const [currentVersion, setCurrentVersion] = useState();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const runVersionCheck = useCallback(() => {
    getAvailableVersion().then((version) => {
      if (isGreaterVersion(version, currentVersion)) {
        setUpdateAvailable(true);
      }
    });
  }, [currentVersion]);

  useEffect(() => {
    getAvailableVersion().then((version) => {
      setCurrentVersion(version);
    });
  }, []);

  return (
    <VersionCheckContext.Provider
      value={{
        runVersionCheck,
        currentVersion, // String
        updateAvailable,
      }}
    >
      {children}
    </VersionCheckContext.Provider>
  );
};

export default VersionCheckProvider;
