import util from "util";

import { PlusCircleIcon } from "@heroicons/react/solid";
import { ArrowSmRightIcon } from "@heroicons/react/solid";
import { FormControl, FormControlLabel } from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import {
  deleteInvite,
  removeGroupMember,
  resendInvite,
  sendInvites,
  updateGroup,
} from "../../api";
import { AppContext } from "../../contexts/appContext";
import defaultProfile from "../../images/default-profile.png";
import { queryGroupManage, queryUsers } from "../../queries";
import FixedImg from "../Common/FixedImg";
import IOSSwitch from "../Common/IOSSwitch";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import Section from "../Common/Section";
import SectionHeader from "../Common/SectionHeader";
import EmailInviteSender from "./EmailInviteSender";
import GroupInvite from "./GroupInvite";

dayjs.extend(utc);

const EMAIL_INVITE_ACTION_NAME = "Send Email Invites";
const INVITE_CODE_ACTION_NAME = "Share Invite Code";

const GroupMembers = ({ group, me, showAdminView }) => {
  const queryClient = useQueryClient();
  const { displayError, displayModal } = useContext(AppContext);
  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });
  const [adminSaving, setAdminSaving] = useState(false);
  const [invites, setInvites] = useState([]);
  const [highlightInviteButton, setHighlightInviteButton] = useState(false);

  const [inviteEmails, setInviteEmails] = useState([]);
  const [showEmailInviteModal, setShowEmailInviteModal] = useState(false);

  const groupId = group.Id;
  const { error: queryError, data: groupManage } = useQuery(
    ["queryGroupManage", { groupId }],
    queryGroupManage,
    { enabled: showAdminView }
  );

  useEffect(() => {
    if (groupManage?.Invites) {
      setInvites(groupManage.Invites);
    }
  }, [groupManage]);

  useEffect(() => {
    if (groupManage && members) {
      setHighlightInviteButton(
        groupManage.Invites?.length === 0 && members.length === 1
      );
    }
  }, [groupManage, members]);

  // useEffect(() => {
  //   if (queryError) console.log(util.inspect(queryError, { depth: 5 }));
  // }, [queryError]);

  const sendInviteEmails = useCallback(async () => {
    const inviteArray = [];
    inviteEmails.forEach((inviteEmail) => {
      inviteArray.push({ Email: inviteEmail });
    });
    const response = await sendInvites(group.Id, {
      Invites: inviteArray,
    });
    // TODO handle error!
    setInviteEmails([]);
    queryClient.invalidateQueries("queryGroupManage");
  }, [inviteEmails]);

  useEffect(() => {
    // NOTE: we are handling this modal differently from elsewhere in the app
    // to workaround an issue where the 'sendInviteEmails' callback wouldn't
    // get updated.  Normally we use 'displayModal(...)' as a one-time call,
    // but here we call it again and again as 'inviteEmails' changes.
    if (showEmailInviteModal) {
      displayModal(
        EMAIL_INVITE_ACTION_NAME,
        <Fragment>
          {group && (
            <div className="my-8 self-center w-full">
              <EmailInviteSender
                emails={inviteEmails}
                setEmails={setInviteEmails}
              />
            </div>
          )}
        </Fragment>,
        sendInviteEmails,
        "Send Invites",
        true,
        () => setShowEmailInviteModal(false)
      );
    }
  }, [showEmailInviteModal, inviteEmails, sendInviteEmails]);

  const showInviteCodeModal = useCallback(() => {
    displayModal(
      INVITE_CODE_ACTION_NAME,
      <Fragment>
        {group && (
          <div className="my-8 self-center">
            <GroupInvite group={group} />
          </div>
        )}
      </Fragment>
    );
  }, [displayModal, group]);

  const InviteCodeGridItem = () => {
    return (
      <div
        onClick={showInviteCodeModal}
        className="cursor-pointer select-none w-full flex flex-row justify-start items-center text-blue py-2 px-2 sm:px-3 bg-white min-w-full"
      >
        <div className="w-8 h-8 flex justify-end items-center">
          <PlusCircleIcon />
        </div>
        <div className="ml-3 text-sm uppercase">{INVITE_CODE_ACTION_NAME}</div>
      </div>
    );
  };

  const removeMember = useCallback(
    async (member) => {
      const response = await removeGroupMember(group.Id, member.Id);
      if (response.data.Success) {
        const groupId = group.Id;
        queryClient.invalidateQueries(["queryGroup", { groupId }]); // Cause Group query to refresh
      } else {
        displayError(response.data.Error.Message);
      }
    },
    [group]
  );

  const handleClickMemberRemove = useCallback(
    (member) => {
      displayModal(
        `Remove ${member?.Name}?`,
        "This action cannot be undone, but you can invite them back later.",
        () => removeMember(member),
        "Yes, remove"
      );
    },
    [removeMember]
  );

  const handleClickMemberRole = useCallback(
    // This function is a toggle...
    async (member) => {
      setAdminSaving(true);
      let newAdminIds;
      if (group?.AdminIds.includes(member.Id)) {
        newAdminIds = group?.AdminIds.filter((id) => {
          return id != member.Id;
        });
      } else {
        newAdminIds = group?.AdminIds.concat([member.Id]);
      }
      await updateGroup(group.Id, {
        AdminIds: newAdminIds,
      }).catch((err, response) => {
        displayError(err.response.data.Error.Message);
      });
      queryClient.invalidateQueries("queryGroup");
      setAdminSaving(false);
    },
    [group]
  );

  const handleClickResendEmail = async (invite) => {
    const response = await resendInvite(group.Id, invite);
    queryClient.invalidateQueries("queryGroupManage");
  };

  const handleClickCancelInvite = async (invite) => {
    const response = await deleteInvite(group.Id, invite);
    queryClient.invalidateQueries("queryGroupManage");
  };

  const InviteListItem = ({ invite }) => {
    return (
      <div className="flex h-20 mb-3 justify-between items-center text-sm font-medium select-none bg-white py-2 px-3 rounded-xl drop-shadow-md">
        <div className="flex items-center w-full">
          <div className="w-12 flex flex-col items-center justify-center mr-3">
            <div className="w-12 h-12 rounded-full bg-gray-400 flex flex-col items-center justify-center">
              <div className="text-xs text-white font-medium">Invited</div>
            </div>
          </div>
          <div className="flex flex-col h-14 w-full justify-between">
            <div className="text-muiGray-1">{invite.Email}</div>
            <div className="text-2xs font-normal text-gray-400 -mt-1">
              {`An invite email was sent on ${dayjs(
                invite.LastSentTime * 1000
              ).format("MMM D")} at ${dayjs(invite.LastSentTime * 1000).format(
                "h:mm a"
              )}`}
            </div>
            <div className="flex justify-between w-full -mt-1">
              <div>
                {invite.CanResendInvite && (
                  <span
                    className={`text-sm text-blue`}
                    onClick={() => handleClickResendEmail(invite)}
                  >
                    Resend Email
                  </span>
                )}
              </div>
              <div
                className={`text-sm text-red`}
                onClick={() => handleClickCancelInvite(invite)}
              >
                Cancel Invite
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InvitesList = useCallback(() => {
    return (
      <div>
        {invites?.map((invite) => {
          return <InviteListItem key={invite.Email} invite={invite} />;
        })}
      </div>
    );
  }, [invites]);

  const MemberListItem = ({ member, group, me, showAdminView }) => {
    const isMe = member.Id === me?.Id;
    const isAdmin = group?.AdminIds.includes(member.Id);
    const buttonClasses = isMe ? "opacity-20" : "cursor-pointer";
    return (
      <div className="flex h-20 mb-3 justify-between items-center text-sm select-none bg-white py-2 px-3 rounded-xl drop-shadow-md">
        <div className="flex items-center">
          <FixedImg
            src={
              member.ProfilePhotoUrl ? member.ProfilePhotoUrl : defaultProfile
            }
            alt={member.FirstName}
            className="w-12 h-12 mr-3 rounded-full"
          />

          <div className="flex flex-col">
            <div className="text-muiGray-1">{member.Name}</div>
            <div className="text-muiGray-3 font-med text-xs">
              {isAdmin ? "Team Admin" : ""}
            </div>
          </div>
        </div>
        {showAdminView && (
          <div className="flex flex-col items-end">
            <div className={buttonClasses}>
              <FormControl>
                <FormControlLabel
                  className="-mt-1"
                  control={
                    <IOSSwitch
                      disabled={isMe || adminSaving}
                      checked={isAdmin}
                      onChange={() => handleClickMemberRole(member)}
                    />
                  }
                  labelPlacement="start"
                  label={<div className="text-sm text-muiGray-2">Admin</div>}
                />
              </FormControl>
            </div>
            <div
              className={`text-xs font-medium text-red ${buttonClasses}`}
              onClick={() => !isMe && handleClickMemberRemove(member)}
            >
              Remove Member
            </div>
          </div>
        )}
      </div>
    );
  };

  const MembersList = ({ group, me, showAdminView }) => {
    return (
      <div>
        {members?.map((member) => {
          return (
            <MemberListItem
              key={member.Id}
              member={member}
              group={group}
              me={me}
              showAdminView={showAdminView}
            />
          );
        })}
      </div>
    );
  };

  // const SendEmailInvitesButton = useCallback(() => {
  //   return (
  //     <div
  //       className="rounded-full bg-blue text-white font-medium px-5 py-1"
  //       onClick={showEmailInviteModal}
  //     >
  //       {EMAIL_INVITE_ACTION_NAME}
  //     </div>
  //   );
  // }, [showEmailInviteModal]);

  const SendEmailInvitesButton = useCallback(() => {
    return (
      <div
        className="select-none cursor-pointer rounded-full bg-blue text-white font-medium px-5 py-1"
        onClick={() => setShowEmailInviteModal(true)}
      >
        {EMAIL_INVITE_ACTION_NAME}
      </div>
    );
  }, [showEmailInviteModal]);

  return (
    <PageColumnContainer>
      <PageColumn>
        {/* {group?.InviteCode && (
          <Section>
            <div className="">
              <div className="mt-4 pb-4 py-4 px-6 bg-white flex flex-row mx-auto">
                <div className="flex-1">
                  <GroupInvite group={group} />
                </div>
              </div>
            </div>
          </Section>
        )} */}
        {showAdminView && (
          <div className="pb-4 flex w-full items-center justify-end">
            {highlightInviteButton && (
              <ArrowSmRightIcon className="mr-3 w-4 h-4 animate-ping" />
            )}
            <SendEmailInvitesButton />
          </div>
        )}
        {showAdminView && !!invites.length && (
          <Section>
            <SectionHeader label={`Open Invites (${invites.length}) `} />
            <InvitesList />
          </Section>
        )}
        {/* <Section>
          <InviteCodeGridItem />
        </Section> */}
        {members && (
          <Section>
            <SectionHeader label={`Members (${members.length})`} />
            <MembersList group={group} me={me} showAdminView={showAdminView} />
          </Section>
        )}
      </PageColumn>
    </PageColumnContainer>
  );
};

export default GroupMembers;
