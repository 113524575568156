import { createContext } from "react";

export const NavContext = createContext(null);

export const BottomNavEnum = {
  HIDDEN: 0,
  TEAMS: 1,
  VIDEOS: 2,
  PROMPTS: 3,
  NOTIFICATIONS: 4,
  SETTINGS: 5,
};
