import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import useFitText from "use-fit-text";

import FixedLink from "../Common/FixedLink";

dayjs.extend(utc);

const PromptCardHeader = ({
  group,
  prompt,
  showTeam,
  cornerText,
  isBlack = false,
  isUnwatched = false,
}) => {
  const [hiddenClass, setHiddenClass] = useState("opacity-0");
  const { fontSize, ref: fitTextRef } = useFitText({
    minFontSize: 50, // min size in %
    maxFontSize: 80, // max size in %
    //logLevel: "debug",
    onFinish: () => {
      // Have 0 opacity until size is calculated to eliminate flashing
      setHiddenClass("opacity-100");
    },
  });

  const groupTextSizeClass = group?.Name.length > 16 ? "text-xs" : "text-xs";
  const groupTextColorClass = isBlack ? "text-white" : "text-gray-800";
  const cornerTextColorClass = isBlack
    ? "text-lightGray-3"
    : "text-lightGray-3";
  if (!cornerText) {
    // ScheduledDate uses UTC time to indicate a particular day, so we don't translate this timestamp to local time
    cornerText = (
      <span className="uppercase tracking-widest">
        {dayjs.unix(prompt.ScheduledDate).utc().format("MMM D")}
      </span>
    );
  }

  return (
    <div className="flex justify-between h-6 items-baseline">
      <div className="flex items-baseline mr-2 max-w-1/2 cursor-pointer">
        {showTeam && (
          <div className={`pr-1 text-sm`}>
            <FixedLink href={`/teams/${group?.Id}`}>
              {group?.Emojicon}
            </FixedLink>
          </div>
        )}
        <div className="flex-row items-baseline overflow-hidden">
          {showTeam && (
            <FixedLink href={`/teams/${group?.Id}`}>
              <div
                ref={fitTextRef}
                style={{ fontSize }}
                className={`font-bold truncate ${hiddenClass} ${groupTextColorClass}`}
              >
                {group?.Name}
              </div>
            </FixedLink>
          )}
        </div>
      </div>
      <div
        className={`flex items-baseline select-none ${groupTextSizeClass} ${cornerTextColorClass}`}
      >
        {isUnwatched && (
          <div className="bg-blue rounded-full h-2.5 w-2.5 mr-1.5"></div>
        )}
        <div className="text-right font-bold">{cornerText}</div>
      </div>
    </div>
  );
};

export default PromptCardHeader;
