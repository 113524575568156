import React, { useState } from "react";

import WalkthroughCamera from "./WalkthroughCamera";
import WalkthroughPrompt from "./WalkthroughPrompt";
import WalkthroughRecord from "./WalkthroughRecord";
import WalkthroughStart from "./WalkthroughStart";

const Walkthrough = ({ onComplete }) => {
  // step of the walkthrough
  const totalSteps = 4;
  const [step, setStep] = useState(0);

  const handleStepFinished = () => {
    const newStep = step + 1;
    if (newStep >= totalSteps) {
      onComplete();
    } else {
      setStep(newStep);
    }
  };

  let stepBlock = <React.Fragment />;
  switch (step) {
    case 0:
      stepBlock = <WalkthroughStart handleStepFinished={handleStepFinished} />;
      break;
    case 1:
      stepBlock = <WalkthroughCamera handleStepFinished={handleStepFinished} />;
      break;
    case 2:
      stepBlock = <WalkthroughPrompt handleStepFinished={handleStepFinished} />;
      break;
    default:
      stepBlock = <WalkthroughRecord handleStepFinished={handleStepFinished} />;
      break;
  }

  return (
    <div className="absolute top-0 left-0 w-full min-h-full h-full flex flex-col items-center justify-center text-center z-50 select-none">
      <div className="flex h-full w-full">
        <div className="flex-1">{stepBlock}</div>
      </div>
    </div>
  );
};

export default Walkthrough;
