import { isIOS, isMobile } from "react-device-detect";

import gray12 from "../../images/12-gray.png";
import appStoreLogo from "../../images/appstore.png";
import playStoreLogo from "../../images/playstore.png";
import FixedImg from "../Common/FixedImg";
import FixedLink from "../Common/FixedLink";

const APP_STORE_URL =
  "https://apps.apple.com/tt/app/12seconds-for-teams/id1560276804";
const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=tv12seconds.app";

const AppStoreBadges = () => (
  <div className="flex">
    <a
      href={APP_STORE_URL}
      target="_blank"
      rel="noreferrer nofollow"
      className="mb-0 px-2 w-full text-white font-semibold text-lg flex items-center select-none"
    >
      <FixedImg
        src={appStoreLogo}
        alt="Get 12seconds for Teams on the App Store"
        className="h-10 object-scale-down"
      />
    </a>
    <a
      href={PLAY_STORE_URL}
      target="_blank"
      rel="noreferrer nofollow"
      className="mb-0 px-2 w-full text-white font-semibold text-lg flex items-center select-none"
    >
      <FixedImg
        src={playStoreLogo}
        alt="Get 12seconds for Teams on the Play Store"
        className="h-10 object-scale-down"
      />
    </a>
  </div>
);

const MarketingLinks = () => (
  <div className="flex flex-row text-xs sm:text-sm items-end">
    <div className="opacity-60 flex flex-row">
      &copy; 2021 Serendipity Systems
    </div>
    <div className="mx-2 sm:mx-3 opacity-60">•</div>
    <a href="mailto:info@12seconds.tv" className="opacity-60 hover:opacity-100">
      Contact
    </a>
    <div className="mx-2 sm:mx-3 opacity-60">•</div>
    <FixedLink href="/tos" className="opacity-60 hover:opacity-100">
      Terms
    </FixedLink>
    <div className="mx-2 sm:mx-3 opacity-60">•</div>
    <FixedLink href="/privacy" className="opacity-60 hover:opacity-100">
      Privacy
    </FixedLink>
  </div>
);

// const MarketingLinks = () => (
//   <div className="flex flex-col text-sm items-end">
//     <FixedLink href="/tos" className="">
//       Terms of Service
//     </FixedLink>
//     <FixedLink href="/privacy" className="">
//       Privacy Policy
//     </FixedLink>
//     <a href="mailto:info@12seconds.tv" className="">
//       Contact Us
//     </a>
//   </div>
// );

const MarketingFooterLogoAndCopyright = () => (
  <div className="flex justify-between items-center">
    <div className="flex flex-col">
      <FixedImg src={gray12} alt="12seconds" className="pb-2 w-36" />
      <div className="text-xs">&copy; 2021 Serendipity Systems</div>
    </div>
  </div>
);

const MarketingFooter = () => (
  <div
    className={`bg-black text-white px-6 pt-6 ${
      isMobile && isIOS ? "pb-16" : "pb-7"
    } select-none`}
  >
    {/* ^ We add extra padding on mobile iOS because of the pesky Open in App banner caused by univeral links when app is installed */}
    <div className="flex flex-col items-center text-xs">
      <MarketingLinks />
      <div className="pt-4">
        <AppStoreBadges />
      </div>
    </div>
  </div>
);

// const MarketingFooter = () => (
//   <div className="bg-black text-white text-opacity-60 px-6 pt-6 pb-7">
//     {/* Desktop, 3 columns */}
//     <div className="hidden sm:block">
//       <div className="grid grid-cols-3 items-end">
//         <MarketingFooterLogoAndCopyright />
//         <AppStoreBadges />
//         <MarketingLinks />
//       </div>
//     </div>

//     {/* Mobile, 2 cols and app links below */}
//     <div className="sm:hidden">
//       <div className="flex flex-row justify-between items-end">
//         <MarketingFooterLogoAndCopyright />
//         <MarketingLinks />
//       </div>
//       <div className="sm:hidden pt-6 flex justify-center">
//         <AppStoreBadges />
//       </div>
//     </div>
//   </div>
// );

export default MarketingFooter;
