// For the icon, pass in as children the heroicon component you want
import classNames from "classnames";

// These values are strings for tailwind classes...
const FG_COLOR = "black";
const BG_COLOR = "black";
const BG_OPACITY = "5";

const ButtonIconText = ({
  onClick,
  label,
  highlight = false,
  transparent = false, // True styles the button with no background color
  disabled = false,
  thinking = false, // True to show the thinking label and a spinner in place of the icon
  thinkingLabel = "Thinking...", // e.g. "Uploading..." "Saving..."
  children,
}) => {
  const bgOpacity = transparent ? 0 : BG_OPACITY;

  // Border action is complex..
  const BORDER_CLASSES =
    disabled || thinking // No border action for these states...
      ? `border-transparent`
      : highlight // If highlight, force the border...
      ? `border-${FG_COLOR}` // Border is full opacity and color of foreground
      : // Else border only on hover...
        "border-transparent"; // NO HOVER, SUCKS ON MOBILE:  `hover:border-${FG_COLOR}`;

  const BUTTON_CLASSES = classNames(
    "box-border",
    "flex flex-row items-center",
    "px-6 py-2",
    "border-2 rounded-md",
    "transition-color duration-100 ease-in",
    `bg-${BG_COLOR} bg-opacity-${bgOpacity}`,
    `text-xs text-black ${disabled || thinking ? "text-opacity-50" : ""}`
  );

  return (
    <button
      disabled={disabled || thinking}
      className={`${BUTTON_CLASSES} ${BORDER_CLASSES}`}
      onClick={onClick}
    >
      {children ? (
        <div>
          {thinking ? (
            <svg
              width={24}
              height={24}
              className="animate-spin"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            children
          )}
        </div>
      ) : (
        ""
      )}
      {label ? (
        <div className="pl-2">{thinking ? thinkingLabel : label}</div>
      ) : (
        ""
      )}
    </button>
  );
};

export default ButtonIconText;
