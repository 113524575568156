import { useQuery } from "react-query";

import {
  queryGroup,
  queryMe,
  queryPromptHistoryForGroup,
  queryUsers,
} from "../../queries";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import PromptsGrid from "../PromptsGrid";
import PromptScheduleDescription from "./PromptScheduleDescription";

const GroupVideos = ({ group, showAdminView }) => {
  const groupId = group.Id;
  const { data: promptHistory } = useQuery(
    ["queryPromptHistoryForGroup", { groupId }],
    queryPromptHistoryForGroup
  );

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center pb-3">
        <div className="w-full flex justify-center">
          <div className="-mt-2 pb-6 text-center text-lightGray-2 text-sm sm:text-base">
            <PromptScheduleDescription
              activeDays={group.ActiveDays}
              timeClose={group.TimeClose}
              timeZone={group.TimeZone}
              scheduledLinkPath={
                showAdminView ? `/teams/${group.Id}/settings` : undefined
              }
            />
          </div>
        </div>
        {promptHistory && (
          <div className="w-full">
            <div className="mt-4">
              <PromptsGrid prompts={promptHistory} showTeam={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupVideos;
