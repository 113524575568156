// An wouter Link that doesn't allow dragging or long-press context menu, making the site more "app like"
// Also always adds select-none and cursor-pointer for children.

// VERSION CHECKING/SITE RELOADING IS HANDLED BY THIS COMPONENT.

import { useCallback, useContext } from "react";
import { Link, useLocation } from "wouter";

import { VersionCheckContext } from "../../contexts/versionCheckContext";

const FixedLink = (props) => {
  const [, setLocation] = useLocation();
  const { updateAvailable, runVersionCheck } = useContext(VersionCheckContext);
  const { onClick, href } = props;
  const updatedProps = { ...props }; // Copy because can't modify props
  delete updatedProps.children;
  if (!updatedProps.style) updatedProps.style = {};
  updatedProps.style["WebkitTouchCallout"] = "none";
  if (!updatedProps.className) {
    updatedProps.className = "";
  }
  updatedProps.className =
    updatedProps.className + " select-none cursor-pointer";

  const versionCheckerOnClick = useCallback(
    (e) => {
      // First perform any onclick action (e.g. hiding the sidebar)
      if (typeof onClick === "function") onClick();
      // Next, if last version check saw an update, refresh app...
      if (updateAvailable) {
        console.log("Update available. Reloading app...");
        setLocation(href);
        window.location.reload();
        return false;
      } else {
        // Else run a version check for next time...
        runVersionCheck();
      }
      e.stopPropagation();
    },
    [updateAvailable, runVersionCheck]
  );

  return (
    <Link
      {...updatedProps}
      onDragStart={(e) => {
        e.preventDefault();
      }}
      onClick={versionCheckerOnClick}
    >
      {props.children}
    </Link>
  );
};

export default FixedLink;
