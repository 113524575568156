import { ArrowSmRightIcon } from "@heroicons/react/solid";
import { Fragment, useCallback, useEffect, useState } from "react";
import isMobile from "react-device-detect";
import { useQuery } from "react-query";
import TextLoop from "react-text-loop";

import { queryGroups, queryUsers } from "../../queries";
import { randomNiceEmojiArray } from "../../utils";
import FixedLink from "../Common/FixedLink";
import NavPage from "../Common/NavPage";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import Section from "../Common/Section";
import Members from "../PromptCard/Members";
import PromptScheduleDescription from "./PromptScheduleDescription";

const GroupList = () => {
  const [
    randomEmojisForCreateButton,
    setRandomEmojisForCreateButton,
  ] = useState(randomNiceEmojiArray());
  const { data: groups } = useQuery("queryGroups", queryGroups);

  const borderHighlightClasses = "border-2 hover:border-white";

  const GroupListItem = ({ group }) => {
    const userIds = group?.UserIds;
    const [needsMoreMembers, setNeedsMoreMembers] = useState(false);
    const { data: members } = useQuery(
      ["queryUsers", { userIds }],
      queryUsers,
      {
        enabled: !!userIds,
      }
    );

    useEffect(() => {
      setNeedsMoreMembers(members?.length === 1);
    }, [members]);

    return (
      <div className="mb-5 flex flex-col w-full rounded-xl select-none drop-shadow-lg cursor-pointer select-none">
        <FixedLink
          className={`h-32 flex-1 w-full flex flex-row justify-start items-center px-5 py-5 bg-yellow bg-gradient-to-b from-whiteOpacity-50 rounded-t-xl ${borderHighlightClasses} border-yellow`}
          href={`/teams/${group.Id}${
            needsMoreMembers ? "/members" : "/videos"
          }`}
        >
          <div
            className="rounded rounded-full bg-yellow-1 flex items-center justify-center"
            style={{ height: "4.5rem", width: "4.5rem", minWidth: "4.5rem" }}
          >
            <div className="text-3xl">{group.Emojicon}</div>
          </div>
          <div className="ml-8 flex flex-col">
            <div className="text-lg font-medium">{group.Name}</div>
            <div className="mt-1 text-xs text-black opacity-70">
              <PromptScheduleDescription
                concise={true}
                activeDays={group.ActiveDays}
                timeClose={group.TimeClose}
                timeZone={group.TimeZone}
              />
            </div>
          </div>
        </FixedLink>
        <FixedLink href={`/teams/${group.Id}/members`}>
          <div
            className={`h-12 bg-yellow-1 rounded-b-xl overflow-hidden ${borderHighlightClasses} border-yellow-1`}
          >
            {members && (
              <Fragment>
                <div className="h-12 px-4 pb-2 pt-1">
                  <Members
                    members={members}
                    noFadeOnMembers={true}
                    bgColor="yellow-1"
                  />
                </div>
                {needsMoreMembers && (
                  <div className="-mt-12 h-12 w-full flex flex-col items-center justify-center px-4 pb-1 pt-2">
                    <div className="text-black text-sm font-medium flex items-center ml-4">
                      Next Step: Send Email Invites{" "}
                      <ArrowSmRightIcon className="ml-2 w-4 h-4 animate-ping" />
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </FixedLink>
      </div>
    );
  };

  const CreateNewGroupListItem = useCallback(() => {
    return (
      <div className="mb-5 flex flex-col w-full rounded-full select-none drop-shadow-lg select-none cursor-pointer">
        <FixedLink href={`/teams/create`}>
          <div
            className={`h-32 flex-1 w-full flex flex-row justify-start items-center px-5 py-5 bg-yellow bg-gradient-to-b from-whiteOpacity-50 rounded-full ${borderHighlightClasses} border-yellow`}
          >
            <div
              className="rounded rounded-full bg-yellow-1 flex items-center justify-center overflow-hidden"
              style={{ height: "4.5rem", width: "4.5rem" }}
            >
              <div className="text-3xl">
                {!isMobile ? (
                  <div>😃</div>
                ) : (
                  <TextLoop
                    delay={2700}
                    mask={true}
                    interval={1700}
                    // springConfig={{ stiffness: 180, damping: 8 }}
                  >
                    {randomEmojisForCreateButton}
                  </TextLoop>
                )}
              </div>
            </div>
            <div className="ml-8 flex flex-col">
              <div className="text-lg font-medium">Create New Team</div>
            </div>
          </div>
        </FixedLink>
      </div>
    );
  }, [randomEmojisForCreateButton]);

  return (
    <div className="">
      <CreateNewGroupListItem />
      {groups &&
        groups.map((group, i) => {
          const isFirst = i === 0;
          const isLast = i === groups.length - 1;
          return (
            <GroupListItem
              key={group.Id}
              group={group}
              isFirst={isFirst}
              isLast={isLast}
            />
          );
        })}
    </div>
  );
};

const Groups = () => {
  return (
    <NavPage backgroundColor="lightGray-6" title="Teams">
      <PageColumnContainer>
        <PageColumn>
          <Section>
            <GroupList />
            {/* <div className="flex justify-center my-5">
              <FixedLink
                className="text-white text-xs font-bold bg-black rounded-full px-5 py-2.5 uppercase"
                href="/teams/create"
              >
                Create New Team
              </FixedLink>
            </div> */}
          </Section>
        </PageColumn>
      </PageColumnContainer>
    </NavPage>
  );
};

export default Groups;
