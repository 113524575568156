// Refactored all of the Prompt.js recorder stuff into here.

import { useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "../contexts/appContext";

const PRERECORD_COUNTDOWN = 3;

const useRecorder = (webcamRef, maxRecordSeconds = 12) => {
  const [capturing, setCapturing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [videoBlob, setVideoBlob] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [preRecordCountdown, setPreRecordCountdown] = useState(
    PRERECORD_COUNTDOWN
  );
  const [preRecordCountingDown, setPreRecordCountingDown] = useState(false);
  const [recordingCountdown, setRecordingCountdown] = useState(
    maxRecordSeconds
  );
  const [captureStartDate, setCaptureStartDate] = useState();
  const [remainingMilliseconds, setRemainingMilliseconds] = useState(0);

  const { displayError } = useContext(AppContext);
  const mediaRecorderRef = useRef(null);

  // Called once at recorder.stop(). Would be called at intervals
  // if we specify timeslice on recorder.start(), but we don't.
  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setVideoBlob(data);
      setVideoUrl(URL.createObjectURL(data));
    } else {
      displayError(
        "No video data returned from recorder. Please tell us what you were doing when you saw this. Were you doing retakes or cancels or anything complicated? We appreciate the help."
      );
    }
    setProcessing(false);
  };

  useEffect(() => {
    return () => {
      // CLEAN UP
      abortRecording();
    };
  }, []);

  const abortRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.ondataavailable = () => {};
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop();
      }
    }
  };

  const startCapture = () => {
    setCaptureStartDate(new Date());
    setCapturing(true);
    setRecordingCountdown(maxRecordSeconds);
    try {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.start();
    } catch (e) {
      initRecorder();
      displayError(
        "Ooops! The 12seconds recorder requires a more recent operating system and/or browser."
      );
    }
  };

  const initRecorder = () => {
    abortRecording();
    setCaptureStartDate();
    setRemainingMilliseconds(0);
    setCapturing(false);
    setProcessing(false);
    setVideoBlob();
    setVideoUrl();
    setPreRecordCountdown(PRERECORD_COUNTDOWN);
    setPreRecordCountingDown(false);
  };

  const startRecorder = () => {
    initRecorder();
    setRemainingMilliseconds(maxRecordSeconds * 1000);
    setPreRecordCountingDown(true);
  };

  const stopRecorder = () => {
    if (mediaRecorderRef.current?.state === "recording") {
      try {
        mediaRecorderRef.current.stop();
      } catch (e) {
        displayError(e.message); // Unexpected!
      }
      setProcessing(true); // Spinner in controls until blob is processed.
      setCapturing(false);
    }
  };

  const cancelRecorder = () => {
    initRecorder();
  };

  useEffect(() => {
    if (preRecordCountingDown) {
      const intervalId = setInterval(() => {
        let countdown = preRecordCountdown - 1;
        if (countdown > 0) {
          setPreRecordCountdown(countdown);
        } else {
          setPreRecordCountingDown(false);
          setPreRecordCountdown(3);
          startCapture();
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [preRecordCountdown, preRecordCountingDown]);

  useEffect(() => {
    if (capturing) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const remainingMilliseconds =
          maxRecordSeconds * 1000 -
          (now.getTime() - captureStartDate.getTime());
        const remainingSeconds = Math.ceil(remainingMilliseconds / 1000);
        setRemainingMilliseconds(remainingMilliseconds);
        setRecordingCountdown(remainingSeconds);
        if (remainingMilliseconds <= 0) {
          stopRecorder();
        }
      }, 10);
      return () => clearInterval(intervalId);
    }
  }, [capturing, captureStartDate, maxRecordSeconds]);

  return {
    startRecorder,
    cancelRecorder,
    preRecordCountingDown,
    capturing,
    processing,
    videoBlob,
    videoUrl,
    preRecordCountdown,
    recordingCountdown,
    remainingMilliseconds,
  };
};

export default useRecorder;
