// Hook for detecting changes to a particular url search param.
// Example use: `const mySearchParamValue = useSearchParam(PARAM_NAME);`

// Addresses 'wouter' not supporting search params.
// See discussion: https://github.com/molefrog/wouter/issues/58

// Wouter currently has no support for detectig search param changes,
// and useLocation does not return search params, nor does it fire
// its hook when params change.

// Unfortunately, the 3rd party search param hooks I've tried don't work
// because Wouter uses camelCase names for history change events instead
// of all lowercase. Fun fun! I tried "react-use" and "use-query-params".
// WARNING: Those may appear to work in React dev hot-reloading, but they
// don't actually work from the build. Fooled me for a while.

// Let's keep checking the wouter project for built-in support for search
// params; they have been discussing ways to add it for a year now...

import { useEffect, useState } from "react";

const isBrowser = typeof window !== "undefined";

const getValue = (search, param) => {
  return new URLSearchParams(search).get(param);
};

const useSearchParam = (param) => {
  const location = window.location;
  const [value, setValue] = useState(() =>
    getValue(window.location.search, param)
  );

  useEffect(() => {
    const onChange = () => {
      setValue(getValue(window.location.search, param));
    };
    // Browser (back button) fires these:
    window.addEventListener("popstate", onChange);
    window.addEventListener("pushstate", onChange);
    window.addEventListener("replacestate", onChange);
    // Wouter fires these:
    window.addEventListener("popState", onChange);
    window.addEventListener("pushState", onChange);
    window.addEventListener("replaceState", onChange);

    // This is SUPER weird, but initial state of 'value' isn't correct
    // when using the browser back button.  It's the old value.  Say you
    // were viewing an inline prompt and hit 'back' to a url without the
    // param.  The hook still returns the old search param!!?!
    // Seems to have to do with the timing of event listeners getting
    // cleaned up below, because if you don't clean them up, it's okay.
    // ANYWAYS: It works fine if we always give it one push. (Probably
    // Causes an additional render when back/forward button is used.)
    onChange(); // Have to give it one push!?!? WTF.

    return () => {
      window.removeEventListener("popstate", onChange);
      window.removeEventListener("pushstate", onChange);
      window.removeEventListener("replacestate", onChange);
      window.removeEventListener("popState", onChange);
      window.removeEventListener("pushState", onChange);
      window.removeEventListener("replaceState", onChange);
    };
  }, [location.search, param]);

  return value;
};

const useSearchParamServer = () => null;

// The "react-use" "useSearchParam" library ignores their search hook
// if using react SSR. Not sure we'll need this, but here it is...
export default isBrowser ? useSearchParam : useSearchParamServer;
