// Robust access to safe area insets, as px string or number.  Always get through here.
// Provides a way for us to hack this feature into android parent app, whose WebView doesn't support it yet.

import { useMemo } from "react";

const useSafeAreaInsets = () => {
  const getSafeAreaInset = useMemo(
    () => (edgeName) => {
      // Android WebView isn't setting the safe-area-inset values yet. The android
      // knows the safe areas, though, so it sets these properties for us
      let inset;
      if (window.parentApp?.safeAreaInsetOverrides) {
        inset = window.parentApp.safeAreaInsetOverrides[edgeName];
      }
      // But except for android app, we just get the computed style, which
      // will be "0px", or on iOS webkit, some other px value.
      if (!inset) {
        // our index.css is setting this property for us from browser env
        inset = getComputedStyle(document.documentElement).getPropertyValue(
          `--safe-area-inset-${edgeName}`
        );
      }
      return inset || "0px"; // default to "0px" just in case, but not known to be needed.
    },
    []
  );

  const numberOfPx = useMemo(
    () => (pxNumberString) => {
      return parseInt(pxNumberString.split("px")[0]);
    },
    []
  );

  const safeAreaInsetTop = getSafeAreaInset("top");
  const safeAreaInsetTopNumber = numberOfPx(safeAreaInsetTop);
  const safeAreaInsetBottom = getSafeAreaInset("bottom");
  const safeAreaInsetBottomNumber = numberOfPx(safeAreaInsetBottom);
  const safeAreaInsetLeft = getSafeAreaInset("left");
  const safeAreaInsetLeftNumber = numberOfPx(safeAreaInsetLeft);
  const safeAreaInsetRight = getSafeAreaInset("right");
  const safeAreaInsetRightNumber = numberOfPx(safeAreaInsetRight);
  return {
    safeAreaInsetTop,
    safeAreaInsetTopNumber,
    safeAreaInsetBottom,
    safeAreaInsetBottomNumber,
    safeAreaInsetLeft,
    safeAreaInsetLeftNumber,
    safeAreaInsetRight,
    safeAreaInsetRightNumber,
  };
};

export default useSafeAreaInsets;
