import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createLocalStoragePersistor } from "react-query/createLocalStoragePersistor-experimental";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //staleTime: 1000 * 60 * 5, // Just for testing
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: (failureCount, error) => {
        // Our custom retry logic. No retry on 404, 403.
        let retry = true;
        if (failureCount > 2) retry = false;
        else if (error.response?.status === 401) retry = false;
        else if (error.response?.status === 404) retry = false;
        else if (error.response?.status === 403) retry = false;
        return retry;
      }
    }
  },
});

// CACHE PERSISTOR IN LOCAL STORAGE
const localStoragePersistor = createLocalStoragePersistor();
persistQueryClient({
   queryClient,
   persistor: localStoragePersistor,
   buster: "1", // https://react-query.tanstack.com/plugins/persistQueryClient#cache-busting
   // CHANGE THE BUSTER STRING IF WE MODIFY DATA STRUCTURES!
});


const QueryProvider = ({children}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}

export default QueryProvider;
