import { useLocation } from "wouter";

import Spinner from "../Spinner";

const ResponsesRedirect = ({ params }) => {
  const [,setLocation] = useLocation();
  const { promptId } = params;
  setLocation("/home?p=" + promptId);
  return (<Spinner/>)
};

export default ResponsesRedirect;
