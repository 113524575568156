import React, { Fragment } from "react";

import PromptCard from "./PromptCard/PromptCard";

const PromptsGrid = ({ prompts, showTeam }) => {
  if (!prompts) return <Fragment />;

  const mostRecentPromptByGroupId = {};

  // We may have prompts from multiple groups.  Return
  // true if this is the first prompt in the list for
  // any group.
  const promptIsMostRecent = (prompt) => {
    let isMostRecent = false;
    if (!mostRecentPromptByGroupId[prompt.GroupId]) {
      mostRecentPromptByGroupId[prompt.GroupId] = prompt;
      isMostRecent = true;
    }
    return isMostRecent;
  };

  return (
    <Fragment>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 gap-y-5 sm:gap-y-9">
        {prompts.map((prompt) => {
          const isMostRecent = promptIsMostRecent(prompt);
          return (
            <PromptCard
              prompt={prompt}
              key={prompt.Id}
              showTeam={showTeam}
              isNew={isMostRecent}
            />
          );
        })}
      </div>
      {!prompts.length && (
        <div className="my-10 flex flex-col justify-center items-center text-center text-bold text-lightGray-1">
          Videos will appear here once your team starts answering prompts.
        </div>
      )}
    </Fragment>
  );
};

export default PromptsGrid;
