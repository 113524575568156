import { useContext } from "react";

import { WindowSizeContext } from "../contexts/windowSizeContext";
import { remToPx } from "../utils";
import useSafeAreaInsets from "./useSafeAreaInsets";

const useVideoAreaHeight = (includeHeader = true) => {
  const { windowSize } = useContext(WindowSizeContext);
  const {
    safeAreaInsetTopNumber,
    safeAreaInsetBottomNumber,
  } = useSafeAreaInsets();

  /*
  we size the controls height with tailwind, so this constant doesn't
  actually define the height of the controls. it's just used for
  getting the dimensions of the webcam. todo: have the header
  and controls export their height.
  */
  const controlsHeight = remToPx(6) + safeAreaInsetBottomNumber;
  const headerHeight = includeHeader ? remToPx(5) + safeAreaInsetTopNumber : 0;

  const videoAreaHeight = windowSize.height - controlsHeight - headerHeight;

  return videoAreaHeight;
};

export default useVideoAreaHeight;
