import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { queryGroups, queryUsers } from "../../queries";
import FixedLink from "../Common/FixedLink";
import InlinePlayLink from "../InlinePlay/InlinePlayLink";
import Members from "./Members";

dayjs.extend(utc);

const PromptCard = ({ prompt, showTeam, isNew }) => {
  const { data: groups } = useQuery("queryGroups", queryGroups);
  const [group, setGroup] = useState();
  const [responseUsers, setResponsedUsers] = useState([]);

  useEffect(() => {
    if (groups) {
      const foundGroup = groups.find((g) => g.Id === prompt.GroupId);
      setGroup(foundGroup);
    }
  }, [groups, prompt.GroupId, setGroup]);

  useEffect(() => {
    if (prompt && prompt.Timeline?.UserEntries) {
      // Some corrupt prompts are missing Timeline on dev
      const userIds = prompt.Timeline.UserEntries.map((userEntry) => {
        return userEntry.UserId;
      });
      setResponsedUsers(userIds);
    }
  }, [prompt, setResponsedUsers]);

  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });

  const unknownWatched = prompt.Watched === undefined;
  const unwatched = unknownWatched || !prompt.Watched;

  return (
    <InlinePlayLink promptId={prompt.Id}>
      <div className="bg-white rounded-xl drop-shadow-xl h-44 flex">
        <div className="flex flex-col h-full w-1/4">
          <div
            className="h-full rounded-l-xl"
            style={{
              backgroundColor: "#CCC", // Be gray while image is loading
              backgroundImage: `url(${prompt.FinalThumbnailUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
          <div className="-mt-8 h-8">
            {isNew && unwatched && (
              <div className="h-8 flex flex-col items-center justify-center bg-blue text-white p-1 w-full text-center text-xs font-bold rounded-bl-xl">
                WATCH NEW
              </div>
            )}
            {!isNew && unwatched && (
              <div className="h-8 flex items-center pl-2">
                <div className="h-4 w-4 bg-blue rounded-full" />
              </div>
            )}
          </div>
        </div>
        <div className="px-2 flex flex-col justify-between w-3/4">
          <div className="pt-2 text-xs flex flex-col space-y-1">
            {showTeam && (
              <div>
                <FixedLink href={`/teams/${group?.Id}`}>
                  <div className={`font-bold truncate`}>
                    {group?.Emojicon}
                    {group?.Name}
                  </div>
                </FixedLink>
              </div>
            )}
            <div className="text-gray-400">
              {dayjs.unix(prompt.ScheduledDate).utc().format("MMMM DD, YYYY")}
            </div>
          </div>

          <div className="text-base mt-2 font-medium leading-5 text-gray-800">
            {prompt.PromptText}
          </div>
          <div className="flex flex-col">
            <Members
              members={members}
              bgColor="white"
              responded={responseUsers}
              onlyResponded={true}
            />

            <div className="text-xs py-2 flex space-x-1">
              {/* <div>
                {prompt?.Timeline?.UserEntries.length || "0"} answer
                {prompt?.Timeline?.UserEntries.length === 1 ? "" : "s"}
              </div>
              <div>&middot;</div> */}
              <div>
                {prompt?.WatchedUsers.length || "0"} view
                {prompt?.WatchedUsers.length === 1 ? "" : "s"}
              </div>
              <div>&middot;</div>
              <InlinePlayLink promptId={prompt.Id} showComments={true}>
                {prompt?.CommentCount} comment
                {prompt?.CommentCount === 1 ? "" : "s"}
              </InlinePlayLink>
            </div>
          </div>
        </div>
      </div>
    </InlinePlayLink>
  );
};

export default PromptCard;
