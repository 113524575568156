import { isMobile } from "react-device-detect";

import downBolt from "../images/down-bolt.svg";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";
import SafeAreaInsetBottom from "./Common/SafeAreaInsetBottom";

const WalkthroughRecord = ({ handleStepFinished }) => {
  return (
    <div className="w-screen h-screen bg-opacity-50 bg-black flex items-center justify-center">
      <div className="flex flex-col justify-center items-center bo">
        <div
          className={`${
            isMobile
              ? "left-1/2 -ml-36 mb-1 bottom-28"
              : "left-1/2 -ml-36 mb-1 bottom-28"
          } absolute z-10 w-72 mb-6`}
        >
          <div className="bg-yellow text-black p-6 rounded flex flex-col items-center justify-center ">
            <div className="font-bold select-none">Record Button</div>
            <div className="text-sm py-4 select-none text-left mb-2">
              Press the red button to begin recording. You will see a three
              second countdown before your twelve second recording begins. After
              you record an answer, you can preview, retake, cancel, or submit
              it. Give it a try!
            </div>
            <div>
              <ButtonPill onClick={handleStepFinished}>Okay</ButtonPill>
            </div>
          </div>
        </div>
        <FixedImg
          src={downBolt}
          alt="bolt arrow"
          className={`${
            isMobile ? "left-1/2 -ml-8 bottom-16" : "left-1/2 -ml-8 bottom-16"
          } absolute`}
        />
        <SafeAreaInsetBottom />
        {/* ^ Account for extra padding on controls when on iOS */}
      </div>
    </div>
  );
};

export default WalkthroughRecord;
