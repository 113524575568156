// use type="submit" for forms
import classNames from "classnames";

const GoButton = ({
  children,
  onClick,
  type = "button",
  bgColor = "black",
  textColor = "white",
}) => {
  const classnames = classNames(
    `bg-${bgColor} text-${textColor} uppercase font-bold rounded-full w-16 h-16 text-lg`
  );
  return (
    <div>
      {/* ^ without this containing div, button may expand to full width of parent */}
      <button
        style={{ WebkitTapHighlightColor: "rgba(201, 224, 253, 0.8)" }}
        type={type}
        className={classnames}
        onClick={onClick}
      >
        {children || "GO"}
      </button>
    </div>
  );
};

export default GoButton;
