import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getMyPromptResponses } from "../api";
import { queryGroups, queryMe } from "../queries";
import FixedLink from "./Common/FixedLink";
import PageColumn from "./Common/PageColumn";
import PageColumnContainer from "./Common/PageColumnContainer";
import PageMargin from "./Common/PageMargin";
import PageTitle from "./Common/PageTitle";
import Section from "./Common/Section";
import SectionHeader from "./Common/SectionHeader";
import IntroCard from "./IntroCard";
import Twelves from "./Twelves";

const Profile = () => {
  const [myTwelves, setMyTwelves] = useState({});

  const { data: me } = useQuery("queryMe", queryMe);
  const { data: groups } = useQuery("queryGroups", queryGroups);

  // TODO. Can't use useQuery in useEffect! So how can loop over groups?
  //  Maybe make a query function in queries.js that does multiple
  //  queries and returns combined results?
  useEffect(() => {
    if (groups) {
      groups.forEach((group) => {
        getMyPromptResponses(group.Id).then((response) => {
          const fetchedResponses = response.data.Data.Responses;
          setMyTwelves((myTwelves) => ({
            ...myTwelves,
            [group.Id]: fetchedResponses,
          }));
        });
      });
    }
  }, [groups]);

  const myName = `${me?.FirstName} ${me?.LastName}`;
  return (
    <PageMargin>
      <PageTitle label={myName} />
      <PageColumnContainer>
        <PageColumn>
          <Section label="Intro 12">
            <IntroCard
              videoUrl={me?.IntroPromptResponse?.VideoUrl}
              introPromptId={groups ? groups[0]?.IntroPromptId : null}
            />
          </Section>
          <Section label="Teams">
            {groups &&
              groups.map((group) => (
                <div className="flex items-center mb-8" key={group.Id}>
                  <div className="text-5xl mr-4">
                    <FixedLink href={`/teams/${group.Id}`}>
                      {group.Emojicon}
                    </FixedLink>
                  </div>
                  <div>
                    <div className="font-bold text-blue">
                      <FixedLink href={`/teams/${group.Id}`}>
                        {group.Name}
                      </FixedLink>
                    </div>
                    <div className="text-sm cursor-pointer">
                      {group.UserIds.length} members
                    </div>
                  </div>
                </div>
              ))}
          </Section>
        </PageColumn>
        <PageColumn>
          <Section label="Past 12s">
            {groups &&
              groups.map((group) => (
                <Twelves
                  group={group}
                  responses={myTwelves[group.Id]}
                  key={group.Id}
                />
              ))}
          </Section>
        </PageColumn>
      </PageColumnContainer>
    </PageMargin>
  );
};

export default Profile;
