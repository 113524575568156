import { ChatAlt2Icon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import utc from "dayjs/plugin/utc";
import { Fragment, useState } from "react";
import Countdown from "react-countdown";
import { useQuery } from "react-query";
import useFitText from "use-fit-text";

import recordCircle from "../images/record-circle.svg";
import { queryUsers } from "../queries";
import {
  getLocalWeekdayPromptCloses,
  isPromptEarly,
} from "../utils/promptUtils";
import FixedLink from "./Common/FixedLink";
import InlinePlayLink from "./InlinePlay/InlinePlayLink";
import Members from "./PromptCard/Members";
import PromptCardHeader from "./PromptCard/PromptCardHeader";
import PromptStatusButton from "./PromptCard/PromptStatusButton";

const CLOSED_OPACITY = "opacity-30"; // Allowing me to easily modify, it's used in two places
const PROCESSING_OPACITY = "opacity-80";
const CLOSED_CORNER_TEXT = (
  <span className="tracking-wider uppercase">Closed</span>
);

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const StatusMessage = ({ children }) => {
  return (
    <div className="absolute h-full bg-gradient-to-b from-blackOpacity-90 to-blackOpacity-80 bg-blur-sm select-none flex flex-col justify-center items-center py-4 px-6 pb-6 text-normal text-white font-bold">
      {children}
    </div>
  );
};

const RecordButton = () => (
  <div className="flex justify-center items-end">
    <div className="h-18 p-3 flex justify-center">
      <div
        style={{ maxHeight: "3rem" }} // Tailwind max-h not working for me
        className={`rounded-full border-grey-500 border-2 border-opacity-30 bg-white flex items-center justify-center shadow-md`}
      >
        <div className="py-2 px-5 select-none text-black font-bold flex items-center justify-center text-lg">
          <img className="pr-2" src={recordCircle} />
          <div>Answer Now</div>
        </div>
      </div>
    </div>
  </div>
);

const RecordEarlyButton = () => (
  <div className="flex justify-center items-end">
    <div className="h-18 p-3 flex justify-center">
      <div
        style={{ maxHeight: "3rem" }} // Tailwind max-h not working for me
        className={`rounded-full border-gray-300 border-2 bg-white flex items-center justify-center shadow-md`}
      >
        <div className="py-2 px-5 select-none text-gray-400 font-medium flex items-center justify-center text-lg">
          {/* <img className="pr-2" src={recordCircle} /> */}
          <div>Answer Early</div>
        </div>
      </div>
    </div>
  </div>
);

const EarlyOverlay = ({ weekdayOfClose = "Tuesday" }) => {
  return (
    <Fragment>
      <div className="w-full h-3/5 flex flex-col items-center justify-center px-6 sm:px-5 pt-6 sm:pt-4">
        <div
          className={`text-gray-400 text-lg font-medium select-none text-center`}
        >
          {`Next prompt arrives ${weekdayOfClose}`}
        </div>
        <div
          className={`text-black text-lg font-bold select-none text-center`}
        ></div>
      </div>
    </Fragment>
  );
};

const PromptTextOverlay = ({ prompt, noGradient = true }) => {
  const [hiddenClass, setHiddenClass] = useState("opacity-0");
  const { fontSize, ref: fitTextRef } = useFitText({
    minFontSize: 100, // min size in %
    maxFontSize: 200, // max size in %
    logLevel: "debug",
    onFinish: () => {
      // Have 0 opacity until size is calculated to eliminate flashing
      setHiddenClass("opacity-100");
    },
  });
  const gradientClasses = noGradient
    ? ""
    : "bg-gradient-to-b from-blackOpacity-80";
  return (
    <Fragment>
      <div
        className={`absolute top-0 left-0 bottom-10 w-full h-full flex items-end ${gradientClasses}`}
      ></div>
      <div
        ref={fitTextRef}
        style={{ fontSize }}
        // Note that small screens have one column and bigger prompts, hence bigger padding.
        className={`text-black font-bold px-6 sm:px-5 pt-6 sm:pt-4 w-full h-3/5 sm:leading-snug select-none transition-opacity text-center ${hiddenClass}`}
      >
        {prompt.PromptText}
      </div>
    </Fragment>
  );
};

const GroupStatusCanSubmit = ({ prompt, isEarly }) => {
  return (
    <FixedLink href={`/prompts/${prompt.Id}`}>
      <div className="aspect-w-16 aspect-h-9 cursor-pointer">
        <div className="w-full h-full flex items-center justify-left bg-white text-black" />
        {isEarly ? (
          <Fragment>
            <EarlyOverlay
              weekdayOfClose={getLocalWeekdayPromptCloses(prompt)}
            />
            <RecordEarlyButton />
          </Fragment>
        ) : (
          <Fragment>
            <PromptTextOverlay prompt={prompt} />
            <RecordButton />
          </Fragment>
        )}
      </div>
    </FixedLink>
  );
};

const GroupStatusSubmitted = ({ prompt }) => {
  const date = dayjs.unix(prompt.ReadyAt);
  const time = date.format("h:mm A");
  let day;
  if (date.isToday()) {
    day = "today";
  } else if (date.isTomorrow()) {
    day = "tomorrow";
  } else {
    day = date.format("dddd");
  }
  return (
    <div className="aspect-w-16 aspect-h-9">
      <div className={`${PROCESSING_OPACITY}`}>
        <div
          className="w-full h-full flex items-center justify-left"
          style={{
            backgroundImage: `url(${prompt.MyResponse.ThumbnailUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
        {/* <FixedImg src={prompt.MyResponse.ThumbnailUrl} alt="Video" /> */}
      </div>
      <PromptTextOverlay prompt={prompt} />
      <StatusMessage>
        <div className="flex flex-col items-center space-y-1 shadow-md text-center">
          <div>Thanks for answering!</div>
          <div>{`Finished video arrives ${day}`}</div>
          <div>{`at ${time}`}</div>
        </div>
      </StatusMessage>
    </div>
  );
};

const GroupStatusFinalVideo = ({ prompt }) => {
  return (
    <InlinePlayLink promptId={prompt.Id}>
      <div className="aspect-w-16 aspect-h-9 cursor-pointer">
        <div
          className="w-full h-full flex items-center justify-left"
          style={{
            backgroundImage: `url(${prompt.FinalThumbnailUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
        <PromptTextOverlay prompt={prompt} />
        <PromptStatusButton text="Watch New Video" bgColor="blue" />
      </div>
    </InlinePlayLink>
  );
};

const GroupStatusNoResponses = ({ prompt }) => {
  return (
    <div className={`aspect-w-16 aspect-h-9 ${CLOSED_OPACITY}`}>
      <div className={`text-black bg-white font-black `}>
        <div className="w-full h-full flex items-center justify-left" />
      </div>
      <PromptTextOverlay prompt={prompt} />
      <StatusMessage>
        <div className="flex flex-col items-center justify-center space-y-1">
          <div>No one answered.</div>
          <div>Next prompt coming soon!</div>
        </div>
      </StatusMessage>
    </div>
  );
};

const GroupStatusClosed = ({ prompt }) => {
  const time = dayjs.unix(prompt.ReadyAt).format("h:mm A");
  return (
    <div className={`aspect-w-16 aspect-h-9`}>
      <div className={`text-black bg-white font-black `}>
        <div className="w-full h-full flex items-center justify-left" />
      </div>
      <PromptTextOverlay prompt={prompt} />
      <StatusMessage>
        <div className="flex flex-col items-center justify-center space-y-1">
          <div>{`Finished video arrives at ${time}`}</div>
        </div>
      </StatusMessage>
    </div>
  );
};

const GroupStatus = ({ group, prompt }) => {
  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });

  if (!prompt) return <Fragment />;

  let StatusCard;
  let opacity = "";
  let cornerText;
  let onlyResponded = false;
  let responded = prompt.ResponseUsers;

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    const totalHours = days * 24 + hours;
    if (completed) {
      return CLOSED_CORNER_TEXT;
    } else {
      let remaining = `${totalHours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      return (
        <span className="tracking-wider normal-case">
          Closing in {remaining}
        </span>
      );
    }
  };

  // prompt.CurrentPhase can be [WaitingToOpen|Open|Closed|NoResponses|ReadyToView]
  const phase = prompt.CurrentPhase;
  let isEarly = false;
  if (phase === "Closed") {
    StatusCard = <GroupStatusClosed prompt={prompt} group={group} />;
    cornerText = CLOSED_CORNER_TEXT;
    onlyResponded = true;
  } else if (phase === "NoResponses") {
    StatusCard = <GroupStatusNoResponses prompt={prompt} />;
    opacity = CLOSED_OPACITY;
    cornerText = CLOSED_CORNER_TEXT;
  } else if (phase === "ReadyToView") {
    StatusCard = <GroupStatusFinalVideo prompt={prompt} group={group} />;
    cornerText = (
      <span className="tracking-wider uppercase">Ready to View</span>
    );
    // Use the order of Timeline for members instead of prompt.RespondedUsers
    responded = prompt.Timeline?.UserEntries.map((ue) => ue.UserId);
    onlyResponded = true;
  } else if (prompt.MyResponse) {
    StatusCard = <GroupStatusSubmitted prompt={prompt} />;
    cornerText = <span className="tracking-wider uppercase">Submitted</span>;
  } else if (prompt.CanSubmit) {
    isEarly = isPromptEarly(prompt);
    StatusCard = <GroupStatusCanSubmit prompt={prompt} isEarly={isEarly} />;
    cornerText = (
      <Countdown date={prompt.ClosesAt * 1000} renderer={countdownRenderer} />
    );
  }

  return (
    <div className="w-full bg-white rounded-xl shadow-xl">
      <div className={`px-2 pt-1 ${opacity} border-b border-gray-100`}>
        <PromptCardHeader
          group={group}
          prompt={prompt}
          showTeam={true}
          cornerText={isEarly ? null : cornerText}
        />
      </div>
      <div>{StatusCard}</div>
      <div className="flex items-end justify-between px-2 pb-2 bg-lightGray-6 rounded-b-xl">
        <div className={`flex-1 ${opacity}`}>
          <Members
            members={members}
            responded={responded}
            onlyResponded={onlyResponded}
            bgColor={"lightGray-6"}
          />
        </div>
        {prompt.CurrentPhase === "ReadyToView" && (
          <InlinePlayLink promptId={prompt.Id} showComments={true}>
            <div className="flex items-center text-gray-800 pl-2">
              <ChatAlt2Icon className="w-6 h-6" />
              <div className="text-sm font-bold pl-1">
                {prompt?.CommentCount}
              </div>
            </div>
          </InlinePlayLink>
        )}
      </div>
    </div>
  );
};

export default GroupStatus;
