import { useContext, useEffect } from "react";
import { useLocation } from "wouter";

import { AppContext } from "../contexts/appContext";
import Spinner from "./Spinner";

const Login = ({ params }) => {
  const [, setLocation] = useLocation();
  const { me, setToken } = useContext(AppContext);

  useEffect(() => {
    // Set the token, which will allow App.js to do a queryMe and get us started...
    setToken(params.token);
  }, [setToken, params.token]);

  useEffect(() => {
    if (me) {
      // When we have me, that means App.js got our token and has logged us in.
      setLocation("/videos");
    }
  }, [me]);

  return (
    <div className="flex flex-col h-screen w-screen items-center justify-center text-yellow">
      <Spinner />
    </div>
  );
};

export default Login;
