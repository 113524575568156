import { useCallback, useState } from "react";

const useModal = () => {
  const [isModalShowing, setIsModalShowing] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalShowing(!isModalShowing);
  }, [isModalShowing]);

  const showModal = () => {
    setIsModalShowing(true);
  };

  const hideModal = () => {
    setIsModalShowing(false);
  };

  return {
    isModalShowing,
    toggleModal,
    showModal,
    hideModal,
  };
};

export default useModal;
