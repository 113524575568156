// Used for filling the top safe area inset (e.g. iOS status bar) */

import useSafeAreaInsets from "../../hooks/useSafeAreaInsets";

const SafeAreaInsetTop = ({ bgColor = "transparent" }) => {
  const { safeAreaInsetTop } = useSafeAreaInsets();
  return (
    <div
      // Unexpected, but mobile safari is letting empty div area get selected!? So, select-none.
      className={`bg-${bgColor} select-none`}
      style={{ height: safeAreaInsetTop }}
    />
  );
};

export default SafeAreaInsetTop;
