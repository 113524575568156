import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { queryGroups, queryHomePageDedupedData } from "../queries";
import NavPage from "./Common/NavPage";
import GroupCreateLink from "./GroupCreateLink";
import GroupStatus from "./GroupStatus";

const Videos = () => {
  const [showGroupCreateLink, setShowGroupCreateLink] = useState(false);
  const [sortedPrompts, setSortedPrompts] = useState();
  const { data: groups } = useQuery("queryGroups", queryGroups);
  const { data: homePageDedupedData } = useQuery(
    "queryHomePageDedupedData",
    queryHomePageDedupedData,
    {
      // Shorten cache so you don't always see yesterday's prompts
      cacheTime: 1000 * 60 * 60 * 1, // 1 hours
    }
  );

  useEffect(() => {
    if (homePageDedupedData && groups) {
      const { currentPrompts } = homePageDedupedData;
      currentPrompts.forEach((prompt) => {
        const group = groups.find((g) => {
          return g.Id === prompt.GroupId;
        });
        prompt._group = group;
      });
      const sorted = currentPrompts.sort((a, b) => {
        if (a.ClosesAt > b.ClosesAt) {
          return 1;
        } else if (a.ClosesAt < b.ClosesAt) {
          return -1;
        } else {
          return a._group.Name.localeCompare(b._group.Name);
        }
      });
      setSortedPrompts(sorted);
    }
  }, [groups, homePageDedupedData]);

  const groupsPlural = groups?.length > 1;

  // People with no groups go to group create page
  useEffect(() => {
    if (groups) {
      setShowGroupCreateLink(groups.length === 0);
    }
  }, [groups]);

  return (
    <NavPage backgroundColor="lightGray-6" title="Prompts">
      <div className="container mx-auto">
        <div className="flex flex-col items-center pb-3">
          {sortedPrompts && (
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 gap-y-5 sm:gap-y-7">
              {sortedPrompts.map((prompt) => {
                {
                  return (
                    <GroupStatus
                      key={prompt.Id}
                      group={prompt._group}
                      prompt={prompt}
                    />
                  );
                }
              })}
            </div>
          )}
          {showGroupCreateLink && (
            <GroupCreateLink text="Create a team to start receiving video prompts!" />
          )}
          {sortedPrompts && !sortedPrompts.length && (
            <div className="my-10 text-lightGray-1">
              No scheduled prompt{groupsPlural ? "s" : ""} today.
            </div>
          )}
        </div>
      </div>
    </NavPage>
  );
};

export default Videos;
