import "react-multi-email/style.css";

import { ReactMultiEmail, isEmail } from "react-multi-email";

const EmailInviteSender = ({ emails, setEmails }) => {
  return (
    <div className="flex flex-col text-gray-500 w-full">
      <div className="text-gray-500 text-sm pb-8">
        Enter email addresses to send invitations to your team.
      </div>
      <ReactMultiEmail
        placeholder="Email addresses"
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        validateEmail={(email) => {
          return isEmail(email); // return boolean
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default EmailInviteSender;
