import { DeviceMobileIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

import { AppContext } from "../contexts/appContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import appStoreLogo from "../images/appstore.png";
import playStoreLogo from "../images/playstore.png";
import FixedImg from "./Common/FixedImg";

const APP_STORE_URL =
  "https://apps.apple.com/tt/app/12seconds-for-teams/id1560276804";
const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=tv12seconds.app";

const GetTheApp = () => {
  const { hasParentApp } = useContext(ParentAppContext);
  const { displayModal } = useContext(AppContext);

  const GetTheAppButton = () => (
    <div className="flex select-none items-center cursor-pointer bg-black border-2 border-white rounded-md py-1.5 px-1 sm:px-2 ">
      <DeviceMobileIcon className="h-6 w-6 text-white -ml-1" />
      <div className="font-medium sm:ml-0.5 mr-0.5 sm:mr-1 text-xs sm:text-sm text-white leading-3 text-center">
        Get the App
      </div>
    </div>
  );

  const GetTheAppChoicesDialogBody = () => {
    return (
      <div className="flex flex-col">
        <a
          href={APP_STORE_URL}
          target="_blank"
          rel="noreferrer nofollow"
          className="mb-0 px-4 py-2 w-full text-black font-semibold text-lg flex items-center select-none"
        >
          <FixedImg
            src={appStoreLogo}
            alt="Get 12seconds for Teams on the App Store"
            className="h-16"
          />
        </a>
        <a
          href={PLAY_STORE_URL}
          target="_blank"
          rel="noreferrer nofollow"
          className="mb-0 px-4 py-2 w-full text-black font-semibold text-lg flex items-center select-none"
        >
          <FixedImg
            src={playStoreLogo}
            alt="Get 12seconds for Teams on the Play Store"
            className="h-16"
          />
        </a>
      </div>
    );
  };

  const openDialog = () => {
    displayModal(
      <span className="font-medium text-lg">Select a store:</span>,
      <GetTheAppChoicesDialogBody />,
      () => {},
      "Cancel",
      false
    );
  };

  let button = null;
  if (!hasParentApp) {
    if (isIOS || isAndroid) {
      const href = isAndroid ? PLAY_STORE_URL : APP_STORE_URL;
      button = (
        <a href={href} target="_blank" rel="noreferrer nofollow">
          <GetTheAppButton />
        </a>
      );
    } else {
      // Else not on android or ios, probably desktop, and we need to show choices...
      button = (
        <div onClick={openDialog}>
          <GetTheAppButton />
        </div>
      );
    }
  }

  return button;
};

export default GetTheApp;

// <a
//                   href="https://apps.apple.com/tt/app/12seconds-for-teams/id1560276804"
//                   target="_blank"
//                   rel="noreferrer nofollow"
//                   className="mb-0 px-4 py-2 w-full text-black font-semibold text-lg flex items-center select-none"
//                 ></a>
