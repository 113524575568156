import { useRef, useState } from "react";

const GroupInvite = ({ group }) => {
  const linkRef = useRef(null);
  const [linkCopied, setLinkCopied] = useState(false);

  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setLinkCopied(true);
  };

  const inviteCode = `${window.location.origin}/invite/${group?.InviteCode}`;
  const width = Math.round(inviteCode.length / 1.75) + "rem"; // Input box sizing, lol
  return (
    <div className="-mx-1">
      {/* negative margin offsets margin added for flex wrap */}
      <div className="mx-1 mb-2 text-gray-500 text-sm">
        Send anyone this handy link and they can join your team:
      </div>
      <div className="flex flex-wrap width-full">
        <input
          ref={linkRef}
          value={inviteCode}
          style={{ maxWidth: width }}
          className="mx-1 mb-2 p-2 border flex-1 font-mono text-lightGray-2 rounded text-sm"
          readOnly={true}
        />
        <button
          className="mx-1 mb-2 bg-black text-sm uppercase text-white h-10 px-4 cursor-pointer w-40"
          onClick={copyToClipboard}
        >
          {linkCopied ? "Link copied!" : "Copy invite link"}
        </button>
      </div>
    </div>
  );
};

export default GroupInvite;
