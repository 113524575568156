import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "wouter";

import { ParentAppContext } from "../contexts/parentAppContext";
import downBolt from "../images/down-bolt.svg";
import BlurScreenOverlay from "./Common/BlurScreenOverlay";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";
import SafeAreaInsetTop from "./Common/SafeAreaInsetTop";

const NeedCameraPermissions = () => {
  const [, setLocation] = useLocation();
  /*return (
    <BlurScreenOverlay>
      <div className="flex flex-col justify-center items-center select-none">
        {!isMobile && (
          <FixedImg
            src={downBolt}
            alt="bolt arrow"
            className="absolute top-0 horizontal-flip left-24"
          />
        )}
        <div className="bg-yellow text-black p-6 rounded flex flex-col items-center justify-center w-72 absolute top-12 mt-5 left-20">
          <div className="font-bold">Camera Access...</div>
          <div className="text-sm py-4">
            12seconds needs camera and microphone access so you can record your
            videos. Please allow camera and microphone permissions for your
            browser.
            <div className="text-xs py-4">
              FYI: We never record unless you hit the record button.
            </div>
          </div>
          <div>
            <ButtonPill onClick={() => setLocation("/prompts")}>
              Cancel
            </ButtonPill>
          </div>
        </div>
      </div>
    </BlurScreenOverlay>
  );*/
  const { hasParentApp } = useContext(ParentAppContext);

  let platform = "web browser";
  if (isMobile && hasParentApp) platform = "12seconds app";
  else if (isMobile && !hasParentApp) platform = "mobile browser app";
  let requestText = `12seconds needs camera and microphone access so you can record your
  videos. Please allow your ${platform} to access the camera and microphone.`;
  return (
    <div className="w-screen h-screen bg-black select-none flex flex-col justify-start">
      <SafeAreaInsetTop />
      <div className="relative">
        <div className="w-72 absolute mx-auto left-0 right-0 top-12 mt-5 z-10">
          <div className="bg-yellow text-black p-6 rounded flex flex-col items-center justify-center">
            <div className="font-bold">Camera and Microphone Access</div>
            <div className="text-sm py-4">
              {requestText}
              <div className="text-xs py-4 font-medium">
                Privacy note: 12seconds only records you when you press the
                record button, and only uploads when you press Submit.
              </div>
            </div>
            <div className="flex w-full justify-between">
              <ButtonPill
                small={true}
                onClick={() => {
                  setLocation("/prompts");
                }}
              >
                Cancel
              </ButtonPill>
              <ButtonPill
                small={true}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Retry
              </ButtonPill>
            </div>
          </div>
        </div>
        {!isMobile && (
          <FixedImg
            src={downBolt}
            alt="bolt arrow"
            className="absolute mx-auto pl-32 top-0 left-0 right-0 z-0 horizontal-flip"
          />
        )}
      </div>
    </div>
  );
};

export default NeedCameraPermissions;
