import { useContext, useEffect } from "react";

import { NavContext } from "../../contexts/navContext";

const NavPage = ({
  children,
  backgroundColor = "light-gray-6",
  title,
  showBackButton = false,
  backLocation = "/",
  backButtonText = "Back",
  allowShowGetTheApp = true,
}) => {
  const { updateTopNav } = useContext(NavContext);

  useEffect(() => {
    updateTopNav(
      title,
      showBackButton,
      backLocation,
      backButtonText,
      allowShowGetTheApp
    );
  }, [title, showBackButton, backLocation, backButtonText]);

  return (
    <div
      className={`flex-1 flex flex-col items-center w-full bg-${backgroundColor} overflow-y-scroll`}
      style={{}}
    >
      <div className="flex flex-col items-center w-full pt-8 px-3">
        <div className="container sm:px-3">{children}</div>
      </div>
    </div>
  );
};

export default NavPage;
