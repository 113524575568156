import rightBolts from "../images/right-bolts.svg";
import ButtonPill from "./Common/ButtonPill";
import FixedImg from "./Common/FixedImg";

const WalkthroughStart = ({ handleStepFinished }) => {
  return (
    <div className="w-full h-full bg-yellow text-black flex flex-col items-center justify-center select-none">
      <div className="container mx-auto flex justify-center items-center flex-col">
        <FixedImg src={rightBolts} alt="Bolts!!!" className="py-8" />
        <div className="text-black text-center font-bold text-xl uppercase">
          Make your first 12 second video!
        </div>
        <div className="py-8 w-full sm:w-3/4 md:w-1/2 px-6">
          We'll walk you through the steps.
        </div>
        <div className="py-4">
          <ButtonPill large="true" onClick={handleStepFinished}>
            Let's do it!
          </ButtonPill>
        </div>
      </div>
    </div>
  );
};

export default WalkthroughStart;
