import icon from "../../images/12-black.svg";

const Terms = () => (
  <div className="p-12">
    <div className="mb-8">
      <a href="/">
        <img className="h-32" src={icon} alt="12seconds" />
      </a>
    </div>
    <p className="my-4" align="center">
      <strong>
        SERENDIPITY SYSTEMS INC. TERMS OF SERVICE<sup></sup>
      </strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4" align="right">
      <strong>
        <em>Date of Last Revision</em>
      </strong>
      <strong>: 08 June, 2021</strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Acceptance of These Terms of Service</u>
      </strong>
      <strong></strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      Serendipity Systems Inc. (“12seconds.tv,” “we,” “us,” or “our”) provides
      our services (described below) and related content to you through our
      website(s) located at 12seconds.tv (the “Site”) and through our mobile
      applications and related technologies (“Mobile Apps”, and collectively,
      such Mobile Apps and the Site, including any updated or new features,
      functionality and technology, the “Service”). All access and use of the
      Service is subject to the terms and conditions contained in these Terms of
      Service (as amended from time to time, these “Terms of Service”). By
      accessing, browsing, or otherwise using the Site, Mobile Apps, or any
      other aspect of the Service, you acknowledge that you have read,
      understood, and agree to be bound by these Terms of Service. If you do not
      accept the terms and conditions of these Terms of Service, you will not
      access, browse, or otherwise use the Service.
    </p>
    <p className="my-4">
      We reserve the right, at our sole discretion, to change or modify portions
      of these Terms of Service at any time. If we do this, we will post the
      changes on this page and will indicate at the top of this page the date
      these Terms of Service were last revised. You may read a current,
      effective copy of these Terms of Service by visiting the “Terms of
      Service” link on the Site and under the “Legal — Terms of Service” section
      of our Mobile App. We will also notify you of any material changes, either
      through the Service user interface, a pop-up notice, email, or through
      other reasonable means. Your continued use of the Service after the date
      any such changes become effective constitutes your acceptance of the new
      Terms of Service. You should periodically visit this page to review the
      current Terms of Service so you are aware of any revisions. If you do not
      agree to abide by these or any future Terms of Service, you will not
      access, browse, or use (or continue to access, browse, or use) the
      Service.
    </p>
    <p className="my-4">
      <strong>
        <em>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
          AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
          LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
          REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
          AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL
          ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST COMPANY ON AN INDIVIDUAL
          BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
          REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO
          SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF)
          ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS
          YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
        </em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Your Privacy: </strong>
      At Company, we respect the privacy of our users. For more information
      please see our Privacy Policy, located at http://12seconds.tv/privacy and
      under the [Legal — Privacy Policy] section of our Mobile App (the “Privacy
      Policy”). By using the Service, you consent to our collection, use and
      disclosure of personal data and other data as outlined therein.{" "}
      <strong></strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>Additional Terms</strong>: In addition, when using certain
      features through the Service, you will be subject to any additional terms
      applicable to such features that may be posted on or within the Service
      from time to time.{" "}
      <strong>
        <em> </em>
      </strong>
      All such terms are hereby incorporated by reference into these Terms of
      Service.
    </p>
    <p className="my-4">
      <strong>
        <u>Access and Use of the Service</u>
      </strong>
      <strong></strong>
    </p>
    <p className="my-4">
      <strong>Service Description: </strong>
      The Service is designed to provide a multimedia communication platform
      that allows users to record and share video messages with other users.
    </p>
    <p className="my-4">
      <strong>Your Registration Obligations: </strong>
      You may be required to register with Company or provide information about
      yourself (<em>e.g.</em>, name and email address) in order to access and
      use certain features of the Service.<strong> </strong>If you choose to
      register for the Service, you agree to provide and maintain true,
      accurate, current, and complete information about yourself as prompted by
      the Service’s registration form. Registration data and certain other
      information about you are governed by our Privacy Policy. If you are under
      13 years of age, you are not authorized to use the Service, with or
      without registering. In addition, if you are under 18 years old, you may
      use the Service, with or without registering, only with the approval of
      your parent or guardian.
    </p>
    <p className="my-4">
      <strong>Member Account, Password and Security: </strong>
      You are responsible for maintaining the confidentiality of your password
      and account details, if any, and are fully responsible for any and all
      activities that occur under your password or account. You agree to (a)
      immediately notify Company of any unauthorized use of your password or
      account or any other breach of security, and (b) ensure that you exit from
      your account at the end of each session when accessing the Service.
      Company will not be liable for any loss or damage arising from your
      failure to comply with this paragraph.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>Modifications to Service: </strong>
      Company reserves the right to modify or discontinue, temporarily or
      permanently, the Service (or any part thereof) with or without notice. You
      agree that Company will not be liable to you or to any third party for any
      modification, suspension or discontinuance of the Service.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong></strong>
      <strong>General Practices Regarding Use and Storage</strong>: You
      acknowledge that Company may establish general practices and limits
      concerning use of the Service, including the maximum period of time that
      data or other content will be retained by the Service and the maximum
      storage space that will be allotted on Company’s or its third-party
      service providers’ servers on your behalf. You agree that Company has no
      responsibility or liability for the deletion or failure to store any data
      or other content maintained or uploaded by the Service. You acknowledge
      that Company reserves the right to terminate accounts that are inactive
      for an extended period of time. You further acknowledge that Company
      reserves the right to change these general practices and limits at any
      time, in its sole discretion, with or without notice.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Conditions of Access and Use</u>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      <strong>User Conduct: </strong>
      You are solely responsible for all video, images, information, data, text,
      code, software, music, sound, photographs, graphics, messages, and other
      materials (“content”) that you make available to Company or other users,
      including by uploading, posting, publishing, or displaying (hereinafter,
      “upload(ing)”) via the Service or by transmitting or otherwise making
      available to other users of the Service (collectively, “User Content”).
      The following are examples of the kinds of content and/or uses that are
      illegal or prohibited by Company. Company reserves the right to
      investigate and take appropriate legal action against anyone who, in
      Company’s sole discretion, violates this provision, including removing the
      offending content from the Service, suspending or terminating the account
      of such violators, and reporting the violator to law enforcement
      authorities. You agree to not use the Service to:
    </p>
    <p className="my-4">
      a) transmit or otherwise upload any content that (i) infringes any
      intellectual property or other proprietary rights of any party; (ii) you
      do not have a right to upload under any law or under contractual or
      fiduciary relationships; (iii) contains software viruses or any other
      computer code, files or programs designed to interrupt, destroy, or limit
      the functionality of any computer software or hardware or
      telecommunications equipment; (iv) poses or creates a privacy or security
      risk to any person; (v) constitutes unsolicited or unauthorized
      advertising, promotional materials, commercial activities and/or sales,
      “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,”
      “sweepstakes,” or any other form of solicitation; (vi) is unlawful,
      harmful, threatening, abusive, harassing, tortious, excessively violent,
      defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s
      privacy, hateful, discriminatory, or otherwise objectionable; or (vii) in
      the sole judgment of Company, is objectionable or which restricts or
      inhibits any other person from using or enjoying the Service, or which may
      expose Company or its users to any harm or liability of any type;
    </p>
    <p className="my-4">
      b) interfere with or disrupt the Service or servers or networks connected
      to the Service, or disobey any requirements, procedures, policies, or
      regulations of networks connected to the Service;
    </p>
    <p className="my-4">
      c) violate any applicable local, state, national, or international law, or
      any regulations having the force of law;
    </p>
    <p className="my-4">
      d) impersonate any person or entity, or falsely state or otherwise
      misrepresent your affiliation with a person or entity;
    </p>
    <p className="my-4">
      e) solicit personal information from anyone under the age of 18;
    </p>
    <p className="my-4">
      f) harvest or collect email addresses or other contact information of
      other users from the Service by electronic or other means for the purposes
      of sending unsolicited emails or other unsolicited communications;
    </p>
    <p className="my-4">
      g) advertise or offer to sell or buy any goods or services for any
      business purpose that is not specifically authorized;
    </p>
    <p className="my-4">
      h) further or promote any criminal activity or enterprise or provide
      instructional information about illegal activities;
    </p>
    <p className="my-4">
      i) obtain or attempt to access or otherwise obtain any content or
      information through any means not intentionally made available or provided
      for through the Service;
    </p>
    <p className="my-4">
      j) circumvent, remove, alter, deactivate, degrade, or thwart any of the
      content protections in or geographic restrictions on any content
      (including Service Content (as defined below)) available on or through the
      Service, including through the use of virtual private networks; or
    </p>
    <p className="my-4">
      k) engage in or use any data mining, robots, scraping, or similar data
      gathering or extraction methods. If you are blocked by Company from
      accessing the Service (including by blocking your IP address), you agree
      not to implement any measures to circumvent such blocking (e.g., by
      masking your IP address or using a proxy IP address or virtual private
      network).
    </p>
    <p className="my-4">
      <strong>
        <em> </em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <em> </em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Fees:</strong>
      To the extent the Service or any portion thereof is made available for any
      fee, you may be required to select a payment plan and provide information
      regarding your credit card or other payment instrument. You represent and
      warrant to Company that such information is true and that you are
      authorized to use the payment instrument. You will promptly update your
      account information with Company or the Payment Processor (as defined
      below), as applicable, of any changes (for example, a change in your
      billing address or credit card expiration date) that may occur. You agree
      to pay Company the amount that is specified in the payment plan in
      accordance with the terms of such plan and these Terms of Service. If your
      payment plan includes an ongoing subscription that is automatically
      renewed periodically, you hereby authorize Company (through the Payment
      Processor) to bill your payment instrument in advance on such periodic
      basis in accordance with the terms of the applicable payment plan until
      you terminate your account, and you further agree to pay any charges so
      incurred. If you dispute any charges you must let Company know within
      sixty (60) days after the date that Company charges you. We reserve the
      right to change Company’s prices. If Company does change prices, Company
      will provide notice of the change through the Service user interface, a
      pop-up notice, email, or through other reasonable means, at Company’s
      option, at least thirty (30) days before the change is to take effect.
      Your continued use of the Service after the price change becomes effective
      constitutes your agreement to pay the changed amount. You will be
      responsible for all taxes associated with the Service, other than taxes
      based on Company’s net income.<strong></strong>
    </p>
    <p className="my-4">
      <a name="_heading=h.gjdgxs"></a>
      <strong>Payment Processing:</strong>
      Notwithstanding any amounts owed to Company hereunder, COMPANY DOES NOT
      PROCESS PAYMENT FOR ANY SERVICES. To facilitate payment for the Service
      via bank account, credit card, or debit card, we use third-party payment
      processors (collectively, “Payment Processors”). These payment processing
      services are provided by the Payment Processors and are subject to the
      applicable Payment Processor’s terms and conditions, privacy policy, and
      all other relevant agreements (collectively, the “Payment Processor
      Agreements”). By agreeing to these Terms of Service, users that use the
      payment functions of the Service also agree to be bound by the applicable
      Payment Processor Agreement for the payment function the user is using, as
      the same may be modified by the applicable Payment Processor from time to
      time. You hereby authorize the applicable Payment Processor to store and
      continue billing your specified payment method even after such payment
      method has expired, to avoid interruptions in payment for your use of the
      Service. Please contact the applicable Payment Processor for more
      information. Company assumes no liability or responsibility for any
      payments you make through the Service.
    </p>
    <p className="my-4">
      <strong>Refunds and Cancellations</strong>: Payments made by you hereunder
      are final and non-refundable, unless otherwise determined by Company. You
      may cancel your subscription online by emailing us at: info@12seconds.tv.
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Commercial Use: </strong>
      Unless otherwise expressly authorized herein or in the Service, you agree
      not to display, distribute, license, perform, publish, reproduce,
      duplicate, copy, create derivative works from, modify, sell, resell,
      exploit, transfer, or upload for any commercial purposes, any portion of
      the Service, use of the Service, or access to the Service.{" "}
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Mobile Services and Software</u>
      </strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>Mobile Services: </strong>
      The Service includes certain services that are available via a mobile
      device, including (i) the ability to upload content to the Service via a
      mobile device, (ii) the ability to browse the Service and the Site from a
      mobile device, and (iii) the ability to access certain features and
      content through Mobile Apps (collectively, the “Mobile Services”). To the
      extent you access the Service through a mobile device, your wireless
      service carrier’s standard charges, data rates, and other fees may apply.
      In addition, downloading, installing, or using certain Mobile Services may
      be prohibited or restricted by your carrier, and not all Mobile Services
      may work with all carriers or devices.
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Mobile App License:</strong>
      Subject to these Terms of Service, Company hereby grants to you a limited,
      revocable, non-exclusive, non-transferable, non-sublicensable license to
      (a) install the Mobile App on one mobile device and (b) use the Mobile App
      for your own personal use solely to access and use the Service. For
      clarity, the foregoing is not intended to prohibit you from installing the
      Mobile App on another device on which you also agreed to these Terms of
      Service. Each instance of these Terms of Service that you agree to in
      connection with downloading a Mobile App grants you the aforementioned
      rights in connection with the installation and use of the Mobile App on
      one device.
    </p>
    <p className="my-4">
      <strong>Ownership; Restrictions:</strong>
      The technology and software underlying the Service or distributed in
      connection therewith are the property of Company, its affiliates, and its
      licensors (including the Mobile Apps, the “Software”). You agree not to
      copy, modify, create a derivative work of, reverse engineer, reverse
      assemble, or otherwise attempt to discover any source code, sell, assign,
      sublicense, or otherwise transfer any right in the Software. Any rights
      not expressly granted herein are reserved by Company.<strong></strong>
    </p>
    <p className="my-4">
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Special Notice for International Use; Export Controls: </strong>
      Company is headquartered in the United States. If you access or use the
      Service from outside of the United States, you do so at your own risk.
      Whether inside or outside of the United States, you are solely responsible
      for ensuring compliance with the laws of your specific jurisdiction.
      Software available in connection with the Service and the transmission of
      applicable data, if any, is subject to United States export controls. No
      Software may be downloaded from the Service or otherwise exported or
      re-exported in violation of U.S. export laws. Downloading or using the
      Software is at your sole risk.
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>Third-Party Distribution Channels:</strong>
      Company offers Software that may be made available through the Apple App
      Store, the Google Play Store, or other distribution channels
      (“Distribution Channels”). If you obtain such Software through a
      Distribution Channel, you may be subject to additional terms of the
      Distribution Channel. These Terms of Service are between you and us only,
      and not with the Distribution Channel. To the extent that you utilize any
      other third-party products and services in connection with your use of the
      Service, you agree to comply with all applicable terms of any agreement
      for such third-party products and services.
    </p>
    <p className="my-4">
      <strong>
        <em>Apple-Enabled Software:</em>
      </strong>
      With respect to Mobile Apps that are made available for your use in
      connection with an Apple-branded product (the, “Apple-Enabled Software”),
      in addition to the other terms and conditions set forth in these Terms of
      Service, the following terms and conditions apply:
    </p>
    <p className="my-4">
      ● Company and you acknowledge that these Terms of Service are concluded
      between Company and you only, and not with Apple Inc. (“Apple”), and that
      as between Company and Apple, Company, not Apple, is solely responsible
      for the Apple-Enabled Software and the content thereof.
    </p>
    <p className="my-4">
      ● You may not use the Apple-Enabled Software in any manner that is in
      violation of or inconsistent with the Usage Rules set forth for
      Apple-Enabled Software in, or otherwise be in conflict with, the Apple
      Media Services Terms and Conditions.
    </p>
    <p className="my-4">
      ● Your license to use the Apple-Enabled Software is limited to a
      non-transferable license to use the Apple-Enabled Software on an iOS
      product that you own or control, as permitted by the “Usage Rules” set
      forth in the Apple Media Services Terms and Conditions, except that such
      Apple-Enabled Software may be accessed and used by other accounts
      associated with the purchaser via Apple’s Family Sharing or volume
      purchasing programs.
    </p>
    <p className="my-4">
      ● Apple has no obligation whatsoever to provide any maintenance or support
      services with respect to the Apple-Enabled Software.
    </p>
    <p className="my-4">
      ● Apple is not responsible for any product warranties, whether express or
      implied by law. In the event of any failure of the Apple-Enabled Software
      to conform to any applicable warranty, you may notify Apple, and Apple
      will refund the purchase price for the Apple-Enabled Software, if any, to
      you; and, to the maximum extent permitted by applicable law, Apple will
      have no other warranty obligation whatsoever with respect to the
      Apple-Enabled Software, or any other claims, losses, liabilities, damages,
      costs, or expenses attributable to any failure to conform to any warranty,
      which will be Company’s sole responsibility, to the extent it cannot be
      disclaimed under applicable law.
    </p>
    <p className="my-4">
      ● Company and you acknowledge that Company, not Apple, is responsible for
      addressing any claims of you or any third party relating to the
      Apple-Enabled Software or your possession and/or use of that Apple-Enabled
      Software, including: (a) product liability claims; (b) any claim that the
      Apple-Enabled Software fails to conform to any applicable legal or
      regulatory requirement; and (c) claims arising under consumer protection,
      privacy, or similar legislation.
    </p>
    <p className="my-4">
      ● In the event of any third-party claim that the Apple-Enabled Software or
      your possession and use of that Apple-Enabled Software infringes that
      third party’s intellectual property rights, as between Company and Apple,
      Company, not Apple, will be solely responsible for the investigation,
      defense, settlement, and discharge of any such intellectual property
      infringement claim.
    </p>
    <p className="my-4">
      ● You represent and warrant that (a) you are not located in a country that
      is subject to a U.S. Government embargo, or that has been designated by
      the U.S. Government as a “terrorist supporting” country; and (b) you are
      not listed on any U.S. Government list of prohibited or restricted
      parties.
    </p>
    <p className="my-4">
      ● If you have any questions, complaints, or claims with respect to the
      Apple-Enabled Software, they should be directed to Company as follows:
    </p>
    <p className="my-4">
      <strong>info@12seconds.tv</strong>
    </p>
    <p className="my-4">
      <strong>(831) 889-9215</strong>
    </p>
    <p className="my-4">
      <strong>101 Cooper Street, Santa Cruz, CA 95060</strong>
    </p>
    <p className="my-4">
      ● You must comply with applicable third-party terms of agreement when
      using the Apple-Enabled Software, <em>e.g.</em>, your wireless data
      service agreement.
    </p>
    <p className="my-4">
      ● Company and you acknowledge and agree that Apple, and Apple’s
      subsidiaries, are third-party beneficiaries of these Terms of Service with
      respect to the Apple-Enabled Software, and that, upon your acceptance of
      the terms and conditions of these Terms of Service, Apple will have the
      right (and will be deemed to have accepted the right) to enforce these
      Terms of Service against you with respect to the Apple-Enabled Software as
      a third-party beneficiary thereof.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        <em>Google-Sourced Software</em>
      </strong>
      <strong>:</strong>
      The following applies to any Mobile App you download from the Google Play
      Store (“Google-Sourced Software”): (a) you acknowledge that these Terms of
      Service are between you and Company only, and not with Google, Inc.
      (“Google”); (b) your use of Google-Sourced Software must comply with
      Google’s then-current Google Play Terms of Service; (c) Google is only a
      provider of Google Play where you obtained the Google-Sourced Software;
      (d) Company, and not Google, is solely responsible for Company’s
      Google-Sourced Software; (e) Google has no obligation or liability to you
      with respect to Google-Sourced Software or these Terms of Service; and (f)
      you acknowledge and agree that Google is a third-party beneficiary to
      these Terms of Service as it relates to Company’s Google-Sourced Software.
    </p>
    <p className="my-4">
      <strong>
        <em> </em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Intellectual Property Rights</u>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      <strong>Service Content: </strong>
      You acknowledge and agree that the Service may contain content or features
      (“Service Content”) that are protected by copyright, patent, trademark,
      trade secret, or other proprietary rights and laws. Except as expressly
      authorized by Company, you agree not to modify, copy, frame, scrape, rent,
      lease, loan, sell, distribute, or create derivative works based on the
      Service or the Service Content, in whole or in part, except that the
      foregoing does not apply to your own User Content (as defined below) that
      you upload to or make available through the Service in accordance with
      these Terms of Service. Any use of the Service or the Service Content
      other than as specifically authorized herein is strictly prohibited.
    </p>
    <p className="my-4">
      <strong>Trademarks:</strong>
      The Company name and logos are trademarks and service marks of Company
      (collectively the “Company Trademarks”). Other company, product, and
      service names and logos used and displayed via the Service may be
      trademarks or service marks of their respective owners who may or may not
      endorse or be affiliated with or connected to Company. Nothing in these
      Terms of Service or the Service should be construed as granting, by
      implication, estoppel, or otherwise, any license or right to use any of
      Company Trademarks displayed on the Service, without our prior written
      permission in each instance. All goodwill generated from the use of
      Company Trademarks will inure to our exclusive benefit.
    </p>
    <p className="my-4">
      <strong>Third-Party Material: </strong>
      Under no circumstances will Company be liable in any way for any content
      or materials of any third parties (including users), including for any
      errors or omissions in any content, or for any loss or damage of any kind
      incurred as a result of the use of any such content. You acknowledge that
      Company does not pre-screen content, but that Company and its designees
      will have the right (but not the obligation) in their sole discretion to
      refuse or remove any content that is available via the Service. Without
      limiting the foregoing, Company and its designees will have the right to
      remove any content that violates these Terms of Service or is deemed by
      Company, in its sole discretion, to be otherwise objectionable. You agree
      that you must evaluate, and bear all risks associated with, the use of any
      content, including any reliance on the accuracy, completeness, or
      usefulness of such content.
    </p>
    <p className="my-4">
      <strong>User Content: </strong>
      You represent and warrant that you own all right, title and interest in
      and to such User Content, including all copyrights and rights of publicity
      contained therein. You hereby grant Company and its affiliated companies,
      successors, and assigns a non-exclusive, worldwide, royalty-free, fully
      paid-up, transferable, sublicensable (directly and indirectly through
      multiple tiers), perpetual, and irrevocable license to copy, display,
      upload, perform, distribute, store, modify, and otherwise use your User
      Content in connection with the operation of the Service and the promotion,
      advertising or marketing of the foregoing in any form, medium or
      technology now known or later developed. You assume all risk associated
      with your User Content and the transmission of your User Content, and you
      have sole responsibility for the accuracy, quality, legality and
      appropriateness of your User Content.
    </p>
    <p className="my-4">
      You hereby authorize Company and its third-party service providers to
      derive statistical and usage data relating to your use of the Service
      (“Usage Data”). We may use Usage Data for any purpose in accordance with
      applicable law and our Privacy Policy.
    </p>
    <p className="my-4">
      Any questions, comments, suggestions, ideas, feedback, reviews, or other
      information about the Service (“Submissions”), provided by you to Company
      are non-confidential and Company will be entitled to the unrestricted use
      and dissemination of these Submissions for any purpose, commercial or
      otherwise, without acknowledgment, attribution, or compensation to you.
    </p>
    <p className="my-4">
      You acknowledge and agree that Company may preserve User Content and may
      also disclose User Content if required to do so by law or in the good
      faith belief that such preservation or disclosure is reasonably necessary
      to: (a) comply with legal process, applicable laws, or government
      requests; (b) enforce these Terms of Service; (c) respond to claims that
      any content violates the rights of third parties; or (d) protect the
      rights, property, or personal safety of Company, its users, or the public.
      You understand that the technical processing and transmission of the
      Service, including your User Content, may involve (i) transmissions over
      various networks; and (ii) changes to conform and adapt to technical
      requirements of connecting networks or devices.
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      <strong></strong>
      <strong>Copyright Complaints: </strong>
      Company respects the intellectual property of others, and we ask our users
      to do the same. If you believe that your work has been copied in a way
      that constitutes copyright infringement, or that your intellectual
      property rights have been otherwise violated, you should notify Company of
      your infringement claim in accordance with the procedure set forth below.
    </p>
    <p className="my-4">
      Company will process and investigate notices of alleged infringement and
      will take appropriate actions under the Digital Millennium Copyright Act
      (“DMCA”) and other applicable intellectual property laws with respect to
      any alleged or actual infringement. A notification of claimed copyright
      infringement should be emailed to Company’s Copyright Agent at
      info@12seconds.tv (Subject line: “DMCA Takedown Request”). You may also
      contact the Copyright Agent by mail or facsimile at:
    </p>
    <p className="my-4">
      <strong>Serendipity Systems</strong>
    </p>
    <p className="my-4">
      <strong>101 Cooper Street.,</strong>
    </p>
    <p className="my-4">
      <strong>Santa Cruz, CA 95060</strong>
    </p>
    <p className="my-4">
      To be effective, the notification must be in writing and contain the
      following information:
    </p>
    <p className="my-4">
      ● a physical or electronic signature of a person authorized to act on
      behalf of the owner of the copyright or other intellectual property
      interest that is allegedly infringed;
    </p>
    <p className="my-4">
      ● identification of the copyrighted work or other intellectual property
      that you claim has been infringed, or, if multiple copyrighted works or
      other intellectual property are covered by a single notification, a
      representative list of such works or other intellectual property;
    </p>
    <p className="my-4">
      ● identification of the content that is claimed to be infringing or to be
      the subject of infringing activity, and where the content that you claim
      is infringing is located on the Service, with enough detail that we may
      find it on the Service;
    </p>
    <p className="my-4">● your address, telephone number, and email address;</p>
    <p className="my-4">
      ● a statement by you that you have a good faith belief that the disputed
      use is not authorized by the copyright or intellectual property owner, its
      agent, or the law; and
    </p>
    <p className="my-4">
      ● a statement by you that the information in your notice is accurate and,
      under penalty of perjury, that you are the copyright or intellectual
      property owner or are authorized to act on the behalf of the owner of the
      copyright or intellectual property that is allegedly infringed.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        <em>Counter-Notice</em>
      </strong>
      : If you believe that your User Content that was removed (or to which
      access was disabled) is not infringing, or that you have the authorization
      from the copyright owner, the copyright owner’s agent, or pursuant to the
      law, to upload and use the content in your User Content, you may send a
      written counter-notice containing the following information to the
      Copyright Agent:
    </p>
    <p className="my-4">● your physical or electronic signature;</p>
    <p className="my-4">
      ● identification of the content that has been removed or to which access
      has been disabled and the location at which the content appeared before it
      was removed or disabled;
    </p>
    <p className="my-4">
      ● a statement by you, made under penalty of perjury, that you have a good
      faith belief that the content was removed or disabled as a result of
      mistake or a misidentification of the content to be removed or disabled;
      and
    </p>
    <p className="my-4">
      ● your name, address, telephone number, and email address, a statement
      that you consent to the jurisdiction of the federal court located within
      the Northern District of California and a statement that you will accept
      service of process from the person who provided notification of the
      alleged infringement.
    </p>
    <p className="my-4">
      If a counter-notice is received by the Copyright Agent, Company will send
      a copy of the counter-notice to the original complaining party informing
      them that Company may replace the removed content or cease disabling it
      within ten (10) business days. Unless the owner of the applicable
      copyrighted work or other intellectual property files an action seeking a
      court order against Company or the user, the removed content may be
      replaced, or access to it restored, within ten (10) to fourteen (14)
      business days or more after receipt of the counter-notice, at our sole
      discretion.
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong></strong>
      <strong>
        <em>Repeat Infringer Policy</em>
      </strong>
      : In accordance with the DMCA and other applicable law, Company has
      adopted a policy of terminating, in appropriate circumstances and at
      Company’s sole discretion, the accounts of users who are deemed to be
      repeat infringers. Company may also at its sole discretion limit access to
      the Service and/or terminate the accounts of any users who infringe any
      intellectual property rights of others, whether or not there is any repeat
      infringement.{" "}
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Third-Party Services and Websites</u>
      </strong>
    </p>
    <p className="my-4">
      The Service may provide links or other access to services, sites,
      technology, and resources that are provided or otherwise made available by
      third parties (the “Third-Party Services”). Additionally, you may enable
      or log in to the Service via various online Third-Party Services, such as
      social media and social networking services like Facebook, Twitter, Tik
      Tok, and Instagram. Your access and use of the Third-Party Services may
      also be subject to additional terms and conditions, privacy policies, or
      other agreements with such third party, and you may be required to
      authenticate to or create separate accounts to use Third-Party Services on
      the websites or via the technology platforms of their respective
      providers. Some Third-Party Services will provide us with access to
      certain information that you have provided to third parties, including
      through such Third-Party Services, and we will use, store and disclose
      such information in accordance with our Privacy Policy. For more
      information about the implications of activating Third-Party Services and
      our use, storage and disclosure of information related to you and your use
      of such Third-Party Services within the Service, please see our Privacy
      Policy. Company has no control over and is not responsible for such
      Third-Party Services, including for the accuracy, availability,
      reliability, or completeness of information shared by or available through
      Third-Party Services, or on the privacy practices of Third-Party Services.
      We encourage you to review the privacy policies of the third parties
      providing Third-Party Services prior to using such services. You, and not
      Company, will be responsible for any and all costs and charges associated
      with your use of any Third-Party Services. Company enables these
      Third-Party Services merely as a convenience and the integration or
      inclusion of such Third-Party Services does not imply an endorsement or
      recommendation. Any dealings you have with third parties while using the
      Service are between you and the third party. Company will not be
      responsible or liable, directly or indirectly, for any damage or loss
      caused or alleged to be caused by or in connection with use of or reliance
      on any Third-Party Services.
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Indemnification</u>
      </strong>
    </p>
    <p className="my-4">
      You agree to defend, indemnify, and hold harmless Company, its affiliates,
      and its and their respective officers, employees, directors, service
      providers, licensors, and agents (collectively, the “Company Parties”)
      from any and all losses, damages, expenses, including reasonable
      attorneys’ fees, rights, claims, actions of any kind, and injury
      (including death) arising out of or relating to your use of the Service,
      any User Content, your connection to the Service, your violation of these
      Terms of Service, or your violation of any rights of another.
      Notwithstanding the foregoing, you will have no obligation to indemnify or
      hold harmless any Company Party from or against any liability, losses,
      damages, or expenses incurred as a result of any action or inaction of
      such Company Party. Company will provide notice to you of any such claim,
      suit, or proceeding. Company reserves the right to assume the exclusive
      defense and control of any matter which is subject to indemnification
      under this section, and you agree to cooperate with any reasonable
      requests assisting Company’s defense of such matter. You may not settle or
      compromise any claim against the Company Parties without Company’s written
      consent. If you are a California resident, you waive California Civil Code
      Section 1542, which says: “A general release does not extend to claims
      that the creditor or releasing party does not know or suspect to exist in
      his or her favor at the time of executing the release and that, if known
      by him or her, would have materially affected his or her settlement with
      the debtor or releasing party.” If you are a resident of another
      jurisdiction, you waive any comparable statute or doctrine.
    </p>
    <p className="my-4">
      <strong>
        <u>Disclaimer of Warranties</u>
      </strong>
    </p>
    <p className="my-4">
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
      AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY PARTIES EXPRESSLY
      DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
      STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
    </p>
    <p className="my-4">
      THE COMPANY PARTIES MAKE NO WARRANTY THAT (A) THE SERVICE WILL MEET YOUR
      REQUIREMENTS; (B) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
      ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
      SERVICE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY PRODUCTS,
      SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
      THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
      <strong>
        <em></em>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>Limitation of Liability</u>
      </strong>
    </p>
    <p className="my-4">
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY PARTIES WILL NOT BE
      LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY
      DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING DAMAGES FOR LOSS OF
      GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY
      PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER
      BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE,
      RESULTING FROM: (A) THE USE OR THE INABILITY TO USE THE SERVICE; (B) THE
      COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
      GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
      RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (C)
      UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (D)
      STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (E) ANY OTHER
      MATTER RELATING TO THE SERVICE. IN NO EVENT WILL THE COMPANY PARTIES’
      TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED
      THE AMOUNT YOU HAVE PAID COMPANY IN THE LAST SIX (6) MONTHS, OR, IF
      GREATER, ONE HUNDRED DOLLARS ($100).
    </p>
    <p className="my-4">
      SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
      WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
      CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET
      FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF
      YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS
      OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE
      SERVICE.
    </p>
    <p className="my-4">
      IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
      “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO
      BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW
      JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE
      LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION WILL NOT
      AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
    </p>
    <p className="my-4">
      <strong>
        <u>Dispute Resolution By Binding Arbitration</u>
      </strong>
      <strong></strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.<u></u>
      </strong>
    </p>
    <p className="my-4">
      <strong>a. </strong>
      <strong>Agreement to Arbitrate</strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      This Dispute Resolution by Binding Arbitration section is referred to in
      these Terms of Service as the “Arbitration Agreement.” You agree that any
      and all disputes or claims that have arisen or may arise between you and
      Company, whether arising out of or relating to these Terms of Service
      (including any alleged breach thereof), the Service, any advertising, or
      any aspect of the relationship or transactions between us, will be
      resolved exclusively through final and binding arbitration, rather than a
      court, in accordance with the terms of this Arbitration Agreement, except
      that you may assert individual claims in small claims court, if your
      claims qualify. Further, this Arbitration Agreement does not preclude you
      from bringing issues to the attention of federal, state, or local
      agencies, and such agencies can, if the law allows, seek relief against us
      on your behalf. You agree that, by entering into these Terms of Service,
      you and Company are each waiving the right to a trial by jury or to
      participate in a class action. Your rights will be determined by a neutral
      arbitrator, not a judge or jury. The Federal Arbitration Act governs the
      interpretation and enforcement of this Arbitration Agreement.
      <strong></strong>
    </p>
    <p className="my-4">
      <strong>b. </strong>
      <strong>
        Prohibition of Class and Representative Actions and Non-Individualized
        Relief
      </strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      <strong>
        <em>
          YOU AND COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
          OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
          UNLESS BOTH YOU AND COMPANY AGREE OTHERWISE, THE ARBITRATOR MAY NOT
          CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY
          NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE,
          OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
          MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
          INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
          PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S),
          EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD
          PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED
          FOR THE ENFORCEABILITY OF THIS PROVISION.
        </em>
      </strong>
    </p>
    <p className="my-4">
      <strong>c. </strong>
      <strong>Pre-Arbitration Dispute Resolution</strong>
    </p>
    <p className="my-4">
      Company is always interested in resolving disputes amicably and
      efficiently, and most customer concerns can be resolved quickly and to the
      customer’s satisfaction by emailing customer support at info@12seconds.tv.
      If such efforts prove unsuccessful, a party who intends to seek
      arbitration must first send to the other, by certified mail, a written
      Notice of Dispute (“Notice”). The Notice to Company should be sent to 101
      Cooper Street, Santa Cruz, CA 95060 (“Notice Address”). The Notice must
      (i) describe the nature and basis of the claim or dispute and (ii) set
      forth the specific relief sought. If Company and you do not resolve the
      claim within sixty (60) calendar days after the Notice is received, you or
      Company may commence an arbitration proceeding. During the arbitration,
      the amount of any settlement offer made by Company or you will not be
      disclosed to the arbitrator until after the arbitrator determines the
      amount, if any, to which you or Company is entitled.
    </p>
    <p className="my-4">
      <strong>d. </strong>
      <strong>Arbitration Procedures</strong>
    </p>
    <p className="my-4">
      Arbitration will be conducted by a neutral arbitrator in accordance with
      the American Arbitration Association’s (“AAA”) rules and procedures,
      including the AAA’s Consumer Arbitration Rules (collectively, the “AAA
      Rules”), as modified by this Arbitration Agreement. For information on the
      AAA, please visit its website, http://www.adr.org. Information about the
      AAA Rules and fees for consumer disputes can be found at the AAA’s
      consumer arbitration page, https://www.adr.org/consumer. If there is any
      inconsistency between any term of the AAA Rules and any term of this
      Arbitration Agreement, the applicable terms of this Arbitration Agreement
      will control unless the arbitrator determines that the application of the
      inconsistent Arbitration Agreement terms would not result in a
      fundamentally fair arbitration. The arbitrator must also follow the
      provisions of these Terms of Service as a court would. All issues are for
      the arbitrator to decide, including issues relating to the scope,
      enforceability, and arbitrability of this Arbitration Agreement. Although
      arbitration proceedings are usually simpler and more streamlined than
      trials and other judicial proceedings, the arbitrator can award the same
      damages and relief on an individual basis that a court can award to an
      individual under these Terms of Service and applicable law. Decisions by
      the arbitrator are enforceable in court and may be overturned by a court
      only for very limited reasons.
    </p>
    <p className="my-4">
      Unless Company and you agree otherwise, any arbitration hearings will take
      place in a reasonably convenient location for both parties with due
      consideration of their ability to travel and other pertinent
      circumstances. If the parties are unable to agree on a location, the
      determination will be made by AAA. If your claim is for $10,000 or less,
      Company agrees that you may choose whether the arbitration will be
      conducted solely on the basis of documents submitted to the arbitrator,
      through a telephonic hearing, or by an in-person hearing as established by
      the AAA Rules. If your claim exceeds $10,000, the right to a hearing will
      be determined by the AAA Rules. Regardless of the manner in which the
      arbitration is conducted, the arbitrator will issue a reasoned written
      decision sufficient to explain the essential findings and conclusions on
      which the award is based.
    </p>
    <p className="my-4">
      <strong>e. </strong>
      <strong>Costs of Arbitration</strong>
    </p>
    <p className="my-4">
      Payment of all filing, administration, and arbitrator fees (collectively,
      the “Arbitration Fees”) will be governed by the AAA Rules, unless
      otherwise provided in this Arbitration Agreement. If the value of the
      relief sought is $75,000 or less, at your request, Company will pay all
      Arbitration Fees. If the value of relief sought is more than $75,000 and
      you are able to demonstrate to the arbitrator that you are economically
      unable to pay your portion of the Arbitration Fees or if the arbitrator
      otherwise determines for any reason that you should not be required to pay
      your portion of the Arbitration Fees, Company will pay your portion of
      such fees. In addition, if you demonstrate to the arbitrator that the
      costs of arbitration will be prohibitive as compared to the costs of
      litigation, Company will pay as much of the Arbitration Fees as the
      arbitrator deems necessary to prevent the arbitration from being
      cost-prohibitive. Any payment of attorneys’ fees will be governed by the
      AAA Rules.
    </p>
    <p className="my-4">
      <strong>f. </strong>
      <strong>Confidentiality</strong>
    </p>
    <p className="my-4">
      All aspects of the arbitration proceeding, and any ruling, decision, or
      award by the arbitrator, will be strictly confidential for the benefit of
      all parties.
    </p>
    <p className="my-4">
      <strong>g. </strong>
      <strong>Severability</strong>
    </p>
    <p className="my-4">
      If a court or the arbitrator decides that any term or provision of this
      Arbitration Agreement (other than the subsection (b) above titled
      “Prohibition of Class and Representative Actions and Non-Individualized
      Relief” above) is invalid or unenforceable, the parties agree to replace
      such term or provision with a term or provision that is valid and
      enforceable and that comes closest to expressing the intention of the
      invalid or unenforceable term or provision, and this Arbitration Agreement
      will be enforceable as so modified. If a court or the arbitrator decides
      that any of the provisions of subsection (b) above titled “Prohibition of
      Class and Representative Actions and Non-Individualized Relief” are
      invalid or unenforceable, then the entirety of this Arbitration Agreement
      will be null and void, unless such provisions are deemed to be invalid or
      unenforceable solely with respect to claims for public injunctive relief.
      The remainder of these Terms of Service will continue to apply.
    </p>
    <p className="my-4">
      <strong>h. </strong>
      <strong>Future Changes to Arbitration Agreement</strong>
    </p>
    <p className="my-4">
      <strong> </strong>
    </p>
    <p className="my-4">
      Notwithstanding any provision in these Terms of Service to the contrary,
      Company agrees that if it makes any future change to this Arbitration
      Agreement (other than a change to the Notice Address) while you are a user
      of the Service, you may reject any such change by sending Company written
      notice within thirty (30) calendar days of the change to the Notice
      Address provided above. By rejecting any future change, you are agreeing
      that you will arbitrate any dispute between us in accordance with the
      language of this Arbitration Agreement as of the date you first accepted
      these Terms of Service (or accepted any subsequent changes to these Terms
      of Service).
    </p>
    <p className="my-4">
      <strong>
        <u>Termination</u>
      </strong>
    </p>
    <p className="my-4">
      You agree that Company, in its sole discretion, may suspend or terminate
      your account (or any part thereof) or use of the Service and remove and
      discard any content within the Service, for any reason, including for lack
      of use or if Company believes that you have violated or acted
      inconsistently with the letter or spirit of these Terms of Service. Any
      suspected fraudulent, abusive, or illegal activity that may be grounds for
      termination of your use of the Service, may be referred to appropriate law
      enforcement authorities. Company may also in its sole discretion and at
      any time discontinue providing the Service, or any part thereof, with or
      without notice. You agree that any termination of your access to the
      Service under any provision of these Terms of Service may be effected
      without prior notice, and acknowledge and agree that Company may
      immediately deactivate or delete your account and all related information
      and files in your account and/or bar any further access to such files or
      the Service. Further, you agree that Company will not be liable to you or
      any third party for any termination of your access to the Service.
    </p>
    <p className="my-4">
      <strong>
        <u>User Disputes</u>
      </strong>
    </p>
    <p className="my-4">
      You agree that you are solely responsible for your interactions with any
      other user in connection with the Service, and Company will have no
      liability or responsibility with respect thereto. Company reserves the
      right, but has no obligation, to become involved in any way with disputes
      between you and any other user of the Service.
    </p>
    <p className="my-4">
      <strong>
        <u>General</u>
      </strong>
    </p>
    <p className="my-4">
      These Terms of Service (together with the terms incorporated by reference
      herein) constitute the entire agreement between you and Company governing
      your access and use of the Service, and supersede any prior agreements
      between you and Company with respect to the Service. You also may be
      subject to additional terms and conditions that may apply when you use
      Third-Party Services, third-party content or third-party software. These
      Terms of Service will be governed by the laws of the State of California
      without regard to its conflict of law provisions. With respect to any
      disputes or claims not subject to arbitration, as set forth above, you and
      Company submit to the personal and exclusive jurisdiction of the state and
      federal courts located within San Francisco County, California. The
      failure of Company to exercise or enforce any right or provision of these
      Terms of Service will not constitute a waiver of such right or provision.
      If any provision of these Terms of Service is found by a court of
      competent jurisdiction to be invalid, the parties nevertheless agree that
      the court should endeavor to give effect to the parties’ intentions as
      reflected in the provision, and the other provisions of these Terms of
      Service remain in full force and effect. You agree that regardless of any
      statute or law to the contrary, any claim or cause of action arising out
      of or related to use of the Service or these Terms of Service must be
      filed within one (1) year after such claim or cause of action arose or be
      forever barred. A printed version of these Terms of Service and of any
      notice given in electronic form will be admissible in judicial or
      administrative proceedings based upon or relating to these Terms of
      Service to the same extent and subject to the same conditions as other
      business documents and records originally generated and maintained in
      printed form. You may not assign these Terms of Service without the prior
      written consent of Company, but Company may assign or transfer these Terms
      of Service, in whole or in part, without restriction. The section titles
      in these Terms of Service are for convenience only and have no legal or
      contractual effect. As used in these Terms of Service, the words “include”
      and “including,” and variations thereof, will not be deemed to be terms of
      limitation, but rather will be deemed to be followed by the words “without
      limitation.” Notices to you may be made via either email or regular mail.
      The Service may also provide notices to you of changes to these Terms of
      Service or other matters by displaying notices or links to notices
      generally on the Service. Company will not be in default hereunder by
      reason of any failure or delay in the performance of its obligations where
      such failure or delay is due to civil disturbances, riot, epidemic,
      hostilities, war, terrorist attack, embargo, natural disaster, acts of
      God, flood, fire, sabotage, fluctuations or unavailability of electrical
      power, network access or equipment, or any other circumstances or causes
      beyond Company’s reasonable control. <a name="bookmark=id.30j0zll"></a>
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u>U.S. Government Restricted Rights</u>
      </strong>
    </p>
    <p className="my-4">
      <strong>
        <u> </u>
      </strong>
    </p>
    <p className="my-4">
      The Service is made available to the U.S. government with “RESTRICTED
      RIGHTS.” Use, duplication, or disclosure by the U.S. government is subject
      to the restrictions contained in 48 CFR 52.227-19 and 48 CFR 252.227-7013
      et seq. or its successor. Access or use of the Service (including the
      Software) by the U.S. government constitutes acknowledgement of our
      proprietary rights in the Service (including the Software).
    </p>
    <p className="my-4">
      <strong>
        <u>Questions? Concerns? Suggestions?</u>
      </strong>
    </p>
    <p className="my-4">
      <strong> </strong>
      <br />
      Please contact us at <u>info@12seconds.tv</u> to report any violations of
      these Terms of Service or to pose any questions regarding these Terms of
      Service or the Service.
    </p>
    <p className="my-4" align="center">
      * * *
    </p>
  </div>
);

export default Terms;
