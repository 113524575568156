// Loads the prompt and then decides if we'll use portrait or landscape player based on prompt.Version
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { setPromptAsWatched } from "../../api";
import { AppContext } from "../../contexts/appContext";
import { queryPrompt } from "../../queries";
import InlinePlayerLandscape from "./InlinePlayerLandscape";
import InlinePlayerPortrait from "./InlinePlayerPortrait";

const InlinePlayer = ({ promptId, onClose, openComments }) => {
  const [, setLocation] = useLocation();
  const { displayError } = useContext(AppContext);
  const queryClient = useQueryClient();

  const { error: queryPromptError, data: prompt } = useQuery(
    ["queryPrompt", { promptId }],
    queryPrompt
  );

  const onCloseWithInvalidateQueries = useCallback(() => {
    // Refresh prompts so we see the new view count.
    if (prompt && !prompt.Watched) {
      const groupId = prompt.GroupId;
      queryClient.invalidateQueries("queryHomePageDedupedData");
      queryClient.invalidateQueries([
        "queryPromptHistoryForGroup",
        { groupId },
      ]);
    }
    onClose();
  }, [prompt]);

  useEffect(async () => {
    if (prompt && !prompt.Watched) {
      await setPromptAsWatched(prompt.Id);
    }
  }, [prompt]);

  useEffect(() => {
    if (queryPromptError) {
      const status = queryPromptError?.response?.status;
      const message =
        queryPromptError?.response?.data?.Error?.Message ||
        "There was a problem loading the video.";
      if (status === 404) {
        displayError("Video not found");
        setLocation("/home");
      } else if (status === 403) {
        displayError("You don't have access to this video");
        setLocation("/home");
      } else if (message) {
        displayError(message);
        setLocation("/home");
      }
    }
  }, [queryPromptError, displayError]);

  const usePortraitPlayer = prompt?.Version === 1;

  return (
    <Fragment>
      {prompt && (
        <Fragment>
          {usePortraitPlayer ? (
            <InlinePlayerPortrait
              prompt={prompt}
              onClose={onCloseWithInvalidateQueries}
              openComments={openComments}
            />
          ) : (
            <InlinePlayerLandscape
              prompt={prompt}
              onClose={onCloseWithInvalidateQueries}
              openComments={openComments}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default InlinePlayer;
