import { FormControl, TextField } from "@material-ui/core";
import Picker from "emoji-picker-react";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { deleteGroup, leaveGroup, updateGroup } from "../../api";
import { AppContext } from "../../contexts/appContext";
import useOuterClick from "../../hooks/useOuterClick";
import { queryGroup } from "../../queries";
import ButtonPill from "../Common/ButtonPill";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import SaveControls from "../Common/SaveControls";
import Section from "../Common/Section";
import ErrorPage from "../ErrorPage/ErrorPage";

const GroupSettingsAdmin = ({ groupId }) => {
  const queryClient = useQueryClient();
  const [showPicker, setShowPicker] = useState(false);
  const [, setLocation] = useLocation();
  const { displayError, displayModal } = useContext(AppContext);
  const [notFound, setNotFound] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const [adminSaving, setAdminSaving] = useState(false);
  const [saveValid, setSaveValid] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupEmoji, setGroupEmoji] = useState("");

  const { error: queryGroupError, data: group } = useQuery(
    ["queryGroup", { groupId }],
    queryGroup
  );

  const { data: me } = useQuery("queryMe");

  // TODO Figure out general error handling for all queries..
  useEffect(() => {
    if (queryGroupError) {
      if (queryGroupError.response.status === 404) {
        setNotFound(true);
      } else {
        displayError(queryGroupError.response.data.Error.Message);
      }
    }
  }, [queryGroupError, setNotFound, displayError]);

  useEffect(() => {
    setGroupName(group?.Name || "");
    setGroupEmoji(group?.Emojicon || "");
    setSaving(false); // Reset this in case the load was due to name/emoji change
    setAdminSaving(false);
  }, [group]);

  const onEmojiClick = (event, emojiObject) => {
    setShowPicker(false);
    setGroupEmoji(emojiObject.emoji);
  };

  useEffect(() => {
    setShowSaveButton(
      groupName !== group?.Name || groupEmoji !== group?.Emojicon
    );
    setSaveValid(groupName.trim() !== "" && groupEmoji.trim() !== "");
  }, [groupName, groupEmoji, group]);

  const doDeleteGroup = useCallback(async () => {
    const response = await deleteGroup(group.Id);
    if (response.data.Success) {
      queryClient.invalidateQueries("queryGroups"); // Cause Groups query to refresh
      setLocation("/teams");
    } else {
      displayError(response.data.Error.Message);
    }
  }, [group]);

  const askDeleteGroup = useCallback(() => {
    displayModal(
      `Delete ${group?.Emojicon} ${group?.Name}?`,
      "Are you sure you want to delete your team? This action cannot be undone.",
      doDeleteGroup,
      "Yes, delete team"
    );
  }, [group, deleteGroup]);

  const handleSave = async (event) => {
    setSaving(true);
    await updateGroup(group.Id, {
      Name: groupName.trim(),
      Emojicon: groupEmoji,
    }).catch((err, response) => {
      displayError(err.response.data.Error.Message);
    });
    queryClient.invalidateQueries("queryGroup");
    // useEffect for [group] will reset the save button
  };

  const handleCancelSave = async (event) => {
    setGroupName(group?.Name);
    setGroupEmoji(group?.Emojicon);
  };

  const innerRef = useOuterClick((ev) => {
    setShowPicker(false);
  });

  const askLeaveGroup = useCallback(() => {
    displayModal(
      `Leave ${group.Name}?`,
      <div className="w-72"></div>,
      () => doLeaveGroup(group),
      "Yes, Leave"
    );
  }, [group]);

  const doLeaveGroup = useCallback(async () => {
    try {
      await leaveGroup(group.Id);
      displayModal(
        "You've left the team.",
        `You're no longer a member of the ${group.Name} team.`
      );
    } catch (err) {
      // TODO catch other exceptions
      const message = err.response.data.Error.Message;
      displayError(message);
    }
    queryClient.invalidateQueries("queryGroups");
  }, [group]);

  if (notFound) {
    return <ErrorPage code="404" message="Page not found" />;
  }

  return (
    <Fragment>
      {group && (
        <PageColumnContainer>
          <PageColumn>
            <Section label="Team Name and Emoji">
              <div className="flex w-full" ref={innerRef}>
                {showPicker && (
                  <div className="absolute z-10">
                    <Picker onEmojiClick={onEmojiClick} />
                  </div>
                )}
                <div
                  className="text-4xl p-2 mr-4 border cursor-pointer rounded h-14 w-14"
                  onClick={(e) => setShowPicker(true)}
                >
                  {groupEmoji}
                </div>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Team name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    // error={!!error}
                    // helperText={error ? error.message : null}
                  />
                </FormControl>
              </div>
              <div className="mb-4">
                <SaveControls
                  show={showSaveButton}
                  onSave={handleSave}
                  onCancel={handleCancelSave}
                  saving={saving}
                  saveEnabled={saveValid}
                />
              </div>
            </Section>

            <Section label="Actions">
              <ButtonPill onClick={askDeleteGroup} bgColor="red">
                Delete Team
              </ButtonPill>
              {/* <ButtonPill onClick={askLeaveGroup} bgColor="red">
                Leave Team
              </ButtonPill> */}
            </Section>
          </PageColumn>
        </PageColumnContainer>
      )}
    </Fragment>
  );
};

export default GroupSettingsAdmin;
