// If children are passed, rendered on the right side of the header, for example buttons

const SectionHeader = ({ label, children }) => (
  <div className="flex flex-row justify-between">
    <div className="pb-2 mb-4 text-md sm:text-lg font-bold text-lightGray-1 select-none">
      {label}
    </div>
    {children && <div>{children}</div>}
  </div>
);

export default SectionHeader;
