// Shows either a spinner or a proress bar

import classnames from "classnames";
import { Line } from "rc-progress";

import puffBlack from "../images/puff-black.svg";
import puff from "../images/puff.svg";
import FixedImg from "./Common/FixedImg";

const Spinner = ({
  text,
  yellowScreen = false,
  showProgress = false,
  progressPercent,
}) => {
  const classes = classnames(
    "absolute top-0 z-50 w-screen h-screen",
    "flex flex-col items-center justify-center",
    { "bg-opacity-90 bg-black text-yellow": !yellowScreen },
    { "bg-yellow text-black": yellowScreen }
  );
  const imgSrc = yellowScreen ? puffBlack : puff;
  const progessColor = yellowScreen ? "#000000" : "#FFF200";
  return (
    <div className={classes}>
      {!showProgress && <FixedImg src={imgSrc} alt="Loading" />}
      {text && <div className="p-8">{text}</div>}
      {showProgress && (
        <div className="w-72">
          <Line
            percent={progressPercent}
            strokeWidth="2"
            strokeColor={progessColor}
            trailColor="#00000000"
          />
        </div>
      )}
    </div>
  );
};

export default Spinner;
