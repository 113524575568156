import icon from "../../images/12-black.svg";

const Privacy = () => (
  <div className="p-12">
    <div className="mb-8">
      <a href="/">
        <img className="h-32" src={icon} alt="12seconds" />
      </a>
    </div>
    <p className="my-4" align="center">
      <strong>SERENDIPITY SYSTEMS INC.</strong>
    </p>
    <p className="my-4" align="center">
      <strong>PRIVACY POLICY</strong>
    </p>
    <p className="my-4">Last Updated June 08 2021</p>
    <p className="my-4">
      Serendipity Systems Inc. ("<strong>Company</strong>," "<strong>we</strong>
      ," "<strong>us</strong>," or "<strong>our</strong>") has prepared this
      Privacy Policy to explain what personal information we collect, how we use
      and share that information, and your choices concerning our information
      practices in connection with our website (the "<strong>Site</strong>"),
      our mobile application (the "<strong>App</strong>"), and the multimedia
      communication services made available through the Site and the App
      (collectively, including the Site, the "<strong>Service</strong>").
    </p>
    <p className="my-4">
      This Privacy Policy explains what personal information we collect, how we
      use and share that information, and your choices concerning our
      information practices. This Privacy Policy is incorporated into and forms
      part of our <a href="/tos">Terms of Service</a>.
    </p>
    <p className="my-4">
      Before using the Service or submitting any personal information to us,
      please review this Privacy Policy carefully and contact us if you have any
      questions. By using the Service, you agree to the practices described in
      this Privacy Policy. If you do not agree to this Privacy Policy, please do
      not access the Site or otherwise use the Service.
    </p>
    <p className="my-4">
      <strong>1. </strong>
      <strong>PERSONAL INFORMATION WE COLLECT</strong>
    </p>
    <p className="my-4">
      <strong>Personal Information You Provide:</strong>
      We collect the following categories of personal information from you when
      you interact with and/or use the Service:
    </p>
    <p className="my-4">
      &#9679; <strong>Identification Information:</strong> We collect your name,
      email address and phone number.
    </p>
    <p className="my-4">
      &#9679; <strong>Financial Information:</strong> Our payment processor(s)
      will collect the financial information necessary to process your payments,
      such as your payment card number and authentication details. Please note,
      however, that we store only a tokenized version of such information and do
      not maintain payment card information on our servers.
    </p>
    <p className="my-4">
      &#9679; <strong>Communication Information: </strong>We may collect
      information when you contact us with questions or concerns and when you
      voluntarily respond to questionnaires, surveys or requests for market
      research seeking your opinion and feedback. Providing communication
      information is optional to you.
    </p>
    <p className="my-4">
      &#9679; <strong>Commercial Information:</strong> We may retain a history
      of the products you browse and/or purchase using the Service.
    </p>
    <p className="my-4">
      &#9679; <strong>Social Media Information:</strong> We have pages on social
      media sites like Instagram, Facebook, Medium, Twitter, and LinkedIn ("{" "}
      <strong>Social Media Pages</strong>"). When you interact with our Social
      Media Pages, we will collect personal information that you elect to
      provide to us, such as your contact details. In addition, the companies
      that host our Social Media Pages may provide us with aggregate information
      and analytics regarding the use of our Social Media Pages.
    </p>
    <p className="my-4">
      <strong>Internet Activity Information:</strong>
      When you visit, use, and interact with the Service, we may receive certain
      information about your visit, use, or interactions. For example, we may
      monitor the number of people that visit the Service, peak hours of visits,
      which page(s) are visited, the domains our visitors come from (e.g.,
      google.com, yahoo.com, etc.), and which browsers people use to access the
      Service (e.g., Chrome, Firefox, Microsoft Internet Explorer, etc.), broad
      geographical information, and navigation pattern. In particular, the
      following information is created and automatically logged in our systems:
    </p>
    <p className="my-4">
      &#9679; <strong>Log Information</strong>: Information that your browser
      automatically sends whenever you visit the Site. Log Information includes
      your Internet Protocol address, browser type and settings, the date and
      time of your request, and how you interacted with the Site.
    </p>
    <p className="my-4">
      &#9679; <strong>Cookies Information:</strong> Please see the "Cookies"
      section below to learn more about how we use cookies.
    </p>
    <p className="my-4">
      &#9679; <strong>Device Information</strong>: Includes name of the device,
      operating system, and browser you are using. Information collected may
      depend on the type of device you use and its settings.
    </p>
    <p className="my-4">
      &#9679; <strong>Usage Information</strong>: We collect information about
      how you use our Service, such as the types of content that you view or
      engage with, the features you use, the actions you take, and the time,
      frequency, and duration of your activities.
    </p>
    <p className="my-4">
      &#9679; <strong>Location Information</strong>: We derive a rough estimate
      of your location from your IP address while you are using the Service and
      store your location history.
    </p>
    <p className="my-4">
      &#9679; <strong>Email Open/Click Information: </strong>We use pixels in
      our email campaigns that allow us to collect your email and IP address as
      well as the date and time you open an email or click on any links in the
      email. <strong></strong>
    </p>
    <p className="my-4">
      <a name="bookmark=id.gjdgxs"></a>
      <strong></strong>
    </p>
    <p className="my-4">
      <strong>Cookies:</strong>
      We use cookies to operate and administer our Site, gather usage data on
      our Site, and improve your experience on it. A "cookie" is a piece of
      information sent to your browser by a website you visit. Cookies can be
      stored on your computer for different periods of time. Some cookies expire
      after a certain amount of time, or upon logging out (session cookies),
      others survive after your browser is closed until a defined expiration
      date set in the cookie (as determined by the third party placing it), and
      help recognize your computer when you open your browser and browse the
      Internet again (persistent cookies). For more details on cookies please
      visit All About Cookies.
    </p>
    <p className="my-4">
      On most web browsers, you will find a "help" section on the toolbar.
      Please refer to this section for information on how to receive a
      notification when you are receiving a new cookie and how to turn cookies
      off.
    </p>
    <p className="my-4">
      Please note that if you limit the ability of websites to set cookies, you
      may be unable to access certain parts of the Site and you may not be able
      to benefit from the full functionality of the Site.
    </p>
    <p className="my-4">
      Advertising networks may use cookies to collect personal information. Most
      advertising networks offer you a way to opt out of targeted advertising.
      If you would like to find out more information, please visit the Network
      Advertising Initiative's online resources and follow the opt-out
      instructions there.
    </p>
    <p className="my-4">
      If you access the Site on your mobile device, you may not be able to
      control tracking technologies through the settings.
    </p>
    <p className="my-4">
      <strong>Analytics:</strong>
      We use Google Analytics, a web analytics service provided by Google, Inc.
      ("Google"). Google Analytics uses cookies to help us analyze how users use
      the Site and enhance your experience when you use the Site. For more
      information on how Google uses this information, click here.
    </p>
    <p className="my-4">
      <a name="_heading=h.30j0zll"></a>
      <strong>Online Tracking and Do Not Track Signals</strong>: We and our
      third party service providers, including Facebook, may use cookies,
      pixels, or other tracking technologies to collect information about your
      browsing activities over time and across different websites following your
      use of the Site and use that information to send targeted advertisements.
      Our Site currently does not respond to "Do Not Track" ("DNT") signals and
      operates as described in this Privacy Policy whether or not a DNT signal
      is received. If we do respond to DNT signals in the future, we will update
      this Privacy Policy to describe how we do so.
    </p>
    <p className="my-4">
      <strong>2. </strong>
      <strong>HOW WE USE PERSONAL INFORMATION</strong>
    </p>
    <p className="my-4">
      We may use personal information for the following purposes:
    </p>
    <p className="my-4">
      &#9679; To provide you with the Service, including responding to your
      inquiries, comments, feedback, or questions;
    </p>
    <p className="my-4">
      &#9679; To send administrative information to you, for example,
      information regarding the Service and changes to our terms, conditions,
      and policies;
    </p>
    <p className="my-4">
      &#9679; To analyze how you interact with our Service;
    </p>
    <p className="my-4">&#9679; To maintain and improve the Service;</p>
    <p className="my-4">&#9679; To develop new products and services;</p>
    <p className="my-4">
      &#9679; To prevent fraud, criminal activity, or misuses of our Service,
      and to ensure the security of our IT systems, architecture, and networks;
      and
    </p>
    <p className="my-4">
      &#9679; To comply with legal obligations and legal process and to protect
      our rights, privacy, safety, or property, and/or that of our affiliates,
      you, or other third parties.
    </p>
    <p className="my-4">
      <strong>Aggregated Information.</strong>
      We may aggregate personal information and use the aggregated information
      to analyze the effectiveness of our Service, to improve and add features
      to our Service, and for other similar purposes. In addition, from time to
      time, we may analyze the general behavior and characteristics of users of
      our Service and share aggregated information like general user statistics
      with prospective business partners. We may collect aggregated information
      through the Service, through cookies, and through other means described in
      this Privacy Policy.
    </p>
    <p className="my-4">
      <strong>Marketing. </strong>
      We may use your personal information to contact you to tell you about
      products or services we believe may be of interest to you. For instance,
      if you elect to provide your email or telephone number, we may use that
      information to send you special offers. You may opt out of receiving
      emails by following the instructions contained in each promotional email
      we send you. If at any time you do not wish to receive future marketing
      communications, you may contact us. If you unsubscribe from our marketing
      lists, you will no longer receive marketing communications but we will
      continue to contact you regarding management of your account, other
      administrative matters, and to respond to your requests.
    </p>
    <p className="my-4">
      <strong>3. </strong>
      <strong>SHARING AND DISCLOSURE OF PERSONAL INFORMATION</strong>
    </p>
    <p className="my-4">
      We do not sell your personal information. In certain circumstances we may
      share the categories of personal information described above without
      further notice to you, unless required by the law, with the following
      categories of third parties:
    </p>
    <p className="my-4">
      <a name="_heading=h.1fob9te"></a>
      &#9679; <strong>Vendors and Service Providers: </strong>To assist us in
      meeting business operations needs and to perform certain services and
      functions, we may share personal information with vendors and service
      providers, including providers of hosting services, cloud services, and
      other information technology services providers, email communication
      software and email newsletter services, advertising and marketing
      services, payment processors, customer relationship management and
      customer support services, and analytics services. Pursuant to our
      instructions, these parties will access, process, or store personal
      information in the course of performing their duties to us. We take
      commercially reasonable steps to ensure our service providers adhere to
      the security standards we apply to your personal information.
    </p>
    <p className="my-4">
      &#9679; <strong>Business Transfers:</strong> If we are involved in a
      merger, acquisition, financing due diligence, reorganization, bankruptcy,
      receivership, dissolution, sale of all or a portion of our assets, or
      transition of service to another provider (collectively a "Transaction"),
      your personal information and other information may be shared in the
      diligence process with counterparties and others assisting with the
      Transaction and transferred to a successor or affiliate as part of or
      following that Transaction along with other assets.
    </p>
    <p className="my-4">
      &#9679; <strong>Legal Requirements:</strong> If required to do so by law
      or in the good faith belief that such action is necessary to (i) comply
      with a legal obligation, including to meet national security or law
      enforcement requirements, (ii) protect and defend our rights or property,
      (iii) prevent fraud, (iv) act in urgent circumstances to protect the
      personal safety of users of the Service, or the public, or (v) protect
      against legal liability.
    </p>
    <p className="my-4">
      &#9679;{" "}
      <strong>
        Affiliates:<em> </em>
      </strong>
      We may share personal information with our current and future affiliates,
      meaning an entity that controls, is controlled by, or is under common
      control with us. Our affiliates may use the personal information we share
      in a manner consistent with this Privacy Policy.
    </p>
    <p className="my-4">
      <strong>4. </strong>
      <strong>DATA RETENTION</strong>
    </p>
    <p className="my-4">
      We keep personal information for as long as reasonably necessary for the
      purposes described in this Privacy Policy, while we have a business need
      to do so, or as required by law (e.g. for tax, legal, accounting, or other
      purposes), whichever is longer.
    </p>
    <p className="my-4">
      <strong>5. </strong>
      <strong>LINKS TO OTHER WEBSITES</strong>
      <u></u>
    </p>
    <p className="my-4">
      The Service may contain links to other websites not operated or controlled
      by us, including social media services ("
      <strong>Third Party Sites</strong>
      "). The information that you share with Third Party Sites will be governed
      by the specific privacy policies and terms of service of the Third Party
      Sites and not by this Privacy Policy. By providing these links we do not
      imply that we endorse or have reviewed these sites. Please contact the
      Third Party Sites directly for information on their privacy practices and
      policies.
    </p>
    <p className="my-4">
      <a name="bookmark=id.3znysh7"></a>
      <strong>6. </strong>
      <strong>SECURITY</strong>
    </p>
    <p className="my-4">
      You use the Service at your own risk. We implement commercially reasonable
      technical, administrative, and organizational measures to protect personal
      information both online and offline from loss, misuse, and unauthorized
      access, disclosure, alteration, or destruction. However, no Internet or
      e-mail transmission is ever fully secure or error free. In particular,
      e-mail sent to or from us may not be secure. Therefore, you should take
      special care in deciding what information you send to us via the Service
      or e-mail. Please keep this in mind when disclosing any personal
      information to us via the Internet. In addition, we are not responsible
      for circumvention of any privacy settings or security measures contained
      on the Service, or third party websites.
    </p>
    <p className="my-4">
      <a name="_heading=h.2et92p0"></a>
      <strong>7. </strong>
      <strong>INTERNATIONAL USERS</strong>
    </p>
    <p className="my-4">
      The Service is based in the United States. By using our Service, you
      understand and acknowledge that your personal data will be transferred
      from your location to our facilities and servers in the United States,
      where data protection laws may differ from those in your jurisdiction.
    </p>
    <p className="my-4">
      <strong>8. </strong>
      <strong>YOUR CHOICES</strong>
    </p>
    <p className="my-4">
      In certain circumstances providing personal information is optional.
      However, if you choose not to provide personal information that is needed
      to use some features of our Service, you may be unable to use those
      features. You can also contact us to request updates or corrections to
      your personal information.
    </p>
    <p className="my-4">
      <strong>9. </strong>
      <strong>CHANGES TO THE PRIVACY POLICY</strong>
    </p>
    <p className="my-4">
      The Service and our business may change from time to time. As a result we
      may change this Privacy Policy at any time. When we do we will post an
      updated version on this page, unless another type of notice is required by
      the applicable law. By continuing to use our Service or providing us with
      personal information after we have posted an updated Privacy Policy, or
      notified you by other means if applicable, you consent to the revised
      Privacy Policy and practices described in it.
    </p>
    <p className="my-4">
      <a name="bookmark=id.3dy6vkm"></a>
      <a name="_heading=h.tyjcwt"></a>
      <strong>10. </strong>
      <strong>CONTACT US</strong>
    </p>
    <p className="my-4">
      If you have any questions about our Privacy Policy or information
      practices, please feel free to contact us at our designated request
      address: privacy@12seconds.tv.
    </p>
  </div>
);

export default Privacy;
