/*
ClosablePromptTimes and CloseablePromptScheduleDescription are
for supporting the production environment in the interim
period, before always-open prompts are rolled out to prod
*/

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import listify from "listify";

import { getGroupActiveDayBooleans } from "../../utils/groupUtils";
import FixedLink from "../Common/FixedLink";

dayjs.extend(utc);

const PromptScheduleDescription = ({
  activeDays,
  timeOpen,
  timeClose,
  scheduledLinkPath,
}) => {
  let { sun, mon, tue, wed, thu, fri, sat } = getGroupActiveDayBooleans(
    activeDays
  );
  let daysText,
    openTimeString,
    closeTimeString = "";
  let hasDays = activeDays.length != 0;
  if (hasDays) {
    if (sun && mon && tue && wed && thu && fri && sat) {
      daysText = "every day";
    } else if (!sun && mon && tue && wed && thu && fri && !sat) {
      daysText = "every weekday";
    } else if (sun && !mon && !tue && !wed && !thu && !fri && sat) {
      daysText = "every Saturday and Sunday";
    } else {
      // ActiveDays enum happens to be english words, so we can be lazy for now...
      daysText = "every " + listify(activeDays);
    }
    const openTimeParts = timeOpen.split(":").map((x) => parseInt(x));
    const closeTimeParts = timeClose.split(":").map((x) => parseInt(x));
    const open = dayjs().utc().hour(openTimeParts[0]).minute(openTimeParts[1]);
    const close = dayjs.utc().hour(closeTimeParts[0]).minute(closeTimeParts[1]);
    openTimeString = open.format("h:mma");
    closeTimeString = close.format("h:mma");
  }

  const ScheduledLink = () => {
    let scheduled = "scheduled";
    if (scheduledLinkPath) {
      scheduled = (
        <span className="text-blue underline">
          <FixedLink href={scheduledLinkPath}>scheduled</FixedLink>
        </span>
      );
    }
    return scheduled;
  };

  return (
    <span>
      {hasDays ? (
        <span>
          Prompts are <ScheduledLink /> to open at <b>{openTimeString}</b> and
          close at <b>{closeTimeString}</b> {daysText}.
        </span>
      ) : (
        <span>
          Prompts are not <ScheduledLink /> for this team!
        </span>
      )}
    </span>
  );
};

export default PromptScheduleDescription;
