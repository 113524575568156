import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "wouter";

import { queryMe } from "../queries";
import YellowScreen from "./Common/YellowScreen";
import WalkthroughProfilePhoto from "./WalkthroughProfilePhoto";

const SignUpFinishPhoto = ({ params }) => {
  const [, setLocation] = useLocation();

  const { data: me } = useQuery("queryMe", queryMe);

  useEffect(() => {
    // If user has already set photo, route to home.
    // This could happen if user hits browser back button.
    // WARNING This will break if default profile pic file name changes!!!
    if (
      me &&
      me.ProfilePhotoUrl &&
      !me.ProfilePhotoUrl.endsWith("default-profile.png")
    ) {
      setLocation("/home");
    }
  }, [me]);

  return (
    <YellowScreen>
      <WalkthroughProfilePhoto
        handleStepFinished={() => setLocation("/home")}
      />
    </YellowScreen>
  );
};

export default SignUpFinishPhoto;
