// Communication with parent apps (e.g. mobile apps) can be done through this hook.

import util from "util";

import { useCallback, useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { registerDevice } from "../api";
import { ParentAppContext } from "../contexts/parentAppContext";

const DEBUG_PARENT_APP = false; // Log what is send/received

const sendMessageToParentApp = (data) => {
  // Parent app will have installed this function on window.
  if (DEBUG_PARENT_APP) console.log("WEB APP SENDING: ", data);
  if (typeof window.parentApp?.sendMessage === "function") {
    window.parentApp.sendMessage(data);
  }
};

const ParentAppProvider = ({ children }) => {
  const hasParentApp = !!window.parentApp;
  const [
    hasParentAppMediaPermissions,
    setHasParentAppMediaPermissions,
  ] = useState(false);
  const [
    parentAppDeclinedMediaPermissions,
    setParentAppDeclinedMediaPermissions,
  ] = useState(false);
  const [
    isAcceptingAndroidNotifications,
    setIsAcceptingAndroidNotifications,
  ] = useState(false);
  const [didRegisterNotifications, setDidRegisterNotifications] = useState(
    false
  );
  const [location, setLocation] = useLocation();
  const [parentAppIsReady, setParentAppIsReady] = useState(!hasParentApp);
  const [parentAppRotation, setParentAppRotation] = useState("NONE");
  const [requestingMediaPermissions, setRequestingMediaPermissions] = useState(
    false
  );
  const [
    didTriggerNotificationsRegistration,
    setDidTriggerNotificationsRegistration,
  ] = useState(false);
  const queryClient = useQueryClient(); // for causing "queryHomePageDedupedData" to refresh

  useEffect(() => {
    if (hasParentApp) {
      // This delay is a hack to get around the WKWebKit issue of SafeAreaInsets not
      // being immediately availabe. 10ms is not enough time for the safeareas. 50 seems to be.
      // Currently used by YellowScreen to do a fade in, and we should also do this for
      // logged in screens. TODO.
      setTimeout(() => setParentAppIsReady(true), 100);
    }
  }, []);

  useEffect(() => {
    if (hasParentApp) {
      sendMessageToParentApp({
        method: "locationDidChange",
        location,
      });
    }
  }, [location]);

  const logoutParentApp = () => {
    // allow this to be triggered again...
    setDidTriggerNotificationsRegistration(false);
  };

  const triggerNotificationRegistration = useCallback(() => {
    if (!didTriggerNotificationsRegistration) {
      // ^ App.js tells us to do this on every "me" load.  We
      // actually just need to do it on the first one.
      // Me gets refreshed on settings changes.
      setDidTriggerNotificationsRegistration(true);
      sendMessageToParentApp({
        method: "registerNotifications",
      });
    }
  }, [didTriggerNotificationsRegistration]);

  const parentAppSetShowStatusBar = (show) => {
    sendMessageToParentApp({
      method: "setShowStatusBar",
      show: show ? "true" : "false",
    });
  };

  const requestParentAppMediaPermissions = useCallback(() => {
    setRequestingMediaPermissions(true);
    if (!requestingMediaPermissions) {
      // ^ IMPORTANT! Don't allow this to be called twice on some re-render fluke...
      // Prompt.js is wanting to call this twice :/, not sure yet why-- maybe a
      // rerender happens as iOS permissions dialog starts to dismiss?
      // A second call causes iOS app to quickly reply we have permissions before
      // the iOS media permissions dialog dismisses, and then the webapp tries
      // to present an ask for web cam/video permission over a dismissing dialog,
      // which means that ask never gets shown.  But this flag fixes it.
      sendMessageToParentApp({
        method: "ensureMediaPermissions",
      });
    }
  }, [requestingMediaPermissions]);

  const openParentAppNotificationsScreen = () => {
    sendMessageToParentApp({
      method: "openNotificationsScreen",
    });
  };

  const receiveParentAppEvent = useCallback(async (event) => {
    const detail = event.detail;
    if (DEBUG_PARENT_APP) console.log("WEB APP RECEIVING:", detail.method);
    switch (detail.method) {
      case "setLocation":
        setLocation(detail.location);
        break;
      case "isAcceptingAndroidNotifications":
        setIsAcceptingAndroidNotifications(detail.value);
        break;
      case "didRegisterNotifications":
        delete detail.method; // Everything is in the format we like, but don't want "method"
        try {
          await registerDevice(detail);
        } catch (e) {
          console.log(
            "Error registering push: ",
            util.inspect(e, { depth: 5 })
          );
        }
        setDidRegisterNotifications(true);
        break;
      case "didDeclineNotifications":
        setDidRegisterNotifications(false);
        break;
      case "didReceiveNotification":
        // Cause Home to refresh data...
        queryClient.invalidateQueries("queryHomePageDedupedData");
        break;
      case "didGetMediaPermissions":
        setRequestingMediaPermissions(false);
        setParentAppDeclinedMediaPermissions(false);
        setHasParentAppMediaPermissions(true);
        break;
      case "didDeclineMediaPermissions":
        setRequestingMediaPermissions(false);
        setParentAppDeclinedMediaPermissions(true);
        setHasParentAppMediaPermissions(false);
        break;
      case "didRotate":
        // "LEFT|RIGHT|NONE"
        setParentAppRotation(detail.rotation);
        break;
      default:
        console.warn(
          "REACT APP DID NOT RECOGNIZE MESSAGE FROM PARENT APP:",
          detail
        );
    }
  }, []);

  useEffect(() => {
    if (window.parentApp) {
      window.addEventListener("ParentAppEvent", receiveParentAppEvent);
      sendMessageToParentApp({ method: "ready" });
      return () => {
        window.removeEventListener("ParentAppEvent", receiveParentAppEvent);
      };
    }
  }, [receiveParentAppEvent]);

  return (
    <ParentAppContext.Provider
      value={{
        hasParentApp,
        parentAppIsReady, // Currently just an internal timer to allow SafeAreaInsets to be loaded.
        logoutParentApp,
        triggerNotificationRegistration,
        openParentAppNotificationsScreen,
        requestParentAppMediaPermissions,
        parentAppDidRegisterNotifications: didRegisterNotifications,
        parentAppHasNotificationsOn: isAndroid
          ? isAcceptingAndroidNotifications
          : didRegisterNotifications,
        hasParentAppMediaPermissions,
        parentAppDeclinedMediaPermissions,
        parentAppNeedsMediaPermissions:
          hasParentApp && !hasParentAppMediaPermissions,
        parentAppRotation,
        parentAppSetShowStatusBar,
      }}
    >
      {children}
    </ParentAppContext.Provider>
  );
};

export default ParentAppProvider;
