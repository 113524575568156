import { TextField } from "@material-ui/core";
import Picker from "emoji-picker-react";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { createGroup } from "../../api";
import { MAX_CHARS_GROUP_NAME } from "../../constants";
import useOuterClick from "../../hooks/useOuterClick";
import puff from "../../images/puff-black.svg";
import { randomEmoji } from "../../utils";
import FixedImg from "../Common/FixedImg";
import NavPage from "../Common/NavPage";
import PageMargin from "../Common/PageMargin";

const GroupCreate = () => {
  const queryClient = useQueryClient(); // for causing "queryMe" to refresh
  const [submitting, setSubmitting] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, control } = useForm();
  const [chosenEmoji, setChosenEmoji] = useState(randomEmoji());
  const [, setLocation] = useLocation();

  const onEmojiClick = (event, emojiObject) => {
    setShowPicker(false);
    setChosenEmoji(emojiObject.emoji);
  };

  const onSubmit = async (values) => {
    setSubmitting(true);
    const result = await createGroup(values.name, chosenEmoji).catch(
      (error) => {
        setError(error);
        setSubmitting(false);
      }
    );

    if (result) {
      // redirect to group page (not admin for now)
      setSubmitting(false);
      queryClient.invalidateQueries("queryGroups"); // Refresh Groups
      const group = result.data.Data;
      setLocation(`/teams/${group.Id}/members`);
    }
  };

  const innerRef = useOuterClick((ev) => {
    setShowPicker(false);
  });

  if (submitting)
    return (
      <div className="flex min-h-screen min-w-screen bg-yellow items-center justify-center">
        <FixedImg src={puff} alt="Loading" />
      </div>
    );

  return (
    <NavPage
      title="Create New Team"
      showBackButton={true}
      backLocation="/teams"
      backButtonText="Teams"
    >
      <div className="flex flex-col items-center justify-center pb-10 px-3">
        <div className="text-center sm:w-96 pb-4">
          <span className="font-bold">12seconds</span> allows you to make
          collaborative videos with a team. After creating your team, you will
          be able to send invitation links.
        </div>
      </div>
      <div className="flex flex-col items-center">
        {error ? <div>{error.message}</div> : <Fragment />}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-4 sm:w-1/2 w-3/4"
        >
          <div className="flex items-center justify-center mb-4" ref={innerRef}>
            {showPicker ? (
              <div className="absolute mt-32 z-20">
                {/* ^ Need mt-32 or it goes under header */}
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            ) : (
              <Fragment />
            )}
            <div
              className="text-3xl h-16 w-16 p-4 mr-4 bg-lightGray-5 rounded-full cursor-pointer"
              onClick={(e) => setShowPicker(true)}
            >
              {chosenEmoji}
            </div>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Team name required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Team name"
                  variant="filled"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ maxLength: MAX_CHARS_GROUP_NAME }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>
          <div className="flex flex-col items-center justify-center px-3">
            <div className="text-center sm:w-96 pb-4">
              Name your team and choose its emoji icon
            </div>
          </div>
          <div className="text-center">
            <button
              className="px-16 py-4 bg-black text-white uppercase font-bold rounded-full"
              type="submit"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </NavPage>
  );
};

export default GroupCreate;
