import React, { useContext, useEffect } from "react";

import { AppContext } from "../contexts/appContext";
import Spinner from "./Spinner";

const Logout = () => {
  const { logout } = useContext(AppContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return <Spinner yellowScreen={true} />;
};

export default Logout;
