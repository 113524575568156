import useSafeAreaInsets from "../../hooks/useSafeAreaInsets";

// Used for filling the bottom safe area inset (e.g. iOS "Home indicator") */
const SafeAreaInsetBottom = ({ bgColor = "transparent" }) => {
  const { safeAreaInsetBottom } = useSafeAreaInsets();
  return (
    <div
      // Unexpected, but mobile safari is letting empty div area get selected!? So, select-none.
      className={`bg-${bgColor} select-none`}
      style={{ height: safeAreaInsetBottom }}
    />
  );
};

export default SafeAreaInsetBottom;
