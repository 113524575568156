import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const utcDateStringsForTimestamp = (timestamp) => {
  const date = dayjs.unix(timestamp).utc();
  return {
    weekday: date.format("dddd"),
    mediumDate: date.format("MMM D"),
    longDate: date.format("MMMM D, YYYY"),
  };
};

export const isPromptEarly = (prompt) => {
  const closesAt = dayjs.unix(prompt.ClosesAt);
  const now = dayjs();
  return closesAt.date() !== now.date(); // Compares day of the month e.g 28
};

export const getLocalWeekdayPromptCloses = (prompt) => {
  return dayjs.unix(prompt.ClosesAt).utc().format("dddd");
};
