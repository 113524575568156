import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import listify from "listify";
import { Fragment } from "react";
import { useEffect, useState } from "react";

import { getGroupActiveDayBooleans } from "../../utils/groupUtils";
import FixedLink from "../Common/FixedLink";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const PromptScheduleDescription = ({
  activeDays,
  timeClose,
  timeZone,
  scheduledLinkPath,
  verbose = false,
  concise = false,
}) => {
  const [hasDays, setHasDays] = useState(false);
  const [daysText, setDaysText] = useState("");
  const [closeTimeStringGroup, setCloseTimeStringGroup] = useState("");
  const [groupTimeZone, setGroupTimeZone] = useState(""); // e.g. "Brazilian Standard Time"
  const [closeTimeStringLocal, setCloseTimeStringLocal] = useState("");

  useEffect(() => {
    let { sun, mon, tue, wed, thu, fri, sat } = getGroupActiveDayBooleans(
      activeDays
    );
    let daysText = "";
    let hasDays = activeDays.length != 0;
    setHasDays(hasDays);
    if (hasDays) {
      if (sun && mon && tue && wed && thu && fri && sat) {
        daysText = "every day";
      } else if (!sun && mon && tue && wed && thu && fri && !sat) {
        daysText = "every weekday";
      } else if (sun && !mon && !tue && !wed && !thu && !fri && sat) {
        daysText = "every Saturday and Sunday";
      } else {
        // ActiveDays enum happens to be english words, so we can be lazy for now...
        daysText = "every " + listify(activeDays);
      }
      setDaysText(daysText);
      let closeTimeStringLocal,
        closeTimeStringGroup = "";
      const closeTimeParts = timeClose.split(":").map((x) => parseInt(x));
      closeTimeStringLocal = dayjs()
        .tz(timeZone)
        .hour(closeTimeParts[0])
        .minute(closeTimeParts[1])
        .local()
        .format("h:mm A")
        .replace(":00", "");
      closeTimeStringGroup = dayjs()
        .tz(timeZone)
        .hour(closeTimeParts[0])
        .minute(closeTimeParts[1])
        .format("h:mm A")
        .replace(":00", "");
      setGroupTimeZone(
        dayjs()
          .tz(timeZone)
          .hour(closeTimeParts[0])
          .minute(closeTimeParts[1])
          .format("zzz")
      );
      setCloseTimeStringLocal(closeTimeStringLocal);
      setCloseTimeStringGroup(closeTimeStringGroup);
    }
  }, []);

  const ScheduledLink = () => {
    let scheduled = "scheduled";
    if (scheduledLinkPath) {
      scheduled = (
        <span className="text-blue underline">
          <FixedLink href={scheduledLinkPath}>scheduled</FixedLink>
        </span>
      );
    }
    return scheduled;
  };

  const localTimeIsDifferent = closeTimeStringGroup != closeTimeStringLocal;

  if (!hasDays) {
    return (
      <span>
        Prompts are not <ScheduledLink /> for this team!
      </span>
    );
  } else if (concise) {
    // Concise is used for Groups List
    return (
      <span>
        New videos {daysText} at <b>{closeTimeStringLocal}</b>!
      </span>
    );
  } else if (verbose) {
    // Verbose is used for settings confirmation dialog
    return (
      <Fragment>
        <span>
          Team videos are <ScheduledLink /> to compile {daysText} at{" "}
          <b>{closeTimeStringGroup}</b> {groupTimeZone}.
        </span>
        {localTimeIsDifferent && (
          <span>
            {" "}
            This is currently <b>{closeTimeStringLocal}</b> local time for you.
          </span>
        )}
      </Fragment>
    );
  } else {
    // Default is used for GroupHome
    return (
      <Fragment>
        <span>
          Team videos are <ScheduledLink /> to compile {daysText} at{" "}
          <b>{closeTimeStringGroup}</b> {groupTimeZone}.
        </span>
        {localTimeIsDifferent && (
          <span>
            {" "}
            This is <b>{closeTimeStringLocal}</b> local time for you.
          </span>
        )}
      </Fragment>
    );
  }
};

export default PromptScheduleDescription;
