import { XIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { usePageVisibility } from "react-page-visibility";
import { useQuery } from "react-query";
import { useLocation } from "wouter";

import {
  getVideoUploadUrl,
  submitResponse,
  updateMe,
  uploadVideo,
} from "../api";
import { AppContext } from "../contexts/appContext";
import { MediaContext } from "../contexts/mediaContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import useRecorder from "../hooks/useRecorder";
import useSafeAreaInsets from "../hooks/useSafeAreaInsets";
import defaultProfile from "../images/default-profile.png";
import { queryGroup, queryMe, queryPrompt } from "../queries";
import FixedImg from "./Common/FixedImg";
import FixedLink from "./Common/FixedLink";
import SafeAreaInsetBottom from "./Common/SafeAreaInsetBottom";
import SafeAreaInsetTop from "./Common/SafeAreaInsetTop";
import Controls from "./Controls";
import ErrorPage from "./ErrorPage/ErrorPage";
import MicCamSelect from "./MicCamSelect";
import NeedCameraPermissions from "./NeedCameraPermissions";
import Preview from "./Preview";
import Record from "./Record";
import Spinner from "./Spinner";
import Walkthrough from "./Walkthrough";

const RECORD_SECONDS = 12;

const Prompt = ({ params }) => {
  const { displayError } = useContext(AppContext);
  const { parentAppSetShowStatusBar } = useContext(ParentAppContext);
  const { requestMediaPermission, mediaPermissionDenied } = useContext(
    MediaContext
  );

  const webcamRef = useRef(null);
  const playerRef = useRef(null);
  const {
    capturing,
    processing,
    videoBlob,
    videoUrl,
    preRecordCountdown,
    preRecordCountingDown,
    recordingCountdown,
    remainingMilliseconds,
    startRecorder,
    cancelRecorder,
  } = useRecorder(webcamRef, RECORD_SECONDS);

  const [, setLocation] = useLocation();
  const isVisible = usePageVisibility();
  const { safeAreaInsetTopNumber } = useSafeAreaInsets();
  const overlayTopPositionClass =
    safeAreaInsetTopNumber > 0 ? "top-16" : "top-10";
  const [progressPercent, setProgressPercent] = useState(0);
  const [hasCompletedTutorial, setHasCompletedTutorial] = useState(false);
  const [turorialStateDetermined, setTurorialStateDetermined] = useState(false);
  const [loading, setLoading] = useState(false);

  const [playing, setPlaying] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const { data: me } = useQuery("queryMe", queryMe);
  const { promptId } = params;
  const { error: queryPromptError, data: prompt } = useQuery(
    ["queryPrompt", { promptId }],
    queryPrompt,
    { cacheTime: 0 } // Don't cache this-- we want to instantly react if already answered or closed.
  );

  const groupId = prompt?.GroupId;
  const { data: group } = useQuery(["queryGroup", { groupId }], queryGroup, {
    enabled: !!groupId,
  });

  // Hide app status bar for prompt record screen...
  useEffect(() => {
    parentAppSetShowStatusBar(false);
    return () => parentAppSetShowStatusBar(true);
  }, []);

  // TODO Figure out general error handling for all queries..
  useEffect(() => {
    if (queryPromptError?.response) {
      if (queryPromptError.response.status === 404) {
        setNotFound(true);
      } else {
        displayError(queryPromptError.response.data.Error.Message);
      }
    } else if (queryPromptError) {
      console.log("UNEXPECTED NETWORK ERROR"); //TODO!
    }
  }, [queryPromptError]);

  useEffect(() => {
    if (me) {
      // setHasCompletedTutorial(false); //DEBUG DO NOT COMMIT!
      setHasCompletedTutorial(me["Settings"]["CompletedTutorial"]);
      // Weird. Seeing some tutorial flashing on mobile after we get me
      // but this effect hasn't yet run. Using an additional marker:
      setTurorialStateDetermined(true);
    }
  }, [me]);

  useEffect(() => {
    if (!isVisible && isMobile && (capturing || preRecordCountingDown)) {
      // If person leaves mobile app while recording, cancel the recording.
      cancelRecording();
    }
  }, [isVisible]);

  const canRecordPrompt = (p) => {
    return p && (p.CurrentPhase === "open" || p.CanSubmit);
  };

  useEffect(() => {
    if (prompt) {
      if (!canRecordPrompt(prompt)) {
        // TODO Let people know the prompt can't record?
        // Could be it just closed, or they have an old url.
        setLocation("/prompts");
      }
    }
  }, [prompt]);

  const startRecording = () => {
    setPlaying(false);
    startRecorder();
  };

  const cancelRecording = () => {
    cancelRecorder();
  };

  useEffect(() => {
    if (hasCompletedTutorial && canRecordPrompt(prompt)) {
      // if (parentAppNeedsMediaPermissions) {
      //   requestParentAppMediaPermissions();
      // } else {
      requestMediaPermission();
      // }
    }
  }, [
    prompt,
    requestMediaPermission,
    hasCompletedTutorial,
    // parentAppNeedsMediaPermissions,
  ]);

  const submitRecording = async () => {
    setProgressPercent(0);
    setPlaying(false);
    setLoading(true);
    let uploadUrlResponse;
    try {
      uploadUrlResponse = await getVideoUploadUrl(prompt.Id);
      const { url, videoId } = uploadUrlResponse.data.Data;
      await uploadVideo(url, videoBlob, setProgressPercent);
      await submitResponse(prompt.Id, videoId);
      setLocation("/prompts");
    } catch (e) {
      if (e?.response?.data?.Error?.Message) {
        // Here we have an error from our servers.
        displayError(
          e.response.data.Error.Message + " Your 12 was not submitted."
        );
      } else {
        // Here we probably have axios throwing a "Network Error",
        // as when there is no network.
        console.log(e);
        displayError(
          "There seems to be a problem with the network. Your 12 was not submitted."
        );
      }
      setLoading(false);
    }
  };

  if (notFound) {
    return <ErrorPage code="404" message="Page not found" />;
  }

  if (!turorialStateDetermined || !prompt) {
    return <Spinner />;
  }

  // if (hasCompletedTutorial && parentAppDeclinedMediaPermissions) {
  //   setLocation("/prompts");
  //   return <Spinner />;
  // }

  if (hasCompletedTutorial && mediaPermissionDenied) {
    return <NeedCameraPermissions />;
  }

  // const countdown = (
  //   <div className="h-16 w-16">
  //     <div className="absolute rounded-full bg-transparent w-16 h-16 mx-auto flex justify-items-center items-center">
  //       <div className="text-white text-center w-full font-bold text-2xl text-shadow">
  //         {preRecordCountingDown ? "" : recordingCountdown}
  //       </div>
  //     </div>
  //     <div className="absolute rounded mx-auto w-16 h-16 flex justify-items-center items-center animate-spin opacity-50">
  //       <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
  //         <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
  //           <stop stopColor="#0FEFFD" />
  //           <stop offset="0.515718" stopColor="#FF00F5" />
  //           <stop offset="0.776042" stopColor="#0FEFFD" />
  //           <stop offset="0.984375" stopColor="#FF00F5" />
  //         </linearGradient>
  //         <circle
  //           shapeRendering="optimizeQuality"
  //           r={24}
  //           cx={32}
  //           cy={32}
  //           className="external-circle"
  //           strokeWidth="5"
  //           fill="none"
  //           stroke="url(#linearColors)"
  //         ></circle>
  //       </svg>
  //     </div>
  //   </div>
  // );

  const userInfo = (
    <div className="flex">
      <FixedImg
        src={me.ProfilePhotoUrl ? me.ProfilePhotoUrl : defaultProfile}
        alt="Settings"
        className="h-11 w-11 sm:h-11 sm:w-11 rounded-full mr-2"
      />
      <div className="flex flex-col text-white text-shadow">
        <div className="text-md font-bold">{me?.Name}</div>
        <div className="text-sm">{group?.Name}</div>
      </div>
    </div>
  );

  return (
    <div className="bg-black h-screen flex">
      {loading && (
        <Spinner
          text={"Hang on a sec, we're uploading your 12."}
          showProgress={true}
          progressPercent={progressPercent}
        />
      )}
      {!hasCompletedTutorial && (
        <Walkthrough
          onComplete={() => {
            updateMe({
              Settings: { CompletedTutorial: true },
            });
            setHasCompletedTutorial(true);
          }}
        />
      )}
      <div
        className={`fixed ${overlayTopPositionClass} left-2 right-2 z-10 flex justify-between items-start select-none`}
      >
        <SafeAreaInsetTop />
        <div className="flex-1">{userInfo}</div>
        {!capturing && !preRecordCountingDown && hasCompletedTutorial && (
          <div className="flex items-center justify-center cursor-pointer -mt-1">
            <FixedLink href="/prompts">
              <XIcon className="w-8 h-8 text-white drop-shadow-md" />
            </FixedLink>
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col bg-black justify-end">
        <div className="flex-1 flex bg-black">
          {videoUrl ? (
            <Preview
              playerRef={playerRef}
              videoUrl={videoUrl}
              playing={playing}
              prompt={prompt}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              onEnded={() => setPlaying(false)}
            ></Preview>
          ) : (
            <Record
              webcamRef={webcamRef}
              preRecordCountingDown={preRecordCountingDown}
              preRecordCountdown={preRecordCountdown}
              remainingMilliseconds={remainingMilliseconds}
              maxMilliseconds={RECORD_SECONDS * 1000}
              prompt={prompt}
              capturing={capturing}
            ></Record>
          )}
        </div>
        <div className="w-screen bg-black flex-1 flex flex-col items-center justify-start">
          <div className="container px-3 py-4">
            <div className="grid grid-cols-12 items-center justify-center">
              <div className="col-start-1 col-end-3">
                {/* Wierd pixel shift (chrome bug?) occurs if containing grid div appears/disappears, so always keep it */}
                {!isMobile &&
                  !capturing &&
                  !processing &&
                  !playing &&
                  !preRecordCountingDown && <MicCamSelect />}
              </div>
              <div style={{ gridColumn: "4 / 10" }}>
                <Controls
                  preRecordCountingDown={preRecordCountingDown}
                  capturing={capturing}
                  processing={processing}
                  playing={playing}
                  videoBlob={videoBlob}
                  recordingCountdown={recordingCountdown}
                  startRecording={startRecording}
                  playRecording={() => setPlaying(true)}
                  pauseRecording={() => setPlaying(false)}
                  cancelRecording={cancelRecording}
                  submitRecording={submitRecording}
                />
              </div>
            </div>
          </div>
          <SafeAreaInsetBottom bgColor="black" />
        </div>
      </div>
    </div>
  );
};

export default Prompt;
