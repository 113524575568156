import { ChevronLeftIcon } from "@heroicons/react/solid";
import { FormControl, TextField } from "@material-ui/core";
import Picker from "emoji-picker-react";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "wouter";

import { deleteGroup, updateGroup } from "../../api";
import { AppContext } from "../../contexts/appContext";
import useOuterClick from "../../hooks/useOuterClick";
import { queryGroup } from "../../queries";
import ButtonPill from "../Common/ButtonPill";
import FixedLink from "../Common/FixedLink";
import PageBackButton from "../Common/PageBackButton";
import PageColumn from "../Common/PageColumn";
import PageColumnContainer from "../Common/PageColumnContainer";
import PageMargin from "../Common/PageMargin";
import PageTitle from "../Common/PageTitle";
import SaveControls from "../Common/SaveControls";
import Section from "../Common/Section";
import SectionHeader from "../Common/SectionHeader";
import SectionInstructions from "../Common/SectionInstructions";
import ErrorPage from "../ErrorPage/ErrorPage";
import CloseablePromptTimes from "./CloseablePromptTimes";
import FuturePrompts from "./FuturePrompts";
import GroupMembers from "./GroupMembers";
import PromptTimes from "./PromptTimes";

const GroupAdmin = ({ params }) => {
  const { groupId } = params;
  const queryClient = useQueryClient();
  const [showPicker, setShowPicker] = useState(false);
  const [, setLocation] = useLocation();
  const { displayError, displayModal } = useContext(AppContext);
  const [notFound, setNotFound] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const [adminSaving, setAdminSaving] = useState(false);
  const [saveValid, setSaveValid] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupEmoji, setGroupEmoji] = useState("");

  const { error: queryGroupError, data: group } = useQuery(
    ["queryGroup", { groupId }],
    queryGroup
  );

  const { data: me } = useQuery("queryMe");

  // TODO Figure out general error handling for all queries..
  useEffect(() => {
    if (queryGroupError) {
      if (queryGroupError.response.status === 404) {
        setNotFound(true);
      } else {
        displayError(queryGroupError.response.data.Error.Message);
      }
    }
  }, [queryGroupError, setNotFound, displayError]);

  useEffect(() => {
    setGroupName(group?.Name || "");
    setGroupEmoji(group?.Emojicon || "");
    setSaving(false); // Reset this in case the load was due to name/emoji change
    setAdminSaving(false);
  }, [group]);

  const onEmojiClick = (event, emojiObject) => {
    setShowPicker(false);
    setGroupEmoji(emojiObject.emoji);
  };

  useEffect(() => {
    setShowSaveButton(
      groupName !== group?.Name || groupEmoji !== group?.Emojicon
    );
    setSaveValid(groupName.trim() !== "" && groupEmoji.trim() !== "");
  }, [groupName, groupEmoji, group]);

  const deleteTeam = useCallback(async () => {
    const response = await deleteGroup(group.Id);
    if (response.data.Success) {
      queryClient.invalidateQueries("queryGroups"); // Cause Groups query to refresh
      setLocation("/home");
    } else {
      displayError(response.data.Error.Message);
    }
  }, [group]);

  const tryDeleteTeam = useCallback(() => {
    displayModal(
      `Delete ${group?.Emojicon} ${group?.Name}?`,
      "Are you sure you want to delete your team? This action cannot be undone.",
      deleteTeam,
      "Yes, delete team"
    );
  }, [group, deleteTeam]);

  const handleSave = async (event) => {
    setSaving(true);
    await updateGroup(group.Id, {
      Name: groupName.trim(),
      Emojicon: groupEmoji,
    }).catch((err, response) => {
      displayError(err.response.data.Error.Message);
    });
    queryClient.invalidateQueries("queryGroup");
    // useEffect for [group] will reset the save button
  };

  const handleCancelSave = async (event) => {
    setGroupName(group?.Name);
    setGroupEmoji(group?.Emojicon);
  };

  const innerRef = useOuterClick((ev) => {
    setShowPicker(false);
  });

  if (notFound) {
    return <ErrorPage code="404" message="Page not found" />;
  }

  return (
    <div className="bg-white">
      <PageMargin backgroundColor="lightGray-6">
        {group && (
          <Fragment>
            <PageBackButton label="Back to Team" href={`/teams/${group.Id}`} />
            <PageTitle label={`Team Settings`} />
            <PageColumnContainer>
              <PageColumn>
                <Section label="Team Name and Emoji">
                  <div className="flex w-full" ref={innerRef}>
                    {showPicker && (
                      <div className="absolute z-10">
                        <Picker onEmojiClick={onEmojiClick} />
                      </div>
                    )}
                    <div
                      className="text-4xl p-2 mr-4 border cursor-pointer rounded h-14 w-14"
                      onClick={(e) => setShowPicker(true)}
                    >
                      {groupEmoji}
                    </div>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Team name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        // error={!!error}
                        // helperText={error ? error.message : null}
                      />
                    </FormControl>
                  </div>
                  <div className="mb-4">
                    <SaveControls
                      show={showSaveButton}
                      onSave={handleSave}
                      onCancel={handleCancelSave}
                      saving={saving}
                      saveEnabled={saveValid}
                    />
                  </div>
                </Section>

                <Section label="Video Schedule">
                  <SectionInstructions>
                    Select the days and time for videos to be compiled. A new
                    prompt to create the next video will open at this same time.
                  </SectionInstructions>
                  <PromptTimes group={group} />
                </Section>

                <Section label="Members">
                  <GroupMembers group={group} me={me} />
                </Section>
              </PageColumn>

              <PageColumn>
                <Section label="Upcoming Prompts">
                  <FuturePrompts groupId={group.Id} />
                </Section>

                <Section label="Actions">
                  <ButtonPill onClick={tryDeleteTeam} bgColor="red">
                    Delete Team
                  </ButtonPill>
                </Section>
              </PageColumn>
            </PageColumnContainer>
          </Fragment>
        )}
      </PageMargin>
    </div>
  );
};

export default GroupAdmin;
