import { Fragment } from "react";

import FixedLink from "../Common/FixedLink";

const ErrorDialog = ({ message, emoji, title, onClose }) => {
  return (
    <div className="bg-white rounded px-12 py-12 shadow text-center">
      <div className="text-8xl mb-4">{emoji ? emoji : "😬"}</div>
      <div className="font-bold text-lg">
        {title ? title : "Something went wrong."}
      </div>
      {message ? <div className="mt-4">{message}</div> : <Fragment />}
      {onClose ? (
        <div className="mt-10 text-blue cursor-pointer" onClick={onClose}>
          Close
        </div>
      ) : (
        <div className="mt-10 text-blue">
          <FixedLink href="/home">Back to home</FixedLink>
        </div>
      )}
    </div>
  );
};

export default ErrorDialog;
