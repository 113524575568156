import icon from "../../images/12-black.svg";

const Copyright = () => (
  <div className="p-12">
    <div className="mb-8">
      <a href="/">
        <img className="h-32" src={icon} alt="12seconds" />
      </a>
    </div>
    <div className="text-2xl font-bold">Copyright Policy</div>
    <div>
      <div className="my-4">
        12seconds, Inc. (“12seconds”) has adopted the following general policy
        toward copyright infringement in accordance with the Digital Millennium
        Copyright Act
        <a
          href="http://lcweb.loc.gov/copyright/legislation/dmca.pdf"
          title="http://lcweb.loc.gov/copyright/legislation/dmca.pdf"
        >
          http://lcweb.loc.gov/copyright/legislation/dmca.pdf
        </a>
        . The address of 12seconds’s Designated Agent to Receive Notification of
        Claimed Infringement (“Designated Agent”) is listed at the end of this
        policy.
      </div>
      <div className="my-4">
        It is 12seconds’s policy to (1) block access to or remove material that
        it believes in good faith to be copyrighted material that has been
        illegally copied and distributed by any of our advertisers, affiliates,
        content providers, members or users; and (2) remove and discontinue
        service to repeat offenders.
      </div>
      <div className="my-4">
        A. Procedure for Reporting Copyright Infringements:
      </div>
      <div className="my-4">
        If you believe that material or content residing on or accessible
        through the 12seconds Web site or service infringes a copyright, please
        send a notice of copyright infringement containing the following
        information to the Designated Agent listed below:
      </div>
      <div className="my-4">
        1. A physical or electronic signature of a person authorized to act on
        behalf of the owner of the copyright that has been allegedly infringed;
        <br />
        2. Identification of works or materials being infringed;
        <br />
        3 Identification of the material that is claimed to be infringing
        including information regarding the location of the infringing materials
        that the copyright owner seeks to have removed, with sufficient detail
        so that 12seconds is capable of finding and verifying its existence;
        <br />
        4. Contact information about the notifier including address, telephone
        number and, if available, e-mail address;
        <br />A statement that the notifier has a good faith belief that the
        material is not authorized by the copyright owner, its agent, or the
        law; and
      </div>
      <div className="my-4">
        A statement made under penalty of perjury that the information provided
        is accurate and the notifying party is authorized to make the complaint
        on behalf of the copyright owner.{" "}
      </div>
      <div className="my-4">
        Once Proper Bona Fide Infringement Notification is Received by the
        Designated Agent:
        <br />
        It is 12seconds’s policy:
      </div>
      <div className="my-4">
        1. to remove or disable access to the infringing material;
        <br />
        2. to notify the content provider, member or user that it has removed or
        disabled access to the material; and
        <br />
        3. that repeat offenders will have the infringing material removed from
        the system and that 12seconds will terminate such content provider’s,
        member’s or user’s access to the service.
      </div>
      <div className="my-4">
        Procedure to Supply a Counter-Notice to the Designated Agent:
      </div>
      <div className="my-4">
        If the content provider, member or user believes that the material that
        was removed or to which access was disabled is either not infringing, or
        the content provider, member or user believes that it has the right to
        post and use such material from the copyright owner, the copyright
        owner’s agent, or pursuant to the law, the content provider, member or
        user must send a counter-notice containing the following information to
        the Designated Agent listed below:
      </div>
      <div className="my-4">
        1. A physical or electronic signature of the content provider, member or
        user;
        <br />
        2. Identification of the material that has been removed or to which
        access to has been disabled and the location at which the material
        appeared before it was removed or disabled;
        <br />
        3. A statement that the content provider, member or user has a good
        faith belief that the material was removed or disabled as a result of
        mistake or a misidentification of the material; and
        <br />
        4. Content provider’s, member’s or user’s name, address, telephone
        number, and, if available, e-mail address and a statement that such
        person or entity consents to the jurisdiction of the Federal Court for
        the judicial district in which the content provider’s, member’s or
        user’s address is located, or if the content provider’s, member’s or
        user’s address is located outside the United States, for any judicial
        district in which 12seconds is located, and that such person or entity
        will accept service of process from the person who provided notification
        of the alleged infringement.
      </div>
      <div className="my-4">
        If a counter-notice is received by the Designated Agent, 12seconds may
        send a copy of the counter-notice to the original complaining party
        informing that person that it may replace the removed material or cease
        disabling it in 10 business days. Unless the copyright owner files an
        action seeking a court order against the content provider, member or
        user, the removed material may be replaced or access to it restored in
        10 to 14 business days or more after receipt of the counter-notice, at
        12seconds’s discretion.
      </div>
      <div className="my-4">
        Please contact 12seconds’s Designated Agent to Receive Notification of
        Claimed Infringement at the following address:{" "}
      </div>
      <div className="my-4">
        12seconds Inc.
        <br />
        c/o Sol Lipman
        <br />
        101 Cooper St.
        <br />
        Santa Cruz, CA 95005
        <br />
        <a href="mailto:sol@12seconds.tv">sol@12seconds.tv</a>
      </div>

      <div className="my-4">Effective Date: April 27, 2021</div>
    </div>
  </div>
);

export default Copyright;
