import SectionHeader from "./SectionHeader";

const Section = ({ children, label = false }) => (
  <div className="mb-8 sm:mb-10 w-full">
    {label && <SectionHeader label={label} />}
    {children}
  </div>
);

export default Section;
