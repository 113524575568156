import Div100vh from "react-div-100vh";
import { Redirect, Route, Switch } from "wouter";

import BadgeCountProvider from "../providers/BadgeCountProvider";
import NavProvider from "../providers/NavProvider";
import ErrorPage from "./ErrorPage/ErrorPage";
import GroupAdmin from "./Groups/GroupAdmin";
import GroupCreate from "./Groups/GroupCreate";
import GroupHome from "./Groups/GroupHome";
import Groups from "./Groups/Groups";
import InlinePlayDisplay from "./InlinePlay/InlinePlayDisplay";
import ResponsesRedirect from "./InlinePlay/ResponsesRedirect";
import BottomNavBar from "./Navigation/BottomNavBar";
import TopNavBar from "./Navigation/TopNavBar";
import Notifications from "./Notifications";
import Profile from "./Profile";
import PromptAutoReloader from "./PromptAutoReloader";
import Prompts from "./Prompts";
import Settings from "./Settings";
import Videos from "./Videos";

// Exported for App.js router, which will send all these path values here. DRY.
export const PATHS = {
  responses: "/responses/:promptId",
  groupCreate: "/teams/create",
  groupHome: "/teams/:groupId",
  groupVideos: "/teams/:groupId/videos",
  groupMembers: "/teams/:groupId/members",
  groupSchedule: "/teams/:groupId/schedule",
  groupSettings: "/teams/:groupId/settings",
  groupAdmin: "/teams/:groupId/settings",
  profile: "/profile",
  home: "/home",
  settings: "/settings",
  videos: "/videos",
  prompts: "/prompts",
  teams: "/teams",
  notifications: "/activity",
};

const Main = () => {
  const searchParams = window.location.search;
  return (
    <BadgeCountProvider>
      <PromptAutoReloader />
      <NavProvider>
        <Div100vh>
          {/* ^ Div100vh is used for magic fix to mobile safari screen height sizing */}
          <div className="flex flex-col h-full">
            <TopNavBar />
            <Switch>
              <Route path={PATHS.responses} component={ResponsesRedirect} />
              <Route path={PATHS.groupCreate} component={GroupCreate} />
              <Route path={PATHS.groupHome} component={GroupHome} />
              <Route path={PATHS.groupVideos} component={GroupHome} />
              <Route path={PATHS.groupMembers} component={GroupHome} />
              <Route path={PATHS.groupSchedule} component={GroupHome} />
              <Route path={PATHS.groupSettings} component={GroupHome} />
              <Route path={PATHS.groupAdmin} component={GroupAdmin} />
              <Route path={PATHS.profile} component={Profile} />
              <Route path={PATHS.home}>
                <Redirect to={PATHS.videos + searchParams} />
              </Route>
              <Route path={PATHS.teams} component={Groups} />
              <Route path={PATHS.videos} component={Videos} />
              <Route path={PATHS.prompts} component={Prompts} />
              <Route path={PATHS.notifications} component={Notifications} />
              <Route path={PATHS.settings} component={Settings} />
              <Route>
                <ErrorPage code="404" message="Page not found" />
              </Route>
            </Switch>
            <BottomNavBar />
            <InlinePlayDisplay />
          </div>
        </Div100vh>
      </NavProvider>
    </BadgeCountProvider>
  );
};

export default Main;
