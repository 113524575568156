import { TextField } from "@material-ui/core";
import { useCallback, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "wouter";

import { signupFinish } from "../api";
import { MAX_CHARS_FIRST_NAME, MAX_CHARS_LAST_NAME } from "../constants";
import { AppContext } from "../contexts/appContext";
import bolts from "../images/black-bolts.svg";
import ButtonPill from "./Common/ButtonPill";
import FatTitle from "./Common/FatTitle";
import FixedImg from "./Common/FixedImg";
import YellowScreen from "./Common/YellowScreen";

const SignUpFinish = ({ params }) => {
  const { token } = params;
  const { displayError, displayModal, setToken } = useContext(AppContext);

  const [, setLocation] = useLocation();

  // step 0: explanation
  // step 1: user details
  const [signUpFinishStep, setSignupFinishStep] = useState(0);
  const { handleSubmit, control } = useForm();

  const Explanation = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <FixedImg src={bolts} alt="You're in!" className="w-24" />
      <div className="py-8">
        <FatTitle>You're in!</FatTitle>
      </div>

      <div className="flex flex-col space-y-8 items-center sm:w-1/2">
        <div>
          Great things are built by teams that know and trust each other.{" "}
          <i>
            <b>12seconds</b>
          </i>{" "}
          helps build team cohesion and rapport through fun collaborative
          videos.
        </div>
        <div>
          Record your twelve second answer to a video prompt, and then watch
          everyone’s answers later in the day. <i>Know thy team!</i>
        </div>
        <ButtonPill large={true} onClick={(e) => setSignupFinishStep(1)}>
          Next
        </ButtonPill>
      </div>
    </div>
  );

  /*
  const ExampleTwelve = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <FixedImg src={logo} alt="12seconds" className="w-48" />
      <div>
        <video controls className="my-8">
          <source
            src="https://cdn.team.12seconds.tv/UG7TN7RW8ZF1/SP8JX2KU6CQ2/e0ce6b83-ed17-4e39-9509-a587a66eb1a2-final.mp4"
            type="video/webm"
          />
        </video>
      </div>
      <div className="mb-8">This is what a end-of-day video looks like.</div>
      <ButtonPill large={true} onClick={(e) => setSignupFinishStep(2)}>
        Next
      </ButtonPill>
    </div>
  );
  */

  const UserDetails = () => (
    <div className="container mx-auto p-4 flex justify-center items-center flex-col">
      <div className="mb-4">
        <FixedImg src={bolts} alt="You're in!" className="w-24 mx-auto" />
        <div className="py-4">
          <FatTitle>Hey, fill this out real quick...</FatTitle>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-4 sm:w-1/2 w-3/4"
      >
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          rules={{ required: "First name required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="First Name"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              inputProps={{ maxLength: MAX_CHARS_FIRST_NAME }}
              helperText={error ? error.message : null}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          rules={{ required: "Last name required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Last Name"
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              inputProps={{ maxLength: MAX_CHARS_LAST_NAME }}
              helperText={error ? error.message : null}
            />
          )}
        />

        <div className="text-center py-6 mt-6">
          <ButtonPill type="submit" large={true}>
            Next
          </ButtonPill>
        </div>
      </form>
    </div>
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        const result = await signupFinish(
          values.firstName,
          values.lastName,
          token
        );
        setToken(token);
        setLocation("/signup-photo");
      } catch (err) {
        // TODO handle AXIOS exceptions!

        //console.log(util.inspect(err, { depth: 10 }));
        // if account already exists, we should just log the person in
        const status = err.response.status;
        if (status === 409) {
          // 409 means the account already exists
          // try to log the user in anyway
          setLocation(`/login/${token}`);
        } else if (status === 401) {
          // Edge case: User may have logged in with this token already and logged out, killing it.
          // TODO check signup/info API first instead!
          displayModal(
            "Whoops!",
            "Sorry, this signup link is too old.",
            () => setLocation("/home"),
            "Okay",
            false
          );
        } else {
          displayError(err.response.data.Error.Message);
        }
      }
    },
    [params.token, displayError]
  );

  return (
    <YellowScreen>
      {(signUpFinishStep === 0 && <Explanation />) ||
        (signUpFinishStep == 1 && <UserDetails />)}
    </YellowScreen>
  );
};
export default SignUpFinish;
