import { useState } from "react";
import Div100vh from "react-div-100vh";

import { waitingList } from "../../api";
import twelveBox from "../../images/12-box.png";
import FixedImg from "../Common/FixedImg";
import FixedLink from "../Common/FixedLink";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";
import SafeAreaInsetTop from "../Common/SafeAreaInsetTop";
import MarketingFooter from "./MarketingFooter";
import MarketingPlayer from "./MarketingPlayer";

const WaitingListSignup = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (email === "") {
      return;
    }
    const result = await waitingList(email).catch((error) => {
      console.log(error);
      setErrorMessage("Please enter a valid email address.");
      return;
    });

    if (result) {
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <div className="p-4">Thanks! We'll be in touch!</div>;
  }

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="flex flex-col space-y-2 px-4 pb-4 w-full">
        <div>
          <input
            placeholder="Work email address"
            className="rounded w-full p-4 text-black"
            type="email"
            value={email}
            onChange={onChange}
          />
        </div>
        <div>
          <button
            className="rounded w-full bg-yellow sm:bg-black text-black sm:text-white py-4 uppercase font-bold text-xl cursor-pointer select-none"
            type="submit"
          >
            Submit
          </button>
        </div>
        {errorMessage && <div className="text-sm text-red">{errorMessage}</div>}
      </div>
    </form>
  );
};

const MarketingHome = () => {
  return (
    <Div100vh>
      <div className="flex flex-col h-full bg-black">
        <div className="flex-1 flex flex-col w-full overflow-y-scroll">
          <div className="relative bg-yellow">
            <SafeAreaInsetTop />
            <div className="flex sm:flex-col w-full justify-between">
              <div className="sm:absolute sm:left-0 mt-4">
                <FixedImg src={twelveBox} alt="12-box" className="w-52" />
              </div>
              <div className="sm:absolute sm:right-0 font-bold text-right sm:px-8 px-4 py-6 underline">
                <FixedLink href="/signin">Sign In</FixedLink>
              </div>
            </div>
            <div className="uppercase font-headline text-5xl sm:text-6xl p-4 mt-4 sm:mt-28 lg:mt-2 text-center">
              Know Thy Team
            </div>
            <div className="sm:flex sm:container sm:mx-auto sm:items-center sm:justify-center">
              <div className="flex flex-col w-full sm:w-96">
                <div className="px-4 py-4 font-light text-lg">
                  <span className="font-bold">
                    Build Team Cohesion in 12 Seconds
                  </span>
                  <br />
                  Get to know your team through daily collaborative videos. Take
                  twelve seconds out of your day to answer a playful video
                  prompt, then watch everyone's answer in a quick compiled video
                  later in the day.
                </div>
                <div className="sm:hidden bg-split-yellow-dark-gray px-12 py-8 pb-6">
                  <MarketingPlayer />
                </div>
                <div className="bg-darkGray text-white sm:bg-yellow sm:text-black sm:mb-6">
                  <div className="px-4 py-4 font-light text-lg">
                    <span className="font-bold">Get Real Benefits</span>
                    <br />
                    Research has shown that team cohesion is a key indicator for
                    success.{" "}
                    <i>
                      <b>12seconds</b>
                    </i>{" "}
                    makes strengthening your team fun and easy.
                  </div>
                  <div className="px-4 py-4 text-lg font-light">
                    <span className="font-bold">Get Prelaunch Access</span>
                    <br />
                    Any team that joins pre-launch can use{" "}
                    <i>
                      <b>12seconds</b>
                    </i>{" "}
                    free forever. Enter your work email address below for an
                    invite.
                  </div>
                  <div className="mb-6 sm:mb-0">
                    <WaitingListSignup />
                  </div>
                </div>
              </div>
              <div className="hidden sm:flex sm:w-96 sm:items-center sm:justify-center ml-8">
                <MarketingPlayer />
              </div>
            </div>
            <div className="sm:pt-6">
              <MarketingFooter />
            </div>
            <SafeAreaInsetBottom />
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

export default MarketingHome;
