import { ChevronLeftIcon } from "@heroicons/react/solid";

import FixedLink from "./FixedLink";

const PageBackButton = ({ label, href }) => {
  return (
    <div className="relative w-full">
      <div className="absolute -ml-2 -mt-7 pl-0.5">
        <FixedLink href={href}>
          <div className="flex select-none cursor-pointer text-blue">
            <ChevronLeftIcon className="h-6 w-6 text-blue" />
            <div className="font-med">{label}</div>
          </div>
        </FixedLink>
      </div>
    </div>
  );
};

export default PageBackButton;
