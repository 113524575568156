import { Fragment } from "react";

import pauseIcon from "../images/pause.svg";
import playIcon from "../images/play-button.svg";
import recordIcon from "../images/record-button.svg";
import stopIcon from "../images/stop-button.svg";
import FixedImg from "./Common/FixedImg";

const Controls = ({
  preRecordCountingDown,
  capturing,
  processing,
  playing,
  videoBlob,
  startRecording,
  playRecording,
  pauseRecording,
  cancelRecording,
  submitRecording,
}) => {
  const recordButton = (
    <button
      disabled={preRecordCountingDown}
      onClick={startRecording}
      className="h-16 w-16 mx-auto"
    >
      <img src={recordIcon} />
    </button>
  );

  // Spins a black/yellow half-ring, appears when blob processing is slow on mobile.
  const processingSpinner = (
    <div className="absolue h-16 w-16">
      <div className="absolute rounded mx-auto w-16 h-16 flex justify-items-center items-center animate-spin">
        <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
          <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop stopColor="#000" />
            <stop offset=".6" stopColor="#FFF200" />
          </linearGradient>
          <circle
            shapeRendering="optimizeQuality"
            r={28}
            cx={32}
            cy={32}
            className="external-circle"
            strokeWidth="8"
            fill="none"
            stroke="url(#linearColors)"
          ></circle>
        </svg>
      </div>
    </div>
  );

  const playButton = (
    <button onClick={playRecording} className="h-16 w-16 mx-auto">
      <div className="text-black w-full flex items-center justify-center">
        <FixedImg src={playIcon} alt="Play" />
      </div>
    </button>
  );

  const pauseButton = (
    <button
      onClick={pauseRecording}
      className="rounded-full bg-yellow w-16 h-16 mx-auto flex justify-items-center items-center"
    >
      <div className="w-full flex items-center justify-center">
        <FixedImg src={pauseIcon} alt="Pause" />
      </div>
    </button>
  );

  const playPauseButton = playing ? pauseButton : playButton;

  const retakeButton = (
    <button
      className="rounded-full text-xs bg-black text-white text-center font-bold py-2 px-4 select-none border-4 border-yellow border-opacity-50"
      onClick={startRecording}
    >
      Retake
    </button>
  );

  const cancelButton = (
    <button
      className="text-xs rounded-full text-white bg-black font-bold text-center py-2 px-4 select-none border-4 border-yellow border-opacity-50"
      onClick={cancelRecording}
    >
      Cancel
    </button>
  );

  const stopButton = (
    <button onClick={cancelRecording} className="h-16 w-16 mx-auto">
      <img src={stopIcon} />
    </button>
  );

  const submitButton = (
    <button
      className={`text-xs rounded-full text-white bg-black font-bold text-center py-2 px-4 select-none border-4 border-yellow border-opacity-50 ${
        videoBlob ? "" : "opacity-50"
      }`}
      disabled={!videoBlob}
      onClick={submitRecording}
    >
      Submit
    </button>
  );

  let leftButton = <Fragment />;
  let rightButton = <Fragment />;
  let centerButton = recordButton;

  if (capturing) {
    leftButton = retakeButton;
    rightButton = submitButton;
    centerButton = stopButton;
  }

  if (videoBlob) {
    leftButton = retakeButton;
    rightButton = submitButton;
    centerButton = playPauseButton;
  }

  if (preRecordCountingDown) {
    leftButton = retakeButton;
    rightButton = cancelButton;
    centerButton = recordButton;
  }

  if (processing) {
    centerButton = processingSpinner;
  }

  const prerecordClasses = preRecordCountingDown
    ? "opacity-50 duration-500"
    : "opacity-100";

  return (
    <div className="select-none">
      <div className={`flex flex-row items-center justify-center`}>
        <div className="pr-6 w-20 flex items-center justify-end">
          {leftButton}
        </div>
        <div
          className={`w-24 flex items-center justify-center ${prerecordClasses}`}
        >
          {centerButton}
        </div>
        <div className="pl-6 w-20 flex items-center justify-start">
          {rightButton}
        </div>
      </div>
    </div>
  );
};

export default Controls;
