import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePageVisibility } from "react-page-visibility";
import { useQuery, useQueryClient } from "react-query";
import { Route, Router, Switch, useLocation } from "wouter";
import makeMatcher from "wouter/matcher";

import { authLogout } from "./api";
import Auth from "./components/Auth";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Copyright from "./components/Legal/Copyright";
import Privacy from "./components/Legal/Privacy";
import Terms from "./components/Legal/Terms";
import LoggedOutHome from "./components/LoggedOutHome";
import Login from "./components/Login";
import Logout from "./components/Logout";
import { PATHS as MAIN_PATHS } from "./components/Main";
import Main from "./components/Main";
import MarketingHome from "./components/Marketing/MarketingHome";
import Modal from "./components/Modal";
import Prompt from "./components/Prompt";
import SignUpFinish from "./components/SignUpFinish";
import SignUpFinishPhoto from "./components/SignUpFinishPhoto";
import { AppContext } from "./contexts/appContext";
import { ParentAppContext } from "./contexts/parentAppContext";
import useModal from "./hooks/useModal";
import LoggedOutRoute from "./LoggedOutRoute";
import ProtectedRoute from "./ProtectedRoute";
import MediaContextProvider from "./providers/MediaContextProvider";
import { queryMe } from "./queries";

// Support multiple routes to Main. Wouter doesn't support multiple paths out of the box
// https://github.com/molefrog/wouter#is-it-possible-to-match-an-array-of-paths
const defaultMatcher = makeMatcher();
const multipathMatcher = (patterns, path) => {
  for (let pattern of [patterns].flat()) {
    const [match, params] = defaultMatcher(pattern, path);
    if (match) return [match, params];
  }
  return [false, null];
};

const App = () => {
  const [me, setMe] = useState();
  const [token, _setToken] = useState();
  const [, setLocation] = useLocation();
  const { isModalShowing, showModal, hideModal } = useModal();
  const [modalBody, setModalBody] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [modalOnAction, setModalOnAction] = useState();
  const [modalActionTitle, setModalActionTitle] = useState();
  const [modalAllowCancel, setModalAllowCancel] = useState();
  const [modalOnHide, setModalOnHide] = useState();
  const {
    hasParentApp,
    logoutParentApp,
    triggerNotificationRegistration,
  } = useContext(ParentAppContext);
  const isPageVisible = usePageVisibility();

  useEffect(() => {
    const localStorageToken = localStorage.getItem("token");
    _setToken(localStorageToken);
  }, []);

  const logout = useCallback(async () => {
    logoutParentApp();
    // Until the react-query LocalStoragePersistor has
    //  a working way to clear the cache, here we just clear
    //  localStorage and reload the entire app. Else
    //  peristor seems to want to keep writing the cache.
    //  https://github.com/tannerlinsley/react-query/issues/1951
    try {
      // Clears push device token and session...
      await authLogout(token); // Need to await until we can fix the page .reload() ?
    } catch (e) {
      // Not the end of the world if we didn't clear the session, but
      // push notifications may persist to the device.
      console.error("auth logout failed:", e);
    }
    _setToken(undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
    setLocation("/");
    window.location.reload();
  }, [setLocation, logoutParentApp, token]);

  const setToken = useCallback((newToken) => {
    localStorage.setItem("token", newToken);
    _setToken(newToken);
  }, []);

  const { error: queryMeError, data: fetchedMe } = useQuery(
    "queryMe",
    queryMe,
    { enabled: !!token }
  );

  useEffect(() => {
    if (fetchedMe && !token) {
      // Here handle a strange state where user has a cached "me" but no token...
      // User would go to loggedout home, try signin, and hang on black spinner,
      // where the code is expecting me to be coming from a successful signin!
      // Kind of a hack fix.
      logout(); // logout will erase the cache..
    } else {
      setMe(fetchedMe);
      if (fetchedMe) {
        triggerNotificationRegistration();
      }
    }
  }, [fetchedMe, token, triggerNotificationRegistration]);

  useEffect(() => {
    if (queryMeError) {
      const status = queryMeError.response?.status;
      if (status === 401 || status === 403) {
        console.log("*** TOKEN EXPIRED? LOGGING OUT ***");
        logout();
      }
    }
  }, [queryMeError, logout]);

  const displayError = (message) => {
    setModalTitle("Error");
    setModalBody(
      <p className="my-4 text-gray-600 text-lg leading-relaxed">{message}</p>
    );
    showModal();
  };

  const displayModal = (
    title,
    body,
    onAction,
    actionTitle,
    allowCancel = true,
    onHide
  ) => {
    setModalTitle(title);
    setModalBody(body);
    setModalOnAction(() => onAction);
    setModalActionTitle(actionTitle);
    setModalAllowCancel(allowCancel);
    setModalOnHide(() => onHide);
    showModal();
  };

  const onHideModal = () => {
    hideModal();
    if (modalOnHide) modalOnHide();
    setModalTitle(undefined);
    setModalBody(undefined);
    setModalOnAction(undefined);
    setModalActionTitle(undefined);
    setModalAllowCancel(undefined);
    setModalOnHide(undefined);
  };

  // websocket setup for getting events (like new comment)
  const ws = useRef(null);
  const queryClient = useQueryClient(); // for causing "queryMe" to refresh

  useEffect(() => {
    if (token && isPageVisible) {
      ws.current = new WebSocket(
        `${process.env.REACT_APP_WS_ROOT_URL}?token=${token}`
      );
      ws.current.onmessage = (msg) => {
        const message = JSON.parse(msg.data);
        //console.log("msg", message);
        switch (message.Action) {
          case "NewComment": {
            const promptId = message.Id;
            queryClient.invalidateQueries(["queryPrompt", { promptId }]); // Cause Prompt query to refresh
            queryClient.invalidateQueries("queryHomePageDedupedData"); // Cause home page to refresh (which shows comment count)
            queryClient.invalidateQueries("queryNotifications"); // notifications
            break;
          }
          default:
            break;
        }
      };
      // ws.current.onopen = () => console.log("ws opened");
      // ws.current.onclose = () => console.log("ws closed");
    } else if (!isPageVisible) {
      ws.current?.close();
    }

    return () => {
      ws.current?.close();
    };
  }, [token, isPageVisible]);

  return (
    <AppContext.Provider
      value={{ me, setToken, logout, displayError, displayModal }}
    >
      <Modal
        isShowing={isModalShowing}
        hide={onHideModal}
        title={modalTitle}
        body={modalBody}
        onAction={modalOnAction}
        actionTitle={modalActionTitle}
        allowCancel={modalAllowCancel}
      />
      <MediaContextProvider>
        <Router matcher={multipathMatcher}>
          <Switch>
            <ProtectedRoute path={Object.values(MAIN_PATHS)} component={Main} />
            <ProtectedRoute path="/prompts/:promptId" component={Prompt} />
            <LoggedOutRoute path="/signup/:token" component={SignUpFinish} />
            <ProtectedRoute
              path="/signup-photo"
              component={SignUpFinishPhoto}
            />
            <LoggedOutRoute
              path="/signup"
              component={() => <Auth isSignup={true} />}
            />
            <Route path="/invite/:code">
              {({ code }) => <Auth isSignup={true} code={code} />}
            </Route>
            <LoggedOutRoute path="/login/:token" component={Login} />
            <LoggedOutRoute
              path="/login"
              component={() => <Auth isSignup={false} />}
            />
            <Route path="/logout" component={Logout} />
            <Route path="/tos" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/copyright" component={Copyright} />
            <LoggedOutRoute path="/marketing_home" component={MarketingHome} />
            <LoggedOutRoute path="/signin" component={LoggedOutHome} />
            {hasParentApp ? (
              <LoggedOutRoute path="/" component={LoggedOutHome} />
            ) : (
              <LoggedOutRoute path="/" component={MarketingHome} />
            )}
            <Route>
              <ErrorPage code="404" message="Page not found" />
            </Route>
          </Switch>
        </Router>
      </MediaContextProvider>
    </AppContext.Provider>
  );
};

export default App;
