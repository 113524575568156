import util from "util";

import { EmojiHappyIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Picker from "emoji-picker-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from "react-query";
import { useSwipeable } from "react-swipeable";
import TextareaAutosize from "react-textarea-autosize";
import ReactTimeAgo from "react-time-ago";
import { elementScrollIntoView } from "seamless-scroll-polyfill"; // For mobile safari smooth scroll. https://github.com/magic-akari/seamless-scroll-polyfill

import { deleteComment, postComment } from "../../api";
import { ParentAppContext } from "../../contexts/parentAppContext";
import useOuterClick from "../../hooks/useOuterClick";
import defaultProfile from "../../images/default-profile.png";
import { queryGroup, queryMe, queryPrompt, queryUsers } from "../../queries";
import FixedImg from "../Common/FixedImg";
import SafeAreaInsetBottom from "../Common/SafeAreaInsetBottom";

const noCommentsMessages = [
  "There are no comments yet.",
  "There are no comments yet. Be the first!",
  "No comments yet. You can change this.",
  "No comments yet. First post is yours!",
  "No comments yet. Tell someone you liked their video!",
  "No comments?! How about an emoji heart?",
];

const rareNoCommentsMessages = [
  "Seems quiet around here...",
  "Either you're the first person here or this video is too good for words.",
  "First person to comment wins a prize!",
  "No comments yet. Please clap.",
  "I can haz comment?",
  "Few comments. Much wow.",
  "Comments section is emptier than a hermit's address book.",
  "Don't think twice, it's alright.",
];

const semiRandomNoCommentsMessage = () => {
  const random = Math.random();
  let message = noCommentsMessages[0];
  if (random > 0.95) {
    message =
      rareNoCommentsMessages[
        Math.floor(Math.random() * rareNoCommentsMessages.length)
      ];
  } else if (Math.random() > 0.4) {
    message =
      noCommentsMessages[Math.floor(Math.random() * noCommentsMessages.length)];
  }
  return message;
};

const TeamBanner = ({ group, setShowComments }) => {
  const swipeHandlers = useSwipeable({
    onSwipedDown: (e) => {
      setShowComments(false);
    },
  });
  return (
    <div {...swipeHandlers} className="flex text-sm mb-1 h-10 items-center">
      <div className="flex-1 font-bold">
        {group.Emojicon} {group.Name}
      </div>
      <div
        className="text-black flex cursor-pointer"
        onClick={(e) => setShowComments(false)}
      >
        <XIcon className="ml-2 w-6 h-6 text-black" />
      </div>
    </div>
  );
};

const CommentInput = ({
  prompt,
  showPicker,
  setShowPicker,
  commentInputHasFocus,
  setCommentInputHasFocus,
  rotated,
  setInProgressCommentText,
}) => {
  const queryClient = useQueryClient(); // for causing "queryPrompt" to refresh
  const [submitting, setSubmitting] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [error, setError] = useState(null);
  const { hasParentApp } = useContext(ParentAppContext);

  const inputRef = useRef();

  const innerRef = useOuterClick((evt) => {
    setShowPicker(false);
  });

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit(evt);
    }
  };

  const handleCommentChange = (evt) => {
    const commentText = evt.target.value;
    setCommentText(commentText);
    setSubmitEnabled(commentText !== "");
  };

  const handleSubmit = async (evt) => {
    setSubmitting(true);
    setError(null);

    if (commentText?.trim() === "") return;
    setInProgressCommentText(commentText.trim());

    const result = await postComment(prompt.Id, commentText).catch((error) => {
      //console.log(util.inspect(error, { depth: 5 }));
      setInProgressCommentText(null);
      setError(error);
      setSubmitting(false);
    });

    if (result) {
      // Result is the prompt
      setCommentText("");
      inputRef.current.blur();
      queryClient.invalidateQueries(["queryPrompt", { promptId: prompt.Id }]);
      setSubmitting(false);
    }
  };

  const onEmojiClick = (evt, emojiObject) => {
    setShowPicker(false);
    setCommentText(commentText + emojiObject.emoji);
    inputRef.current.focus();
  };

  const preventDefault = useCallback((e) => e.preventDefault(), []);

  useEffect(() => {
    // Seems this is not needed in iOS, but it does fix mobile web scrolling
    if (isMobile && !hasParentApp) {
      if (commentInputHasFocus) {
        // When rendering our container
        window.addEventListener("touchmove", preventDefault, {
          passive: false,
        });
      } else {
        // When rendering our container
        window.removeEventListener("touchmove", preventDefault);
      }
      return () => {
        window.removeEventListener("touchmove", preventDefault);
      };
    }
  }, [commentInputHasFocus]);

  useEffect(() => {
    if (rotated === true) {
      inputRef.current.blur();
    }
  }, [rotated]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col">
        {showPicker && (
          <div className={`absolute right-0 bottom-10 z-20 flex mb-2`}>
            <Picker
              onEmojiClick={onEmojiClick}
              disableAutoFocus={true}
              disableSearchBar={true}
              groupVisibility={{
                symbols: false,
                flags: false,
                recently_used: false,
              }}
            />
          </div>
        )}
        {error && <div className="text-xs text-red">{error.message}</div>}
        <div className={`flex flex-row items-end`} ref={innerRef}>
          <TextareaAutosize
            ref={inputRef}
            placeholder="Add comment"
            value={commentText}
            maxRows={`${commentInputHasFocus ? 5 : 3}`}
            minRows={`${commentInputHasFocus && isMobile ? 4 : 1}`}
            onChange={handleCommentChange}
            onFocus={(evt) => setCommentInputHasFocus(true)}
            onBlur={(evt) =>
              // hack: setting the comment input state on blur
              // was causing a rerender which was eating the send
              // button press
              setTimeout(() => setCommentInputHasFocus(false), 200)
            }
            onKeyPress={handleKeyPress}
            disabled={submitting === true}
            className={`${
              isMobile
                ? "bg-lightGray-6 text-lightGray-2"
                : "bg-white text-black"
            } flex-1 p-2 resize-none rounded-lg transition-height`}
          />
          {isMobile && (
            <button
              className={`ml-2 ${
                submitEnabled ? "bg-blue" : "bg-lightGray-4"
              } text-gray-2 text-white rounded py-2 px-4`}
              onClick={(evt) => handleSubmit(evt)}
              disabled={submitEnabled === false}
            >
              Send
            </button>
          )}
          {!isMobile && (
            <div
              className="cursor-pointer"
              onClick={(evt) => {
                setShowPicker(true);
              }}
            >
              <EmojiHappyIcon
                className={`w-6 h-6 ml-2 ${
                  isMobile ? "text-white" : "text-black"
                }`}
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const Comments = ({
  setShowComments,
  promptId,
  commentInputHasFocus,
  setCommentInputHasFocus,
  hidden,
  rotated = false,
}) => {
  const { data: me } = useQuery("queryMe", queryMe);
  const [showPicker, setShowPicker] = useState(false);
  const [noCommentsText] = useState(semiRandomNoCommentsMessage());
  const commentBottomRef = useRef();
  const queryClient = useQueryClient(); // for causing "queryPrompt" to refresh
  const [inProgressCommentText, setInProgressCommentText] = useState(null);

  const { error: queryPromptError, data: prompt } = useQuery(
    ["queryPrompt", { promptId }],
    queryPrompt
  );

  const groupId = prompt?.GroupId;
  const { data: group } = useQuery(["queryGroup", { groupId }], queryGroup, {
    enabled: !!groupId,
  });

  const userIds = group?.UserIds;
  const { data: members } = useQuery(["queryUsers", { userIds }], queryUsers, {
    enabled: !!userIds,
  });

  useEffect(() => {
    // Clear the in progress prompt once prompts load...
    setInProgressCommentText(null);
  }, [prompt]);

  useEffect(() => {
    if (inProgressCommentText !== null) {
      scrollToBottom();
    }
  }, [inProgressCommentText]);

  const scrollToBottom = () => {
    // Note: current? may be null due to this
    // happening in setTimeout. We could be unmounted.
    if (commentBottomRef.current) {
      // Use polyfill for iOS Safari smooth scroll...
      elementScrollIntoView(commentBottomRef.current, {
        block: "nearest",
        inline: "center",
        behavior: "auto",
        alignToTop: false,
      });
    }
  };

  const handleDeleteComment = async (evt, commentId) => {
    evt.preventDefault();

    const result = await deleteComment(prompt.Id, commentId).catch((error) => {
      console.log(error);
    });

    if (result) {
      queryClient.invalidateQueries(["queryPrompt", { promptId: prompt.Id }]);
    }
  };

  // todo: this is duped from InlinePLayer. dedupe
  const userForId = (userId) => {
    if (members && members.length > 0) {
      return members.find((member) => member.Id === userId);
    }
  };

  useEffect(() => {
    if (commentBottomRef.current) {
      setTimeout(
        () => {
          scrollToBottom();
        },
        commentInputHasFocus ? 200 : 0
        // setTimeout because something about the height resize
        // seems to break the scroll. A delay fixes it.
      );
    }
  }, [prompt, commentBottomRef.current, commentInputHasFocus]);

  const backgroundRef = useRef();

  const backgroundSwipeHandlers = useSwipeable({
    onSwipedDown: (e) => {
      setShowComments(false);
      e.event.stopPropagation();
    },
  });

  const frameSwipeHandlers = useSwipeable({
    onSwipedDown: (e) => {
      // If we don't stop propagation, background will pick it up.
      e.event.stopPropagation();
    },
  });

  const backgroundRefPassthrough = (el) => {
    // combine useSwipeable's ref with our backgroundRef
    backgroundSwipeHandlers.ref(el); // call useSwipeables ref prop with el
    backgroundRef.current = el; // set the el to a ref you can access yourself
  };

  const frameClasses = classNames(
    "bg-white text-black p-3 flex flex-col overflow-auto bg-blur-xl transition-height",
    "pointer-events-auto",
    //^ setting pointer-event-auto because background container has pointer-events-none
    // when not mobile, allowing click passthrough.
    {
      "max-h-1/2": isMobile && commentInputHasFocus && !isAndroid, //Android keyboard scrolls differntly and the bigger size works.
      "max-h-3/4": isMobile && (!commentInputHasFocus || isAndroid),
      "w-full rounded-t-3xl shadow-md bg-opacity-100": isMobile,
      "sm:w-2/3 md:w-1/2 mx-6 my-20 max-h-5/6 rounded-lg shadow-md bg-opacity-80": !isMobile,
      hidden: hidden,
    }
  );

  return (
    <div
      {...backgroundSwipeHandlers} // Must be inserted BEFORE ref declaration below. WTF
      ref={backgroundRefPassthrough}
      className={`absolute z-30 right-0 bottom-0 w-full h-full flex flex-col justify-end items-end ${
        !isMobile && "pointer-events-none" // Desktop allows pointer events to pass through background
      }`}
      onClick={(e) => {
        if (e.target === backgroundRef.current) {
          e.preventDefault();
          setShowComments(false);
          e.stopPropagation();
        }
      }}
    >
      {prompt && group && members && (
        <div
          {...frameSwipeHandlers}
          className={frameClasses}
          onClick={(e) => {
            if (showPicker) {
              setShowPicker(false);
            }
          }}
        >
          <TeamBanner group={group} setShowComments={setShowComments} />

          <div
            className={`flex-1 my-2 flex flex-col space-y-4 overflow-scroll min-h-32`}
          >
            {!prompt.Comments.length && (
              <div className="flex items-center justify-center text-center w-full opacity-30 h-12">
                {noCommentsText}
              </div>
            )}
            {prompt.Comments.map((comment) => {
              const user = userForId(comment.UserId);
              return (
                <div className="flex items-center pr-4" key={comment.CommentId}>
                  {/* pr-4 above makes room for scroll bar */}
                  <FixedImg
                    src={user ? user.ProfilePhotoUrl : defaultProfile}
                    alt="Profile"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <div className="text-xs font-bold">{user.FirstName}</div>
                      <ReactTimeAgo
                        className="text-xs font-bold opacity-20"
                        date={new Date(comment.DateCreated * 1000)}
                      />
                    </div>
                    <div className="text-sm">{comment.CommentText}</div>
                    {/* hiding delete comment for now until the treatment is ready. */}
                    {/*(comment.UserId === me.Id ||
                    group?.AdminIds.includes(me.Id)) && (
                    <div
                      className="text-xs text-red cursor-pointer"
                      onClick={(evt) => {
                        handleDeleteComment(evt, comment.CommentId);
                      }}
                    >
                      remove comment
                    </div>
                    )*/}
                  </div>
                </div>
              );
            })}
            {/* In case send takes a minute, we have an "in progress" rendering of the comment
            which will clear whenever prompt query happens.  (See useEffect prompt) */}
            {inProgressCommentText && (
              <div className="flex items-center pr-4 opacity-30">
                {/* pr-4 above makes room for scroll bar */}
                <FixedImg
                  src={me ? me.ProfilePhotoUrl : defaultProfile}
                  alt="Profile"
                  className="w-8 h-8 rounded-full mr-2"
                />
                <div className="flex flex-col w-full">
                  <div className="flex w-full justify-between">
                    <div className="text-xs font-bold">{me.FirstName}</div>
                    {/* <ReactTimeAgo
                        className="text-xs font-bold opacity-20"
                        date={new Date(comment.DateCreated * 1000)}
                      /> */}
                  </div>
                  <div className="text-sm">{inProgressCommentText}</div>
                </div>
              </div>
            )}
            <div ref={commentBottomRef} />
          </div>

          <CommentInput
            prompt={prompt}
            setShowPicker={setShowPicker}
            showPicker={showPicker}
            commentInputHasFocus={commentInputHasFocus}
            setCommentInputHasFocus={setCommentInputHasFocus}
            rotated={rotated}
            setInProgressCommentText={setInProgressCommentText}
          />
          {/* if keyboard is open, we don't want this extra padding at the bottom */}
          {isMobile && !commentInputHasFocus && <SafeAreaInsetBottom />}
        </div>
      )}
    </div>
  );
};

export default Comments;
