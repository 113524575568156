import "./tailwind.output.css";
import "./index.css";

import * as Sentry from "@sentry/react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import React from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import App from "./App";
import MuiProvider from "./providers/MuiProvider";
import ParentAppProvider from "./providers/ParentAppProvider";
import QueryProvider from "./providers/QueryProvider";
import VersionCheckProvider from "./providers/VersionCheckProvider";
import WindowSizeProvider from "./providers/WindowSizeProvider";
import reportWebVitals from "./reportWebVitals";

TimeAgo.addDefaultLocale(en); // Used for Comments timestamp

// Uncomment this and its component below for an on-screen console during mobile dev.
// import ConsoleFeed from "./components/ConsoleFeed"; // *** DO NOT COMMIT ***

// CLEANUP OLD LOCALSTORE. CAN REMOVE EVENTUALLY.
localStorage.removeItem("user"); // Not used anymore, in favor of REACT_QUERY_OFFLINE_CACHE.

if (process.env.REACT_APP_DISABLE_SENTRY !== "true") {
  Sentry.init({
    dsn:
      "https://6adc30f6ec6b4976a69bc9b1fea56f2d@o506421.ingest.sentry.io/5596228",
    autoSessionTracking: true,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
} else {
  console.log("Sentry Disabled in local dev");
}

const tagManagerArgs = {
  gtmId: "GTM-MFLRQXF",
};
TagManager.initialize(tagManagerArgs);

// Make mobile body not scrollable only on mobile
// (having no scroll bounce on desktop feels weird to me)
if (isMobile) {
  document.body.style.position = "fixed";
  document.body.style.overflow = "hidden";
  document.body.style.height = "100%";
  document.body.style.width = "100%";
}

// Allows for easier mobile testing
const forceToken = process.env.REACT_APP_FORCE_TOKEN;
if (forceToken && forceToken !== "false") {
  console.log("FORCING TOKEN");
  localStorage.setItem("token", forceToken);
}

ReactDOM.render(
  <React.StrictMode>
    <QueryProvider>
      <HelmetProvider>
        <VersionCheckProvider>
          <ParentAppProvider>
            <MuiProvider>
              <WindowSizeProvider>
                <App />
              </WindowSizeProvider>
            </MuiProvider>
          </ParentAppProvider>
        </VersionCheckProvider>
      </HelmetProvider>
    </QueryProvider>
    {/* <ConsoleFeed/>  */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
