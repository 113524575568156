import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { BadgeCountContext } from "../contexts/badgeCountContext";
import { queryHomePageDedupedData, queryNotifications } from "../queries";
import { getPromptEarlyStateInfo, isPromptEarly } from "../utils/promptUtils";

const BadgeCountProvider = ({ children }) => {
  const { data: notifications } = useQuery(
    "queryNotifications",
    queryNotifications
  );

  // Even though we don't need all the data, use the same query as Home does,
  // to reduce number of queries we use and so that when ParentApp clears
  // this query, we get a new count.
  const { data: homePageDedupedData } = useQuery(
    "queryHomePageDedupedData",
    queryHomePageDedupedData,
    {
      // Shorten cache so you don't always see yesterday's prompts
      cacheTime: 1000 * 60 * 60 * 1, // 1 hours
    }
  );

  const [videosBadgeCount, setVideosBadgeCount] = useState(0);
  const [promptsBadgeCount, setPromptsBadgeCount] = useState(0);
  const [notificationsBadgeCount, setNotificationsBadgeCount] = useState(0);
  const [notificationsScreenShowing, setNotificationsScreenShowing] = useState(
    false
  );

  useEffect(() => {
    if (notifications) {
      let newCount = 0;
      notifications.Notifications.forEach((notification) => {
        if (notification.DateCreated > notifications.LastRead) {
          newCount += 1;
        }
      });
      setNotificationsBadgeCount(newCount);
    }
  }, [notifications]);

  useEffect(() => {
    if (homePageDedupedData) {
      const mostRecentPromptByGroupId = {};

      // We may have prompts from multiple groups.  Return
      // true if this is the first prompt in the list for
      // any group.
      const promptIsMostRecent = (prompt) => {
        let isMostRecent = false;
        if (!mostRecentPromptByGroupId[prompt.GroupId]) {
          mostRecentPromptByGroupId[prompt.GroupId] = prompt;
          isMostRecent = true;
        }
        return isMostRecent;
      };

      let openPromptCount = 0;
      homePageDedupedData.currentPrompts.forEach((prompt) => {
        if (prompt.CanSubmit && !isPromptEarly(prompt)) {
          openPromptCount += 1;
        }
      });
      setPromptsBadgeCount(openPromptCount);

      let newVideoCount = 0;
      homePageDedupedData.recentPrompts.forEach((prompt) => {
        const isMostRecent = promptIsMostRecent(prompt);
        const unknownWatched = prompt.Watched === undefined;
        const unwatched = unknownWatched || !prompt.Watched;
        if (isMostRecent && unwatched) {
          newVideoCount += 1;
        }
      });
      setVideosBadgeCount(newVideoCount);
    }
  }, [homePageDedupedData]);

  return (
    <BadgeCountContext.Provider
      value={{
        videosBadgeCount,
        promptsBadgeCount,
        notificationsBadgeCount: notificationsScreenShowing
          ? 0
          : notificationsBadgeCount,
        setNotificationsScreenShowing,
      }}
    >
      {children}
    </BadgeCountContext.Provider>
  );
};

export default BadgeCountProvider;
