// PageFull adds a full screen of backgroundColor with padding to go under header.

import SafeAreaInsetTop from "./SafeAreaInsetTop";

const PageFull = ({ children, backgroundColor = "light-gray-6" }) => {
  return (
    <div
      className={`pt-20 flex flex-col items-center h-screen w-screen min-h-screen min-w-screen bg-${backgroundColor}`}
      style={{}}
    >
      {/* pt-20 goes under the absolute header */}
      <SafeAreaInsetTop bgColor={backgroundColor} />
      {children}
    </div>
  );
};

export default PageFull;
