// This has a one-time useEffect that will cause prompts to refresh every midnight
// and cause open prompt badges to appear.
// TODO This will not work if user is jumping timezones without a refresh.

import { useEffect } from "react";
import { useQueryClient } from "react-query";

const PromptAutoReloader = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const now = new Date();
    const midnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0
    );
    const msUntilMidnight = midnight.getTime() - now.getTime();
    let interval;
    const timeout = setTimeout(() => {
      // Requery Prompts at midnight.  This will refresh the badges as Early prompts become active.
      queryClient.invalidateQueries("queryHomePageDedupedData");
      interval = setInterval(() => {
        // And then do it again every 24 hours.
        queryClient.invalidateQueries("queryHomePageDedupedData");
      }, 24 * 60 * 60 * 1000);
    }, msUntilMidnight);
    return () => {
      clearTimeout(timeout);
      if (interval) clearInterval(interval);
    };
  }, []);
  return null;
};

export default PromptAutoReloader;
