import { InformationCircleIcon } from "@heroicons/react/solid";

import FixedLink from "./Common/FixedLink";

const GroupCreateLink = ({ text }) => {
  return (
    <FixedLink href="/teams/create" className="cursor">
      <div className="flex items-center my-5 p-5 font-medium rounded-xl bg-black text-whiteOpacity-90 shadow-lg select-none cursor-pointer">
        <div className="pr-4">
          <InformationCircleIcon className="w-8 h-8" />
        </div>
        <div>
          {text} Teams can be created in the Teams section or by clicking{" "}
          <u>here</u>.
        </div>
      </div>
    </FixedLink>
  );
};
export default GroupCreateLink;
