import { Fragment, useContext } from "react";
import { isMobile } from "react-device-detect";
import Webcam from "react-webcam";

import { MediaContext } from "../contexts/mediaContext";
import { ParentAppContext } from "../contexts/parentAppContext";
import { WindowSizeContext } from "../contexts/windowSizeContext";
import useSafeAreaInsets from "../hooks/useSafeAreaInsets";
import useVideoAreaHeight from "../hooks/useVideoAreaHeight";
import puff from "../images/puff.svg";
import FixedImg from "./Common/FixedImg";
import PreRecordCountdown from "./PreRecordCountdown";
import PromptTitle from "./PromptTitle";
import TimerBar from "./Recorder/TimerBar";

const Record = ({
  webcamRef,
  preRecordCountingDown,
  preRecordCountdown,
  remainingMilliseconds,
  maxMilliseconds,
  prompt,
  capturing,
}) => {
  const { videoDevice, audioDevice, hasMediaPermission } = useContext(
    MediaContext
  );
  const { safeAreaInsetTopNumber } = useSafeAreaInsets();
  const videoAreaHeight = useVideoAreaHeight(false);
  const { windowSize } = useContext(WindowSizeContext);
  const { hasParentApp } = useContext(ParentAppContext);

  if (!videoAreaHeight) return <Fragment />;

  //sizing the webcam to fill window size
  let videoWidth = windowSize.width;
  // determine the proper video height for the window size width
  let videoHeight = (videoWidth / 9) * 16;
  let videoTopOffset = (videoHeight - videoAreaHeight) * -1;
  let videoLeftOffset = 0;

  if (videoTopOffset > 0) {
    videoHeight = videoAreaHeight;
    videoWidth = (videoHeight / 16) * 9;
    videoLeftOffset = (videoWidth - windowSize.width) * -1;
    videoTopOffset = 0;
  }

  const timerBarTopClass = safeAreaInsetTopNumber > 0 ? "-top-2" : "top-3";

  // console.log("RECORD HAS VIDEO DEVICE: ", videoDevice);
  // console.log("RECORD HAS VIDEO HEIGHT: ", videoAreaHeight);

  // If devices switch, we need to remount the Webcam, at least to get new resolutions..
  const webcamForceRemountKey =
    "key" + hasMediaPermission + audioDevice?.deviceId + videoDevice?.deviceId;

  const audioDeviceId = audioDevice?.deviceId;
  const videoDeviceId = videoDevice?.deviceId;
  let readyToRecord = hasMediaPermission && !!audioDevice && !!videoDevice;
  // console.log("READY: " + readyToRecord);

  const mobileWebcamStyle = {
    height: videoHeight,
    width: videoWidth,
    overflow: "hidden",
    marginTop: videoTopOffset,
  };

  const desktopWebcamStyle = {
    height: videoAreaHeight,
  };

  return (
    <div className="relative w-full">
      <div
        className="absolute bg-black flex items-center justify-center select-none"
        style={{ width: "100%", height: videoAreaHeight }}
      >
        <FixedImg src={puff} alt="Loading" />
      </div>
      <div
        key={webcamForceRemountKey}
        style={{
          width: videoWidth,
          marginLeft: videoLeftOffset,
        }}
      >
        {readyToRecord ? (
          <Webcam
            ref={webcamRef}
            audioConstraints={{ deviceId: audioDeviceId }}
            videoConstraints={{
              deviceId: videoDeviceId,
              facingMode: "user",
              width: { ideal: isMobile ? 1280 : 405 }, // {min: 640, ideal: 1280, max: 1280},
              height: { ideal: 720 }, //{min: 360, ideal: 720, max: 720}
            }}
            mirrored
            className="mx-auto"
            height={videoAreaHeight}
            width={videoWidth}
            style={isMobile ? mobileWebcamStyle : desktopWebcamStyle}
          />
        ) : (
          <div style={{ width: "100%", height: videoAreaHeight }}>
            {/* For the purpose of filling out the space as video permissions are getting asked. */}
          </div>
        )}
      </div>
      <PromptTitle text={prompt ? prompt.PromptText : "..."} />

      {preRecordCountingDown && (
        <PreRecordCountdown preRecordCountdown={preRecordCountdown} />
      )}
      {capturing && !!remainingMilliseconds && (
        <div className={`absolute ${timerBarTopClass} top-0 left-0 w-full`}>
          <TimerBar
            remainingMilliseconds={remainingMilliseconds}
            maxMilliseconds={maxMilliseconds}
          />
        </div>
      )}
    </div>
  );
};

export default Record;
