import { Fragment } from "react";

import FixedImg from "../Common/FixedImg";

const Members = ({
  members = [],
  responded = [],
  onlyResponded = false,
  bgColor = "white",
  noFadeOnMembers = false,
}) => {
  const fadeFromClass = "from-" + bgColor;
  const userForId = (userId) => {
    if (members && members.length > 0) {
      return members.find((member) => member.Id === userId);
    }
  };

  const memberOpacityClass = noFadeOnMembers ? "" : "opacity-10";

  return (
    <div className="flex flex-row mt-2 h-6">
      <div className="flex-1 flex overflow-hidden">
        {responded.map((userId) => {
          const user = userForId(userId);
          if (!user) return <Fragment key={userId} />;
          return (
            <FixedImg
              src={user?.ProfilePhotoUrl}
              alt="Profile"
              className="w-6 h-6 rounded-full mr-1"
              key={userId}
            />
          );
        })}
        {!onlyResponded &&
          members.map((user) => {
            if (responded.includes(user.Id)) {
              return <Fragment key={user.Id} />;
            }
            return (
              <FixedImg
                src={user?.ProfilePhotoUrl}
                alt="Profile"
                className={`w-6 h-6 rounded-full mr-2 ${memberOpacityClass}`}
                key={user.Id}
              />
            );
          })}
      </div>
      {/* gradient fade to white in case list is long. (P.S. Never use from-transparent. Safari hates it.) */}
      <div
        className={`right-0 w-6 h-6 -ml-6 bg-gradient-to-l ${fadeFromClass}`}
      />
    </div>
  );
};
export default Members;
